<merim-common-layout-table class="multiple-dtt-report"
                           [config]="tableConfig"
                           [dataSource]="tableData()">

</merim-common-layout-table>

<ng-template #coloredTime let-context="data" let-info="info" let-row="row">
	<p class="m-0 h-[23px] leading-[23px] py-0 px-2 rounded-[4px] bg-blue-100 text-blue-900"
	   [class]="info.customConditionHandler ? info.customConditionHandler(context, row) : getClassForValue(context, info.type)">
		{{ context ? (context | toMins) : '-' }}
	</p>
</ng-template>