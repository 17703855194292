import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as api from '@dki/api-client';
import { Period } from '@libs/dash/core/entity';
import { BkfReportComponent, SecondsToMins } from '@libs/dash/features/v1';
import { BKF_TABLE_COLUMNS } from '@libs/dash/features/v2';
import { ColumnType, CommonLayoutTableComponent, CommonTableConfig } from '@libs/shared/modules/common-components';
import { TranslateService } from '@libs/shared/modules/i18n';

@Component({
	selector: 'bkf-table-cumul',
	standalone: true,
	imports: [CommonModule, CommonLayoutTableComponent, SecondsToMins],
	templateUrl: './bkf-table-cumul.component.html',
	styleUrl: './bkf-table-cumul.component.scss',
	providers: [SecondsToMins],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BkfTableCumulComponent extends BkfReportComponent implements OnInit {
	private readonly _translateService: TranslateService = inject(TranslateService);
	private readonly _secondsToMins: SecondsToMins = inject(SecondsToMins);

	tableConfig: CommonTableConfig<api.SosDttReport>;
	tableData = input.required<any[]>();

	@ViewChild('coloredTime', { read: TemplateRef, static: true }) coloredTime: TemplateRef<any>;

	ngOnInit(): void {
		this.initConfig();
	}

	getClassForValue(value: number, type: string): string {
		if (!value || isNaN(value)) {
			return '';
		}
		return !this.objectiveAchieved(type, value) ? 'red' : 'green';
	}

	private initConfig(): void {
		this.tableConfig = {
			titleKey: 'dtt-export.group_type.cumul_all',
			columns: [
				{
					key: BKF_TABLE_COLUMNS.DayPart,
					headerLabelKey: 'dtt-export.dtt_report.header.day_part',
					alignment: 'left',
					columnType: ColumnType.Translation,
					width: '19.5%',
					valueGetter: (cell: string) => `dtt-export.dtt_report.day_part.${cell}`,
				},
				{
					key: BKF_TABLE_COLUMNS.Schedule,
					headerLabelKey: 'sosKioskTile.dataTable.schedule',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					valueGetter: (cell: Period, row: api.SosDttReport, index: number) => {
						const formatTime = (time: number) => time.toString().padStart(2, '0');
						const template = this._translateService.instant('sosKioskTile.timeRange');
						return template
							.replace('{start}', formatTime(parseInt(this.dayParts[index][0], 10)))
							.replace('{end}', formatTime(parseInt(this.dayParts[index][1], 10)));
					},
					totalGetter: () => this._translateService.instant('sosKioskTile.dataTable.totalAverage'),
				},
				{
					key: BKF_TABLE_COLUMNS.CA,
					headerLabelKey: 'dtt-export.dtt_report.header.ca',
					alignment: 'right',
					columnType: ColumnType.Price,
					width: '11.5%',
					totalGetter: (data: api.SosDttReport[]) => this.total(data ?? [], 'total_revenue'),
				},
				{
					key: BKF_TABLE_COLUMNS.AverageTicket,
					headerLabelKey: 'dtt-export.dtt_report.header.avrg_ticket',
					alignment: 'right',
					columnType: ColumnType.Price,
					width: '11.5%',
					valueGetter: (cell: unknown, row: api.SosDttReport) => row?.total_revenue && row?.total_revenue / row?.count,
					totalGetter: (data: api.SosDttReport[]) => this.total(data ?? [], 'total_revenue') / this.total(data ?? [], 'count'),
				},
				{
					key: BKF_TABLE_COLUMNS.NumberOfTickets,
					headerLabelKey: 'dtt-export.dtt_report.header.orders',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					valueGetter: (cell: unknown, row: api.SosDttReport) => row?.count || '-',
					totalGetter: (data: api.SosDttReport[]) => this.total(data ?? [], 'count') || '-',
				},
				{
					key: BKF_TABLE_COLUMNS.Cars,
					headerLabelKey: 'dtt-export.dtt_report.header.cars',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					valueGetter: (cell: unknown, row: api.SosDttReport) => (row?.count && row?.count - row?.sos_samecar) || '-',
					totalGetter: (data: api.SosDttReport[]) => (this.total(data ?? [], 'count') - this.total(data ?? [], 'sos_samecar')) || '-',
				},
				{
					key: BKF_TABLE_COLUMNS.Wait,
					headerLabelKey: 'dtt-export.dtt_report.header.wait',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					valueGetter: (cell: number) => this._secondsToMins.transform(cell),
					totalGetter: (data: api.SosDttReport[]) =>
						this._secondsToMins.transform(this.totalAverage(data ?? [], 'mean_sos_waittime', false)),
				},
				{
					key: BKF_TABLE_COLUMNS.OrderTime,
					headerLabelKey: 'dtt-export.dtt_report.header.order_time',
					alignment: 'right',
					columnType: ColumnType.Custom,
					width: '11.5%',
					templateRef: this.coloredTime,
					info: { type: 'order' },
					valueGetter: (cell: number) => cell,
					totalGetter: (data: api.SosDttReport[]) =>
						this._secondsToMins.transform(this.totalAverage(data ?? [], 'mean_sos_ordertime', false)),
				},
				{
					key: BKF_TABLE_COLUMNS.Order,
					headerLabelKey: 'dtt-export.dtt_report.header.order',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					valueGetter: (cell: unknown, row: api.SosDttReport) =>
						this._secondsToMins.transform(row?.mean_sos_waittime + row?.mean_sos_ordertime),
					totalGetter: (data: api.SosDttReport[]) =>
						this._secondsToMins.transform(
							this.totalAverage(data ?? [], 'mean_sos_waittime', false) + this.totalAverage(data ?? [], 'mean_sos_ordertime', false)
						),
				},
				{
					key: BKF_TABLE_COLUMNS.LineTime,
					headerLabelKey: 'dtt-export.dtt_report.header.transit',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					valueGetter: (cell: number) => this._secondsToMins.transform(cell),
					totalGetter: (data: api.SosDttReport[]) =>
						this._secondsToMins.transform(this.totalAverage(data ?? [], 'mean_sos_linetime', false)),
				},
				{
					key: BKF_TABLE_COLUMNS.Transit,
					headerLabelKey: 'dtt-export.dtt_report.header.transit_delivery',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					valueGetter: (cell: number) => this._secondsToMins.transform(cell),
					totalGetter: (data: api.SosDttReport[]) =>
						this._secondsToMins.transform(this.totalAverage(data ?? [], 'mean_sos_hardservetime', false)),
				},
				{
					key: BKF_TABLE_COLUMNS.Delivery,
					headerLabelKey: 'dtt-export.dtt_report.header.delivery',
					alignment: 'right',
					columnType: ColumnType.Custom,
					width: '11.5%',
					templateRef: this.coloredTime,
					info: {
						customConditionHandler: (cell: number, row: api.SosDttReport) => {
							if (!row || !cell) {
								return '';
							}
							return this.objectiveAchieved('delivery', row && this.getDeliveryTime(row)) ? 'green' : 'red';
						},
					},
					valueGetter: (cell: unknown) => cell,
					totalGetter: (data: api.SosDttReport[]) =>
						this._secondsToMins.transform(this.totalAverage(data ?? [], 'mean_sos_deliverytime', false)),
				},
				{
					key: BKF_TABLE_COLUMNS.Total,
					headerLabelKey: 'dtt-export.dtt_report.header.total',
					alignment: 'right',
					columnType: ColumnType.Custom,
					width: '11.5%',
					templateRef: this.coloredTime,
					info: {
						customConditionHandler: (cell: number, row: api.SosDttReport) => {
							if (!row || !cell) {
								return '';
							}
							return this.totalTime(row) && this.objectiveAchieved('total', this.totalTime(row)) ? 'green' : 'red';
						},
					},
					valueGetter: (cell: unknown, row: api.SosDttReport) => this.totalTime(row),
					totalGetter: (data: api.SosDttReport[]) => this._secondsToMins.transform(this.totalAverage(data ?? [], 'total', true)),
				},
				{
					key: BKF_TABLE_COLUMNS.Parked,
					headerLabelKey: 'dtt-export.dtt_report.header.park',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					valueGetter: (cell: number) => this._secondsToMins.transform(cell),
					totalGetter: (data: api.SosDttReport[]) =>
						this._secondsToMins.transform(this.totalAverage(data ?? [], 'mean_sos_parktime', false)),
				},
				{
					key: BKF_TABLE_COLUMNS.Depart,
					headerLabelKey: 'dtt-export.dtt_report.header.depart',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					valueGetter: (cell: number) => this._secondsToMins.transform(cell),
					totalGetter: (data: api.SosDttReport[]) =>
						this._secondsToMins.transform(this.totalAverage(data ?? [], 'mean_sos_departtime', false)),
				},
			],
		};
	}
}
