<div class="wrapper">
	@for (period of datePeriods; track period) {
		<div class="label" [class.selected]="selectedPeriod === period" (click)="selectPeriod(period)">
			<span [translate]="'sosKioskTile.datePicker.' + period"></span>
		</div>
	}
	<div class="selector" (click)="openPicker(picker)">
		<mat-icon id="calendar">date_range</mat-icon>
		<span>{{ formattedDateRange }}</span>
		<mat-icon id="arrow">keyboard_arrow_down</mat-icon>
		<mat-date-range-input [formGroup]="range" [rangePicker]="picker" class="hideme">
			<input matStartDate formControlName="from" />
			<input matEndDate formControlName="to" />
		</mat-date-range-input>
		<mat-date-range-picker #picker>
			<mat-date-range-picker-actions>
				<button mat-button matDateRangePickerCancel>
					<span [translate]="'sosKioskTile.datePicker.cancel'"></span>
				</button>
				<button mat-raised-button color="primary" matDateRangePickerApply (click)="applyCustomDates()">
					<span [translate]="'sosKioskTile.datePicker.apply'"></span>
				</button>
			</mat-date-range-picker-actions>
		</mat-date-range-picker>
	</div>
</div>
