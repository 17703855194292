import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, Injector, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Range } from '@libs/dash/core/entity';
import { DiscountsReportComponent, ReportsModule } from '@libs/dash/features/v1';
import { ExportOption, ExportOptionType, LayoutFilterService } from '@libs/dash/features/v2';
import { ColumnType, CommonLayoutTableComponent, CommonTableConfig } from '@libs/shared/modules/common-components';

const DISCOUNTS_REPORTS_COLUMN = {
	Name: 'name',
	Percent: 'percent',
	Value: 'value',
	Quantity: 'count',
	Total: 'total_value',
	TotalExcludingVat: 'total_value_excluding_vat',
};

@Component({
	selector: 'discounts-reports-tab',
	standalone: true,
	imports: [CommonModule, CommonLayoutTableComponent, ReportsModule],
	templateUrl: './discounts-reports-tab.component.html',
	styleUrl: './discounts-reports-tab.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountsReportsTabComponent extends DiscountsReportComponent implements OnInit {
	private readonly _injector: Injector = inject(Injector);
	private readonly _dr: DestroyRef = inject(DestroyRef);
	private readonly _layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	tableData = computed(() => {
		if (this.discountsReportSig()) {
			return this.discountsReportSig().by_discount_type;
		}
		return [];
	});

	tableConfig: CommonTableConfig<any> = {
		titleKey: 'reports.discount-report',
		columns: [
			{
				key: DISCOUNTS_REPORTS_COLUMN.Name,
				headerLabelKey: 'reports.discount.libelle-new',
				alignment: 'left',
				columnType: ColumnType.Text,
			},
			{
				key: DISCOUNTS_REPORTS_COLUMN.Percent,
				headerLabelKey: 'reports.discount.percent-new',
				alignment: 'left',
				columnType: ColumnType.Translation,
				valueGetter: (cell: boolean) => (cell ? 'reports.discount.yes' : 'reports.discount.no'),
			},
			{
				key: DISCOUNTS_REPORTS_COLUMN.Value,
				headerLabelKey: 'reports.discount.value-new',
				alignment: 'right',
				columnType: ColumnType.Text,
				totalColumnType: ColumnType.Translation,
				totalGetter: () => 'reports.discount.total',
			},
			{
				key: DISCOUNTS_REPORTS_COLUMN.Quantity,
				headerLabelKey: 'reports.discount.quantity',
				alignment: 'right',
				columnType: ColumnType.Text,
				totalGetter: () => this.discountsReportSig()?.total.count,
			},
			{
				key: DISCOUNTS_REPORTS_COLUMN.TotalExcludingVat,
				headerLabelKey: 'reports.discount.discountAmountGross-new',
				alignment: 'right',
				columnType: ColumnType.Price,
				totalGetter: () => this.discountsReportSig()?.total.total_value_excluding_vat,
			},
			{
				key: DISCOUNTS_REPORTS_COLUMN.Total,
				headerLabelKey: 'reports.discount.discountAmountVat-new',
				alignment: 'right',
				columnType: ColumnType.Price,
				totalGetter: () => this.discountsReportSig()?.total.total_value,
			},
		],
	};

	override ngOnInit(): void {
		super.ngOnInit();

		effect(
			() => {
				const range = this._layoutFilterServiceService?.range();
				if (range) {
					this.dateRange.setValue({ from: range.from.toJSDate(), to: range.to.toJSDate() });
					this.setPeriod(Range.Period);
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		const availableExportOptions: ExportOption[] = [
			{ label: ExportOptionType.CSV, selected: false, type: ExportOptionType.CSV },
			{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF },
		];
		this._layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);

		this._layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this._dr))
			.subscribe((selectedOptions: string[]) => this._exportData(selectedOptions));
	}

	private _exportData(selectedOptions: string[]) {
		if (selectedOptions.length === 0) {
			return;
		}
		for (const option of selectedOptions) {
			switch (option) {
				case ExportOptionType.PDF:
					this.downloadPDF();
					break;
				case ExportOptionType.CSV:
					this.downloadCSV();
					break;
			}
		}
	}
}
