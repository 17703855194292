import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { TranslateService } from '@libs/shared/modules/i18n';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { TODAYS_OVERVIEW_FACADE } from '../../facade/todays-overview-facade.injection.token';
import { TodaysOverviewServiceProvider } from '../../facade/todays-overview-facade.provider.interface';

@Component({
	selector: 'dk-revenue-tile',
	templateUrl: './revenue-tile.component.html',
	styleUrls: ['./revenue-tile.component.scss'],
})
export class RevenueTileComponent {
	tooltip = {
		shown: false,
		color: '',
		text: '',
		x: 0,
		y: 0,
	};

	numberOfColumns = 12;
	currentHourSeriesColumnOffset = 0;
	chartPeakValue = 0;

	viewData$ = combineLatest([this._todaysOverviewFacade.revenueProgress$, this._translateService.selectTranslation('revenueTile')]).pipe(
		map(([revenueProgress, i18n]) => {
			let currentHourItem = undefined;

			if (revenueProgress?.activeHours?.length) {
				this.numberOfColumns = revenueProgress.activeHours.length;
			}

			if (revenueProgress?.currentHourSeries?.length) {
				revenueProgress.currentSeries.forEach((item, index) => {
					if (item.label === revenueProgress.currentHourSeries[0].label) {
						this.currentHourSeriesColumnOffset = index;
					}
				});
			}

			if (revenueProgress?.horizontalSteps?.length && revenueProgress?.horizontalSteps[1]) {
				this.chartPeakValue = revenueProgress.horizontalSteps[1];
			}

			if (revenueProgress?.currentHourSeries?.length) {
				currentHourItem = revenueProgress.currentHourSeries[0];
			}
			return { revenueProgress, currentHourItem, i18n };
		})
	);

	@ViewChild('svgContainer', { read: ElementRef }) svgContainerRef: ElementRef | undefined = undefined;

	constructor(
		private _translateService: TranslateService,
		@Inject(TODAYS_OVERVIEW_FACADE) private _todaysOverviewFacade: TodaysOverviewServiceProvider
	) {}

	get columnWidth(): number {
		let width = 12;

		if (this.svgContainerRef && this.svgContainerRef.nativeElement) {
			width = this.svgContainerRef.nativeElement.clientWidth / this.numberOfColumns;
		}

		return width;
	}

	get svgHeight(): number {
		let height = 300;

		if (this.svgContainerRef && this.svgContainerRef.nativeElement) {
			height = this.svgContainerRef.nativeElement.clientHeight;
		}

		return height;
	}

	calculateCy(absoluteValue: number): number {
		let height = 12;

		if (this.svgHeight && this.chartPeakValue) {
			const value100Perc = this.chartPeakValue;
			const value1Perc = value100Perc / 100;
			const valuePrec = absoluteValue / value1Perc;

			height = this.svgHeight - (this.svgHeight / 100) * valuePrec;
		} else if (this.svgHeight && this.chartPeakValue === 0) {
			height = this.svgHeight;
		}

		return height;
	}

	composePath(series: any, closePath: boolean, startingIndex?: number, fillType?: string): string {
		let path = '';
		const sIndex = startingIndex ? startingIndex : 0;

		if (series && series.length > 0) {
			const columnWidth = this.columnWidth;
			const breakPoints = series.map((item, index) => {
				const outOfPeriod = item.label.split(':')[0] <= new Date().getHours() + 1;
				if (closePath) {
					return `L ${(sIndex + index) * columnWidth} ${this.calculateCy(item.value)}`;
				} else {
					if (index !== series.length - 1 && outOfPeriod) {
						return `L ${(sIndex + index) * columnWidth} ${this.calculateCy(item.value)}`;
					} else {
						return '';
					}
				}
			});
			path = `M ${sIndex * columnWidth} ${this.svgHeight} ` + breakPoints.join(' ');
			// const fillerPoint = ` L ${breakPoints[breakPoints.length - 1].split(' ')[1]} ${breakPoints[0].split(' ')[2]}`;
			// if (closePath && fillType) path += fillerPoint;
		}
		return path;
	}

	setTootltip(shown: boolean, item: any, color: string, x: number, y: number): void {
		this.tooltip = {
			shown,
			color,
			text: `${item.label}h : ${item.value} EUR`,
			x: x - 60,
			y: y - 30,
		};
	}

	hideTootltip(): void {
		this.tooltip = {
			shown: false,
			color: '',
			text: '',
			x: 0,
			y: 0,
		};
	}

	isNaN(value: any) {
		return isNaN(value);
	}
}
