import { Component, Output, EventEmitter, ViewChild, inject, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatCheckboxModule, MatCheckboxChange } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@libs/shared/modules/i18n';
import { ExportOption, LayoutFilterService } from '../layout-filter-service/layout-filter.service';

@Component({
	selector: 'export-options',
	standalone: true,
	imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule, MatCheckboxModule, FormsModule, TranslateModule],
	templateUrl: './export-options.component.html',
	styleUrls: ['./export-options.component.scss'],
})
export class ExportOptionsComponent {
	@Output() export = new EventEmitter<string[]>();
	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

	exportOptions: ExportOption[] = [];
	private _layoutFilterService = inject(LayoutFilterService);

	constructor() {
		effect(() => {
			this.exportOptions = this._layoutFilterService.availableExportOptions();
		});
	}

	updateSelection(event: MatCheckboxChange): void {
		const index = this.exportOptions.findIndex((option) => option.label === event.source.value);
		if (index !== -1) {
			this.exportOptions[index].selected = event.checked;
		}
	}

	hasSelectedOptions(): boolean {
		return this.exportOptions.some((option) => option.selected);
	}

	onExport() {
		const selectedOptions = this.exportOptions.filter((option) => option.selected).map((option) => option.label);
		this.export.emit(selectedOptions);
		this.menuTrigger.closeMenu();
		this.exportOptions.forEach((option) => (option.selected = false));
	}

	onCancel() {
		this.exportOptions.forEach((option) => (option.selected = false));
		this.menuTrigger.closeMenu();
	}

	onClose() {
		this.exportOptions.forEach((option) => (option.selected = false));
	}
}
