import { PERMISSION_OPERATIONS, PERMISSIONS } from '@bk/frontend-common';
import { MENU_MODEL_ACTION_CONDITION, MenuModel, SubMenuModel } from '@libs/shared/models';
import { NavigationTileActionType } from '@libs/shared/modules/navigation-tile';
import { GAP_COLORS } from './colors.model';
import { MODULES } from './config.type';
import { ROUTE_PATHS } from './constants';

export const SUBMENU_PRICE_MANAGEMENT_IMPORT: SubMenuModel<MODULES> = {
	text: 'menu-user-management-import',
	icon: 'import_export',
	url: 'price-management/prices-import',
	permissions: {
		key: PERMISSION_OPERATIONS.PRICE_MANAGEMENT,
		minimal: PERMISSIONS.WRITE,
	},
};

export const SUBMENU_PRICE_MANAGEMENT_EXPORT: SubMenuModel<MODULES> = {
	text: 'menu-user-management-export',
	icon: 'import_export',
	url: 'price-management/prices-export',
	permissions: {
		key: PERMISSION_OPERATIONS.PRICE_MANAGEMENT,
		minimal: PERMISSIONS.READ,
	},
};

export const SUBMENU_PRICE_MANAGEMENT_PRODUCTS: SubMenuModel<MODULES> = {
	text: 'menu-user-management-products',
	icon: 'inventory',
	url: 'price-management/price-list/product',
};

export const SUBMENU_PRICE_MANAGEMENT_MENUS: SubMenuModel<MODULES> = {
	text: 'menu-user-management-menus',
	icon: 'restaurant_menu',
	url: 'price-management/price-list/menu',
};

export const SUBMENU_PRICE_MANAGEMENT_INGREDIENTS: SubMenuModel<MODULES> = {
	text: 'menu-user-management-ingredients',
	icon: 'cake',
	url: 'price-management/price-list/ingredient',
};

// Restaurant Management Submenus
export const SUBMENU_RESTAURANT_MANAGEMENT_OWNERS: SubMenuModel<MODULES> = {
	text: 'SUBMENU_OWNER_MANAGEMENT',
	icon: 'supervisor_account',
	url: 'restaurant-management/owner-management',
	module: MODULES.OWNER_MANAGEMENT,
	permissions: {
		key: PERMISSION_OPERATIONS.RESTAURANT_MANAGEMENT,
		minimal: PERMISSIONS.READ,
	},
	enabled: 'restaurant.options.ownerManagement',
	color: GAP_COLORS.RESTAURANT_OWNERS_MANAGEMENT,
	description: 'restaurant-owners-description',
	actions: [
		{
			text: 'open',
			url: 'restaurant-management/owner-management',
			condition: MENU_MODEL_ACTION_CONDITION.PERMITTED_ENABLED,
			action: NavigationTileActionType.Open,
		},
	],
};

export const SUBMENU_RESTAURANT_MANAGEMENT_PAYMENTS: SubMenuModel<MODULES> = {
	text: 'SUBMENU_PAYMENTS_MANAGEMENT',
	icon: 'payments',
	url: 'restaurant-management/payment-management',
	module: MODULES.PAYMENT_MANAGEMENT,
	permissions: {
		key: PERMISSION_OPERATIONS.RESTAURANT_MANAGEMENT,
		minimal: PERMISSIONS.READ,
	},
	enabled: 'restaurant.options.paymentManagement',
	color: GAP_COLORS.RESTAURANT_PAYMENTS_MANAGEMENT,
	description: 'restaurant-payments-description',
	actions: [
		{
			text: 'open',
			url: 'restaurant-management/payment-management',
			condition: MENU_MODEL_ACTION_CONDITION.PERMITTED_ENABLED,
			action: NavigationTileActionType.Open,
		},
	],
};

export const SUBMENU_RESTAURANT_MANAGEMENT_RESTAURANTS: SubMenuModel<MODULES> = {
	text: 'SUBMENU_RESTAURANT_MANAGEMENT',
	icon: 'store',
	url: 'restaurant-management/restaurants-list',
	module: MODULES.RESTAURANT,
	permissions: {
		key: PERMISSION_OPERATIONS.RESTAURANT_MANAGEMENT,
		minimal: PERMISSIONS.READ,
	},
	color: GAP_COLORS.RESTAURANT_LIST_MANAGEMENT,
	description: 'restaurant-management-description',
	actions: [
		{
			text: 'open',
			url: 'restaurant-management/restaurants-list',
			condition: MENU_MODEL_ACTION_CONDITION.NONE,
			action: NavigationTileActionType.Open,
		},
	],
};

export const SUBMENU_RESTAURANT_MANAGEMENT_TAG: SubMenuModel<MODULES> = {
	text: 'SUBMENU_TAG_MANAGEMENT',
	icon: 'local_offer',
	url: 'restaurant-management/tag-management',
	module: MODULES.TAG_MANAGEMENT,
	permissions: {
		key: PERMISSION_OPERATIONS.RESTAURANT_MANAGEMENT,
		minimal: PERMISSIONS.READ,
	},
	enabled: 'restaurant.options.tagManagement',
	color: GAP_COLORS.RESTAURANT_TAG_MANAGEMENT,
	description: 'RESTAURANT_TAG_DESCRIPTION',
	actions: [
		{
			text: 'open',
			url: 'restaurant-management/tag-management',
			condition: MENU_MODEL_ACTION_CONDITION.PERMITTED_ENABLED,
			action: NavigationTileActionType.Open,
		},
	],
};

export const MENU: MenuModel<MODULES>[] = [
	{
		text: 'menu-dashboard',
		icon: 'dashboard',
		url: ROUTE_PATHS.dashboard,
		submenu: [],
	},
	{
		text: 'menu-user-management',
		icon: 'person',
		url: 'user-management',
		module: MODULES.USER_MANAGEMENT,
		permissions: {
			key: PERMISSION_OPERATIONS.USER_MANAGEMENT,
			minimal: PERMISSIONS.READ,
		},
		submenu: [],
		color: GAP_COLORS.USER_MANAGEMENT,
		description: 'menu-user-management-description',
		actions: [
			{
				text: 'open',
				url: 'user-management',
				condition: MENU_MODEL_ACTION_CONDITION.NONE,
				action: NavigationTileActionType.Open,
			},
			{
				text: 'add',
				url: 'user-management/create',
				condition: MENU_MODEL_ACTION_CONDITION.PERMITTED,
				action: NavigationTileActionType.Open,
			},
		],
	},
	{
		text: 'MENU_DISCOUNT_MANAGEMENT',
		icon: 'percent',
		module: MODULES.DISCOUNT_MANAGEMENT,
		url: ROUTE_PATHS.discountManagement,
		color: GAP_COLORS.DISOCUNT_MANAGEMENT,
		permissions: {
			key: PERMISSION_OPERATIONS.PRODUCT_MANAGEMENT,
			minimal: PERMISSIONS.READ,
		},
		submenu: [],
	},
	{
		text: 'MENU_RESTAURANT_MANAGEMENT',
		icon: 'restaurant',
		url: 'restaurant-management',
		permissions: {
			key: PERMISSION_OPERATIONS.RESTAURANT_MANAGEMENT,
			minimal: PERMISSIONS.READ,
		},
		submenu: [
			SUBMENU_RESTAURANT_MANAGEMENT_RESTAURANTS,
			SUBMENU_RESTAURANT_MANAGEMENT_OWNERS,
			SUBMENU_RESTAURANT_MANAGEMENT_PAYMENTS,
			SUBMENU_RESTAURANT_MANAGEMENT_TAG,
		],
		color: GAP_COLORS.RESTAURANT_MANAGEMENT,
		description: 'restaurant-management-description',
		actions: [
			{
				text: 'open',
				url: 'restaurant-management/restaurants-list',
				condition: MENU_MODEL_ACTION_CONDITION.NONE,
				action: NavigationTileActionType.Open,
			},
			{
				text: 'add',
				url: 'restaurant-management/restaurants-list/create',
				condition: MENU_MODEL_ACTION_CONDITION.PERMITTED,
				action: NavigationTileActionType.Open,
			},
			{
				text: 'open-payment',
				url: 'restaurant-management/payment-management',
				condition: MENU_MODEL_ACTION_CONDITION.PERMITTED_ENABLED,
				action: NavigationTileActionType.Open,
				subMenuModel: SUBMENU_RESTAURANT_MANAGEMENT_PAYMENTS,
			},
			{
				text: 'open-owners',
				url: 'restaurant-management/owner-management',
				condition: MENU_MODEL_ACTION_CONDITION.PERMITTED_ENABLED,
				action: NavigationTileActionType.Open,
				subMenuModel: SUBMENU_RESTAURANT_MANAGEMENT_OWNERS,
			},
			{
				text: 'open-tags',
				url: 'restaurant-management/tag-management',
				condition: MENU_MODEL_ACTION_CONDITION.PERMITTED_ENABLED,
				action: NavigationTileActionType.Open,
				subMenuModel: SUBMENU_RESTAURANT_MANAGEMENT_TAG,
			},
		],
	},
	{
		text: 'menu-price-management',
		icon: 'euro',
		url: 'price-management',
		module: MODULES.PRICE_MANAGEMENT,
		permissions: {
			key: PERMISSION_OPERATIONS.PRICE_MANAGEMENT,
			minimal: PERMISSIONS.READ,
		},
		submenu: [
			SUBMENU_PRICE_MANAGEMENT_MENUS,
			SUBMENU_PRICE_MANAGEMENT_PRODUCTS,
			SUBMENU_PRICE_MANAGEMENT_INGREDIENTS,
			SUBMENU_PRICE_MANAGEMENT_IMPORT,
			SUBMENU_PRICE_MANAGEMENT_EXPORT,
		],
		color: GAP_COLORS.PRICE_MANAGEMENT,
		description: 'menu-price-management-description',
		actions: [
			{
				text: 'open',
				url: 'price-management/price-list/product',
				condition: MENU_MODEL_ACTION_CONDITION.PERMITTED,
				action: NavigationTileActionType.Open,
			},
			{
				text: 'open-menus',
				url: 'price-management/price-list/menu',
				condition: MENU_MODEL_ACTION_CONDITION.PERMITTED,
				action: NavigationTileActionType.Open,
			},
			{
				text: 'open-ingredients',
				url: 'price-management/price-list/ingredient',
				condition: MENU_MODEL_ACTION_CONDITION.PERMITTED,
				action: NavigationTileActionType.Open,
			},
			{
				text: 'import-prices',
				url: 'price-management/prices-import',
				condition: MENU_MODEL_ACTION_CONDITION.PERMITTED,
				action: NavigationTileActionType.Open,
			},
			{
				text: 'export-prices',
				url: 'price-management/prices-export',
				condition: MENU_MODEL_ACTION_CONDITION.PERMITTED,
				action: NavigationTileActionType.Open,
			},
		],
	},
	{
		text: 'MENU_ALLERGENS',
		icon: 'favorite_border',
		url: 'allergen-management',
		module: MODULES.ALLERGENS_MANAGEMENT,
		permissions: {
			key: PERMISSION_OPERATIONS.PRODUCT_MANAGEMENT,
			minimal: PERMISSIONS.READ,
		},
		submenu: [],
	},
	{
		text: 'MENU_MONITORING',
		icon: 'pie_chart',
		url: 'monitoring',
		module: MODULES.MONITORING,
		permissions: {
			key: PERMISSION_OPERATIONS.MONITORING,
			minimal: PERMISSIONS.READ,
		},
		submenu: [],
		color: GAP_COLORS.MONITORING,
		description: 'MENU_MONITORING_DESCRIPTION',
		actions: [
			{
				text: 'open',
				url: 'monitoring',
				condition: MENU_MODEL_ACTION_CONDITION.NONE,
				action: NavigationTileActionType.Open,
			},
		],
	},
	{
		text: 'MENU_OPTIONS_MANAGEMENT',
		icon: 'settings',
		url: 'options-management',
		module: MODULES.OPTIONS_MANAGEMENT,
		permissions: {
			key: PERMISSION_OPERATIONS.RESTAURANT_MANAGEMENT,
			minimal: PERMISSIONS.READ,
		},
		submenu: [],
		color: GAP_COLORS.OPTIONS_MANAGEMENT,
		description: 'MENU_OPTIONS_DESCRIPTION',
		actions: [
			{
				text: 'open',
				url: 'options-management',
				condition: MENU_MODEL_ACTION_CONDITION.NONE,
				action: NavigationTileActionType.Open,
			},
			{
				text: 'add',
				url: 'options-management/create',
				condition: MENU_MODEL_ACTION_CONDITION.PERMITTED,
				action: NavigationTileActionType.Open,
			},
		],
	},
	{
		text: 'MENU_MEDIA_MANAGEMENT',
		icon: 'movie',
		url: 'media-management',
		module: MODULES.MEDIA_MANAGEMENT,
		submenu: [],
		permissions: {
			key: PERMISSION_OPERATIONS.MEDIA_MANAGEMENT,
			minimal: PERMISSIONS.READ,
		},
		color: GAP_COLORS.MEDIA_MANAGEMENT,
		description: 'MENU_MEDIA_DESCRIPTION',
	},
	{
		text: 'menu-about',
		icon: 'info',
		url: 'about',
		submenu: [],
		color: GAP_COLORS.ABOUT,
		description: 'menu-about-description',
		actions: [
			{
				text: 'open',
				url: 'about',
				condition: MENU_MODEL_ACTION_CONDITION.NONE,
				action: NavigationTileActionType.Open,
			},
		],
	},
];
