import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@libs/shared/modules/i18n';
import { map } from 'rxjs/operators';

import { ACCOUNT_FACADE } from '../../facade/account-facade.injection.token';
import { AccountServiceProvider } from '../../facade/account-facade.provider.interface';

@Component({
	selector: 'dk-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
	texts$ = this._translateService.selectTranslation('logout');

	actionButtons$ = this._translateService.selectTranslation('logout').pipe(
		map((localisedLogoutTexts) => {
			return {
				positive: {
					label: localisedLogoutTexts.buttons.logout,
					routerLink: '/acount/login',
					disabled: false,
				},
			};
		})
	);

	constructor(
		private _translateService: TranslateService,
		@Inject(ACCOUNT_FACADE) private _accountFacade: AccountServiceProvider
	) {}

	ngOnInit(): void {
		this._accountFacade.promptLogout();
	}
}
