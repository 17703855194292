import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureMapService } from '@libs/dash/guards';
import { MyRestaurantsSelectors, RootStoreState, RouterStoreSelectors } from '@libs/dash/store';
import { TranslateService } from '@libs/shared/modules/i18n';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TODAYS_OVERVIEW_FACADE } from '../facade/todays-overview-facade.injection.token';
import { TodaysOverviewServiceProvider } from '../facade/todays-overview-facade.provider.interface';

@UntilDestroy()
@Component({
	selector: 'dk-todays-overview',
	templateUrl: './todays-overview.component.html',
	styleUrls: ['./todays-overview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodaysOverviewComponent implements OnInit, AfterViewInit {
	private _displayedNumberOfColumns = 4;
	private dataRequested = false;
	todaysOpeningHour = '';

	myDefaultRestaurant$ = this._todaysOverviewFacade.myDefaultRestaurant$;
	channelPermoranceInPercentage$ = this._todaysOverviewFacade.channelPermoranceInPercentage$;
	channelLoadPerformance$ = this._todaysOverviewFacade.channelLoadPerformance$;
	restaurantIsCurrentlyOpened$ = this._todaysOverviewFacade.restaurantIsOpened$.pipe(
		map((isOpened) => {
			if (isOpened && !this.dataRequested) {
				this.dataRequested = true;
			}

			return isOpened;
		})
	);

	localisedTexts$ = this._translateService.selectTranslation('todays-overview');
	i18n$ = combineLatest([
		this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam)),
		this._translateService.selectTranslation('todays-overview'),
	]).pipe(
		map(([language, texts]) => {
			return {
				language,
				texts,
			};
		})
	);

	isLoading$ = this._store.pipe(select(MyRestaurantsSelectors.getIsLoading));

	featureMap = this.fm.featureMap.globalOverview.todays;

	constructor(
		private _store: Store<RootStoreState>,
		private _translateService: TranslateService,
		private _elementRef: ElementRef,
		@Inject(TODAYS_OVERVIEW_FACADE) private _todaysOverviewFacade: TodaysOverviewServiceProvider,
		private fm: FeatureMapService,
		private router: Router
	) {}

	ngAfterViewInit(): void {
		// this.normalizeGrid();
		void 0;
	}

	ngOnInit(): void {
		this._todaysOverviewFacade.myDefaultRestaurant$
			.pipe(
				untilDestroyed(this),
				filter((id) => !!id)
			)
			.subscribe(() => this._todaysOverviewFacade.fetchDefaultRestaurantOrdersData());
	}

	normalizeGrid(element: HTMLElement) {
		const grids = Array.from(element.children);
		grids.forEach((element) => {
			if (element !== null) {
				const count = element.childElementCount;
				(element as HTMLElement).style.gridTemplateColumns = `repeat(${count}, 1fr)`;
			}
		});
	}
}
