import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BreadcrumbService {
	readonly keyName$ = new BehaviorSubject<Record<string | number, string>>({});

	setKeyName(key: number | string, name: string): void {
		this.keyName$.next({ [key]: name });
	}

	clear(): void {
		this.keyName$.next({});
	}
}
