<p [translate]="'filters'"></p>
<div class="flex gap-8 mb-16 w-full">
	<dk-restaurant-selector-new
		[multiple]="multiSelectRestaurant"
		[hidden]="false"
		(restaurantSelected)="restaurantSelected.emit($event)"
	></dk-restaurant-selector-new>
	<ng-content></ng-content>
	<div class="flex flex-row w-full"
	     [ngClass]="{ 'justify-between': selects?.length > 0, 'justify-end': !selects?.length }">
		@if (selects && selects?.length > 0) {
			<button mat-stroked-button class="common-layout-outlined-button" (click)="clearAll()">
				<mat-icon>close</mat-icon>
				<span [translate]="'common-layout.clear'"></span>
			</button>
		}
		<export-options (export)="exportSelected.emit($event)"></export-options>
	</div>
</div>
