import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule, PLATFORM_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';

import { PageContainerModule, SharedMaterialModule } from '@libs/dash/shared';
import { LocalizedDateAdapter } from '../../core/utils/localized-date.adapter';
import { SafeDepositModule } from '../safe-deposit/safe-deposit.module';
import { SessionsHistoryModule } from '../sessions-history/sessions-history.module';
import { HISTORY_LOGS_FACADE } from './facade/history-logs-facade.injection.token';
import { HistoryLogsFacade } from './facade/history-logs-facade.services';
import { HistoryLogsRoutingModule } from './history-logs-routing.module';
import { DepositSynthComponent } from './view-component/deposits-synth/deposits-synth.component';
import { FinancialMovementComponent } from './view-component/financial-movements/financial-movements.component';
import { HistoryLogsComponent } from './view-component/history-logs.component';
import { ReceiptsSynthComponent } from './view-component/receipts-synth/receipts-synth.component';
import { VouchersReportComponent } from './view-component/vouchers-report/vouchers-report.component';

@NgModule({
	declarations: [HistoryLogsComponent, ReceiptsSynthComponent, DepositSynthComponent, FinancialMovementComponent, VouchersReportComponent],
	imports: [
		CommonModule,
		HistoryLogsRoutingModule,
		PageContainerModule,
		SessionsHistoryModule,
		SafeDepositModule,
		MatTabsModule,
		SharedMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		MatMenuModule,
		MatSelectModule,
		MatCheckboxModule,
		MatIconModule,
	],
	providers: [
		{
			provide: HISTORY_LOGS_FACADE,
			useClass: HistoryLogsFacade,
		},
		{
			provide: DateAdapter,
			useClass: LocalizedDateAdapter,
			deps: [MAT_DATE_LOCALE, PLATFORM_ID],
		},
		CurrencyPipe,
	],
	bootstrap: [],
})
export class HistoryLogsModule {}
