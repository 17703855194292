@if (isDepensesVisible()) {
	<merim-common-layout-table [config]="tableDepensesConfig"
	                           [paddingTop]="false"
	                           [dataSource]="this.dataSig()?.data?.depenses">
	</merim-common-layout-table>
}

@if (isEntriesVisible()) {
	<merim-common-layout-table [config]="tableEntriesConfig"
	                           [paddingTop]="false"
	                           [dataSource]="this.dataSig()?.data?.entries">
	</merim-common-layout-table>
}

@if (isSortiesVisible()) {
	<merim-common-layout-table [config]="tableSortiesConfig"
	                           [paddingTop]="false"
	                           [dataSource]="this.dataSig()?.data?.sorties">
	</merim-common-layout-table>
}

<ng-template #downloadRef let-row="row">
	<div class="w-full flex justify-center items-center cursor-pointer" (click)="onDownloadClick(row)">
		<span class="w-5 material-symbols-outlined text-accent-900 mr-2">download</span>
		<p class="m-0 text-sm font-bold tracking-tight text-accent-900" [translate]="'common-layout.download'"></p>
	</div>
</ng-template>
