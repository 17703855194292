import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Signal } from '@angular/core';
import { MenuModel } from '@libs/shared/models';
import { CommonLayoutService } from '@libs/shared/modules/common-layout';
import { HEADER_ACTIONS } from '../../models/enums';
import { Router } from '@angular/router';

@Component({
	selector: 'merim-common-layout-menu',
	templateUrl: './common-layout-menu.component.html',
	styleUrl: './common-layout-menu.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonLayoutMenuComponent<T> {
	constructor(
		private router: Router,
		private ref: ChangeDetectorRef
	) {}

	private readonly _commonLayoutService: CommonLayoutService<T> = inject(CommonLayoutService<T>);

	isExpanded: Signal<boolean> = this._commonLayoutService.menuExpanded;
	version: Signal<string> = this._commonLayoutService.version;
	title: Signal<string> = this._commonLayoutService.title;
	menuItems: Signal<MenuModel<T>[]> = this._commonLayoutService.menuItems;

	toggleMenu(): void {
		(document as any).startViewTransition(() => {
			this.ref.detectChanges();
			this._commonLayoutService.toggleMenuExpanded();
		});
	}

	onLogoutClick(): void {
		this._commonLayoutService.actionsSubject.next(HEADER_ACTIONS.LOGOUT);
	}

	toggleSubmenu(menuItem: MenuModel<T>): void {
		if (!this.isExpanded()) {
			this.menuItems().forEach((item) => (item.isSubmenuOpen = false));
			return;
		}

		const isActive = this.isSubmenuActive(menuItem);
		this.menuItems().forEach((item) => {
			if (item !== menuItem) {
				item.isSubmenuOpen = false;
			}
		});

		menuItem.isSubmenuOpen = !menuItem.isSubmenuOpen || isActive;
	}

	onMenuItemClick(menuItem: MenuModel<T>): void {
		if (!menuItem.submenu || menuItem.submenu.length === 0) {
			this.menuItems().forEach((item) => (item.isSubmenuOpen = false));
		} else {
			this.toggleSubmenu(menuItem);
		}
	}

	isSubmenuActive(menuItem: MenuModel<T>): boolean {
		return (
			this.router.isActive(menuItem.url, {
				paths: 'subset',
				queryParams: 'subset',
				fragment: 'ignored',
				matrixParams: 'ignored',
			}) ||
			menuItem.submenu?.some((submenuItem) =>
				this.router.isActive(submenuItem.url, {
					paths: 'subset',
					queryParams: 'subset',
					fragment: 'ignored',
					matrixParams: 'ignored',
				})
			) ||
			false
		);
	}
}
