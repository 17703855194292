import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { Restaurant } from '@dki/api-client';
import { DatePeriod } from '@libs/dash/core/entity';
import { RestaurantSelectionModule } from '@libs/dash/features/v1';
import {
	CommonButtonToggleGroupComponent,
	CommonLayoutSelectComponent,
	CommonLayoutTab,
	CommonLayoutTabsComponent,
	CommonScreenTitleComponent,
} from '@libs/shared/modules/common-components';
import { TranslateService } from '@libs/shared/modules/i18n';
import { DatePickerNewComponent } from '../date-picker-new/date-picker-new.component';
import {
	DashLayoutFilters,
	DashLayoutFiltersOptions,
	LayoutFilterService,
	LayoutRange,
} from '../layout-filter-service/layout-filter.service';
import { LayoutFiltersComponent } from '../layout-filters/layout-filters.component';
import { DiscountsReportsTabComponent } from './partial-views/discounts-reports-tab/discounts-reports-tab.component';
import { EmployeesReportsTabComponent } from './partial-views/employees-reports-tab/employees-reports-tab.component';
import { HourlyReportsTabComponent } from './partial-views/hourly-reports-tab/hourly-reports-tab.component';
import { OperatingReportsTabComponent } from './partial-views/operating-reports-tab/operating-reports-tab.component';
import { ProductReportsTabComponent } from './partial-views/product-reports-tab/product-reports-tab.component';
import { SynthesisReportsTabComponent } from './partial-views/synthesis-reports-tab/synthesis-reports-tab.component';
import { WeeklyReportsTabComponent } from './partial-views/weekly-reports-tab/weekly-reports-tab.component';

@Component({
	selector: 'sales-reports',
	standalone: true,
	imports: [
		CommonModule,
		DatePickerNewComponent,
		CommonScreenTitleComponent,
		CommonLayoutTabsComponent,
		CommonLayoutSelectComponent,
		CommonButtonToggleGroupComponent,
		LayoutFiltersComponent,
		RestaurantSelectionModule,
	],
	templateUrl: './sales-reports.component.html',
	styleUrl: './sales-reports.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslateService],
})
export class SalesReportsComponent implements OnInit {
	private readonly _layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	selectedTabId: string;

	get range(): Signal<LayoutRange> {
		return this._layoutFilterServiceService.range;
	}

	get rangePeriod(): Signal<DatePeriod> {
		return this._layoutFilterServiceService.rangePeriod;
	}

	get filters(): DashLayoutFilters {
		return this._layoutFilterServiceService.filters;
	}

	get filtersOptions(): DashLayoutFiltersOptions {
		return this._layoutFilterServiceService.filtersOptions;
	}

	tabs: CommonLayoutTab[] = [
		{
			label: 'reports.hourlySalesTile',
			id: 'hourly',
			content: HourlyReportsTabComponent,
		},
		{
			label: 'reports.weeklyReportTile',
			id: 'weekly',
			content: WeeklyReportsTabComponent,
		},
		{
			label: 'reports.synthesis-report-new',
			id: 'synthesis',
			content: SynthesisReportsTabComponent,
		},
		{
			label: 'reports.operatingReportTileNew',
			id: 'operating',
			content: OperatingReportsTabComponent,
		},
		{
			label: 'reports.productsReportTile',
			id: 'products',
			content: ProductReportsTabComponent,
		},
		{
			label: 'reports.employee-meals',
			id: 'employess',
			content: EmployeesReportsTabComponent,
		},
		{
			label: 'reports.discount-report',
			id: 'discount',
			content: DiscountsReportsTabComponent,
		},
	];

	ngOnInit(): void {
		this.initFilters();
	}

	setRestaurants(restaurant: Restaurant | Restaurant[]) {
		this._layoutFilterServiceService.updateFilter('restaurant', restaurant);
	}

	onTabsChanged(event: string): void {
		this.selectedTabId = event;
	}

	channelSelected(channel: string | string[] | null): void {
		this._layoutFilterServiceService.updateFilter('channels', channel as string[]);
	}

	onDatePeriodSelection(period: DatePeriod) {
		this._layoutFilterServiceService.setRangePeriod(period);
	}

	onDateSelection(event: LayoutRange) {
		this._layoutFilterServiceService.setRange({ from: event.from, to: event.to });
	}

	export(selectedOptions: string[]) {
		this._layoutFilterServiceService.emitExport(selectedOptions);
	}

	private initFilters(): void {
		this._layoutFilterServiceService.registerFilter('restaurant', signal<Restaurant | Restaurant[]>([]));
		this._layoutFilterServiceService.registerFilter('channels', signal<string[]>([]));
	}
}
