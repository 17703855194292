@if (isBKC) {
	<bkc-resto-table [viewdata]="contentViewData()"
	                 [tableData]="dataTableMappedSig()"
	                 [sourceType]="sourceType"
	                 [selectedRestaurants]="selectedRestaurants"
	                 [dateRange]="dateRange"
	                 [dayParts]="dayParts"
	                 [goals]="goals">

	</bkc-resto-table>
} @else {
	<bkf-table-resto [viewdata]="contentViewData()"
	                 [tableData]="dataTableMappedSig()"
	                 [sourceType]="sourceType"
	                 [selectedRestaurants]="selectedRestaurants"
	                 [dateRange]="dateRange"
	                 [dayParts]="dayParts"
	                 [goals]="goals">

	</bkf-table-resto>
}