import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Error } from '@libs/dash/core/entity';
import { TranslateService } from '@libs/shared/modules/i18n';
import { map } from 'rxjs/operators';

@Component({
	selector: 'dk-authentication-failed',
	templateUrl: './authentication-failed.component.html',
	styleUrls: ['./authentication-failed.component.scss'],
})
export class AuthenticationFailedComponent {
	title = '';
	message = '';
	btn_text = '';
	viewData$ = this.locale.selectTranslation('errors').pipe(
		map((i18n) => {
			const errorRouteParam = this.route.snapshot.paramMap.get('error');
			if (errorRouteParam === Error.forbidden) {
				this.title = i18n.not_permitted_title;
				this.message = i18n.not_permitted;
			} else if (errorRouteParam === Error.expired) {
				this.title = i18n.expired_title;
				this.message = i18n.expired;
			}
			this.btn_text = i18n.login_btn;
			return i18n;
		})
	);

	constructor(
		private route: ActivatedRoute,
		private locale: TranslateService
	) {}
}
