<div class="container mx-auto">
	<div class="mb-4">
		<p class="text-base font-sarabun leading-normal tracking-tight font-medium m-0">
			<span [translate]="'sosKioskTile.speedOfService.objectiveLabel'"></span>
		</p>
	</div>

	<div class="indicators grid grid-cols-3 gap-4">
		@for (indicator of indicators; track indicator.labelKey) {
			<merim-sos-indicator-card [labelKey]="indicator.labelKey" [percent]="indicator.percent" [objective]="indicator.objective">
			</merim-sos-indicator-card>
		}
	</div>

	<div id="data-table">
		<sos-data-table-cumul [data]="contentViewData()?.sosData ?? []"> </sos-data-table-cumul>

		<sos-data-table-resto [contentViewData]="contentViewData()"> </sos-data-table-resto>
	</div>
</div>
