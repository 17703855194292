import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionButtons } from '@libs/dash/shared';

import { RootStoreState, RouterStoreSelectors } from '@libs/dash/store';
import { TranslateService } from '@libs/shared/modules/i18n';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ACCOUNT_FACADE } from '../../facade/account-facade.injection.token';
import { AccountServiceProvider } from '../../facade/account-facade.provider.interface';

@Component({
	selector: 'dk-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
	actionButtons$: Observable<ActionButtons> = this._translateService.selectTranslation('login').pipe(
		map((localisedLoginTexts) => {
			return {
				positive: {
					label: localisedLoginTexts.buttons.login,
					disabled: false,
				},
			};
		})
	);

	logiForm = new FormGroup({
		email: new FormControl('', Validators.email),
		password: new FormControl(''),
	});

	localisedTexts$ = this._translateService.selectTranslation('login');

	passwordRenewalPage$ = this._store.select(RouterStoreSelectors.getCurrentLanguageParam).pipe(
		map((currentLanguageParamm) => {
			return `/account/request-password-renewal`;
		})
	);

	constructor(
		private _store: Store<RootStoreState>,
		private _translateService: TranslateService,
		@Inject(ACCOUNT_FACADE) private _accountFacade: AccountServiceProvider
	) {}

	watchReturnKeypress(keyCode: string): void {
		if (keyCode === 'Enter' && this.logiForm.valid && this.logiForm.value.email) {
			this.promptLogin();
		}
	}

	promptLogin(): void {
		this._accountFacade.promptLogin(this.logiForm.value.email, this.logiForm.value.password);
		this.logiForm.reset();
	}
}
