import { Component } from '@angular/core';
import { ActionButtons } from '@libs/dash/shared';

import { RootStoreState, RouterStoreSelectors } from '@libs/dash/store';
import { TranslateService } from '@libs/shared/modules/i18n';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'dk-welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent {
	actionButtons$: Observable<ActionButtons> = combineLatest([
		this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam)),
		this._translateService.selectTranslation('account-welcome'),
	]).pipe(
		map(([currentLanguage, localisedAccountLoginTexts]) => {
			return {
				positive: {
					label: localisedAccountLoginTexts.buttons.continue,
					disabled: false,
					routerLink: `/account/new-password`,
				},
			};
		})
	);

	localisedTexts$ = this._translateService.selectTranslation('account-welcome');

	constructor(
		private _store: Store<RootStoreState>,
		private _translateService: TranslateService
	) {}
}
