import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { downloadBlob } from '@libs/dash/core';

import { Range } from '@libs/dash/core/entity';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { SESSIONS_HISTORY_FACADE } from '../../facade/sessions-history-facade.injection.token';
import { SessionsHistoryServiceProvider } from '../../facade/sessions-history-facade.provider.interface';

@UntilDestroy()
@Component({
	selector: 'dk-sessions-reports',
	templateUrl: './sessions-reports.component.html',
	styleUrls: ['./sessions-reports.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionsReportsComponent implements OnInit {
	@Input() localeText: any;

	dateRange = new FormGroup({
		from: new FormControl(this.sessionsHistoryServiceProvider.range.from.toJSDate()),
		to: new FormControl(this.sessionsHistoryServiceProvider.range.to.toJSDate()),
	});

	data: any;
	dataSig: WritableSignal<any> = signal(null);
	isLoading = this.sessionsHistoryServiceProvider.isLoading$;
	restaurantsFetching$ = this.sessionsHistoryServiceProvider.restaurantsLoading$;
	toggleKiosk = false;
	formGroup: FormGroup;
	toggleKiosk$: Observable<boolean>;
	withoutKioskSessions: any;
	kioskSessionsCount = 0;

	displayedColumnsEntries: string[] = ['reglements', 'amountTh', 'declaredAmount', 'gap', 'comment'];
	displayedColumnsRecap: string[] = ['annulations', 'refunds', 'reductions', 'debit'];
	displayedColumnsEmployee: string[] = ['label', 'id', 'name'];

	ngOnInit(): void {
		this.toggleKiosk$ = this.formGroup.valueChanges.pipe(
			untilDestroyed(this),
			map((e) => e.toggle),
			startWith(false)
		);
		combineLatest([
			this.sessionsHistoryServiceProvider.sessionsHistory$.pipe(untilDestroyed(this)),
			this.toggleKiosk$,
			this.restaurantsFetching$,
		])
			.pipe(filter(([, , loading]) => !loading))
			.subscribe(([sessionHistory, toggleKiosk]) => {
				const withKiosk = sessionHistory;
				const withoutKioskSessions = sessionHistory.filter((record) => !record['personnelsSource'][1].name.toLowerCase().includes('kiosk'));
				this.kioskSessionsCount = withKiosk.length - withoutKioskSessions.length;
				if (toggleKiosk === true) {
					this.data = withKiosk;
				} else {
					this.data = withoutKioskSessions;
				}
				this.dataSig.update(() => this.data);
			});
		combineLatest([this.sessionsHistoryServiceProvider.myDefaultRestaurant$, this.restaurantsFetching$])
			.pipe(
				filter(([rest, loading]) => !!rest && !loading),
				untilDestroyed(this),
				distinctUntilChanged((prev, curr) => {
					return prev[0]?.id === curr[0]?.id;
				})
			)
			.subscribe(() => {
				this.setPeriod();
			});
		this.sessionsHistoryServiceProvider.dateRange$.pipe(untilDestroyed(this)).subscribe((v) => {
			this.dateRange.setValue({ from: v.from.toJSDate(), to: v.to.toJSDate() });
		});
	}

	constructor(@Inject(SESSIONS_HISTORY_FACADE) private readonly sessionsHistoryServiceProvider: SessionsHistoryServiceProvider) {
		this.formGroup = new FormGroup({
			toggle: new FormControl(false),
		});
	}

	setPeriod(period?: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case Range.Today:
				from = today;
				to = today;
				break;
			case Range.Week:
				from = today.startOf(Range.Week);
				to = today.endOf(Range.Week);
				break;
			case Range.Month:
				from = today.startOf(Range.Month);
				to = today.endOf(Range.Month);
				break;
			case Range.Period:
				to = !this.dateRange.controls.to.value ? from : to;
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this.sessionsHistoryServiceProvider.getSessionsHistory(from, to);
	}

	totalCost(session, colCount) {
		const key = colCount === 1 ? 'theoretical' : colCount === 2 ? 'real' : 'gap';
		if (key !== 'gap') return session.entriesSource.reduce((acc, y) => acc + y.amount[key], 0);
		else return this.totalCost(session, 1) - this.totalCost(session, 2);
	}

	downloadOne(session) {
		const csv = this.sessionToCSV(session);
		const filename = `${this.localeText.sessionNumber} - ${session.sessionNumber}`;
		downloadBlob(csv, filename, 'text/csv;charset=utf-8;');
	}

	sessionToCSV(session) {
		let csv = `${this.localeText.sessionNumber};${this.localeText.sessionStartLabel};${this.localeText.sessionValidationLabel};${session.personnelsSource[0].label};${session.personnelsSource[1].label}\r\n`;
		csv += `${session.sessionNumber};${session.sessionClosing};${session.sessionValidation};${session.personnelsSource[0].name};${session.personnelsSource[1].name}\r\n\r\n`;
		csv += `${this.localeText.reglements};${this.localeText.amountTh};${this.localeText.declaredAmount};${this.localeText.gap};${this.localeText.comment}\r\n`;
		csv += Object.values(session.entriesSource)
			.map(
				(e) =>
					`${this.localeText.entries[e['paymentMode']]};${e['amount'].theoretical};${e['amount'].real};${e['amount'].theoretical - e['amount'].real};${e['category']};${
						e['comment']
					}`
			)
			.join('\r\n');
		csv += `\r\n`;
		csv += `${this.localeText.incVAT}\r\n`;
		csv += `${this.localeText.annulations};${this.localeText.refunds};${this.localeText.reductions};${this.localeText.debit};\r\n`;
		csv += `${session.recapLine[0].cancellation};${session.recapLine[0].refund};${session.recapLine[0].discount};${session.recapLine[0].withdrawn}\r\n`;
		return csv;
	}

	downloadAll() {
		const sessionsCSV = this.data.map((session) => this.sessionToCSV(session)).join('\r\n');
		const from = DateTime.fromJSDate(this.dateRange.value.from).toFormat('yyyy MM dd');
		const to = DateTime.fromJSDate(this.dateRange.value.to).toFormat('yyyy MM dd');
		const filename = `${this.localeText.sessions} ${from} - ${to}`;
		downloadBlob(sessionsCSV, filename, 'text/csv;charset=utf-8');
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}
}
