<merim-common-screen-title [title]="'reportsV2.saleReports.title'">
	<date-picker-new
		(dateSelection)="onDateSelection($event)"
		[selectedPeriodSig]="rangePeriod()"
		(selectedPeriodChange)="onDatePeriodSelection($event)"
		[initDate]="range()"
	></date-picker-new>
</merim-common-screen-title>

<merim-common-layout-tabs [tabs]="tabs" (contentChanged)="onTabsChanged($event)">
	<layout-filters [multiSelectRestaurant]="true"
	                (exportSelected)="exportData($event)"
	                (restaurantSelected)="setRestaurants($event)">
		@if (selectedTabId === 'receipts') {
			<common-layout-select [icon]="'device_hub'"
			                      [label]="'receipts-synth.cashier'"
			                      [search]="true"
			                      [selectedValue]="filters?.receipts()"
			                      [allCheckbox]="false"
			                      [multiSelect]="true"
			                      (selectionChange)="onReceiptChange($event)"
			                      [options]="filtersOptions?.receipts ? filtersOptions?.receipts() : []">
			</common-layout-select>
		}

		@if (selectedTabId === 'session') {
			<common-layout-select [icon]="'device_hub'"
			                      [label]="'receipts-synth.cashier'"
			                      [search]="true"
			                      [selectedValue]="filters?.vendors()"
			                      [allCheckbox]="false"
			                      [multiSelect]="true"
			                      (selectionChange)="onVendorsChange($event)"
			                      [options]="filtersOptions?.vendors ? filtersOptions?.vendors() : []">
			</common-layout-select>
		}

		@if (selectedTabId === 'session' && filtersOptions?.kiosks && filtersOptions?.kiosks() > 0) {
			<mat-slide-toggle class="!flex items-center common-layout-switch"
			                  [checked]="filters?.kiosks()"
			                  (change)="onKiosksChange($event.checked)">
				<p class="whitespace-nowrap">
					{{ 'sessions-history.reports.kioskSessionShowNew' | translate }}<span class="mx-1">{{ filtersOptions?.kiosks ?
					filtersOptions?.kiosks() : 0 }}</span>{{ 'sessions-history.reports.kioskSessionNew' | translate }}
				</p>
			</mat-slide-toggle>
		}

		@if (selectedTabId === 'financial') {
			<common-layout-select [icon]="'device_hub'"
			                      [label]="'reportsV2.reports'"
			                      [search]="true"
			                      [selectedValue]="filters?.financialReports()"
			                      [allCheckbox]="true"
			                      [multiSelect]="true"
			                      (selectionChange)="onFinancialChange($event)"
			                      [options]="filtersOptions?.financialReports ? filtersOptions?.financialReports() : []">
			</common-layout-select>
		}
	</layout-filters>
</merim-common-layout-tabs>
