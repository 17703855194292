import { Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { MenuModel } from '@libs/shared/models';
import { AvailableLangs } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { HEADER_ACTIONS } from '../models/enums';

@Injectable({
	providedIn: 'root',
})
export class CommonLayoutService<T> {
	actionsSubject = new Subject<HEADER_ACTIONS>();

	private _menuExpanded: WritableSignal<boolean> = signal(false);
	private _version: WritableSignal<string> = signal('');
	private _menuItems: WritableSignal<MenuModel<T>[]> = signal<MenuModel<T>[]>([]);
	private _userName: WritableSignal<string> = signal('');
	private _userEmail: WritableSignal<string> = signal('');
	private _title: WritableSignal<string> = signal('');
	private _availableActions: HEADER_ACTIONS[] = [];

	get menuExpanded(): Signal<boolean> {
		return this._menuExpanded.asReadonly();
	}

	get availableActions(): HEADER_ACTIONS[] {
		return this._availableActions;
	}

	set availableActions(actions: HEADER_ACTIONS[]) {
		this._availableActions = actions;
	}

	get version(): Signal<string> {
		return this._version.asReadonly();
	}

	get menuItems(): Signal<MenuModel<T>[]> {
		return this._menuItems.asReadonly();
	}

	get userName(): Signal<string> {
		return this._userName.asReadonly();
	}

	get userEmail(): Signal<string> {
		return this._userEmail.asReadonly();
	}

	get title(): Signal<string> {
		return this._title.asReadonly();
	}

	toggleMenuExpanded(): void {
		this._menuExpanded.update((expanded: boolean) => !expanded);
	}

	setVersion(version: string): void {
		this._version.update(() => version);
	}

	setMenuItems(menuItems: MenuModel<T>[]): void {
		this._menuItems.update(() => menuItems);
	}

	setUserName(name: string): void {
		this._userName.update(() => name);
	}

	setUserEmail(email: string): void {
		this._userEmail.update(() => email);
	}

	setTitle(name: string): void {
		this._title.update(() => name);
	}
}
