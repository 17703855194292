import { Pipe, PipeTransform } from '@angular/core';
import { CommonTableConfig } from "@libs/shared/modules/common-components";

@Pipe({
	name: 'operatingReportsTableConfigFilter',
	standalone: true,
})
export class OperatingReportsTableConfigFilterPipe<T> implements PipeTransform {
	transform(value: CommonTableConfig<T>, selectedChannels: string[]): CommonTableConfig<T> {
		if (selectedChannels && selectedChannels.length > 0 && value) {
			selectedChannels.push('key');
			const columns = value.columns.filter((column) => selectedChannels.includes(column.key));
			return {
				...value,
				columns,
			};
		}
		return value;
	}
}
