import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	inject,
	Injector,
	OnInit,
	Signal,
	signal,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import * as api from '@dki/api-client';
import { Range } from '@libs/dash/core/entity';
import { FinancialMovementComponent, HistoryLogsModule, SecondsToMins } from '@libs/dash/features/v1';
import { LayoutFilterService } from '@libs/dash/features/v2';
import {
	ColumnType,
	CommonLayoutTableComponent,
	CommonSelectOption,
	CommonTableColumnConfig,
	CommonTableConfig
} from '@libs/shared/modules/common-components';
import { TranslateModule } from '@libs/shared/modules/i18n';

export const FINANCE_MOVEMENT_COLUMN = {
	Movement: 'name',
	Name: 'employee_name',
	FirstName: 'employee_first_name',
	Amount: 'amount',
	Date: 'effective_time',
	Download: 'download',
};

@Component({
	selector: 'finance-movement-tab',
	standalone: true,
	imports: [CommonModule, HistoryLogsModule, CommonLayoutTableComponent, SecondsToMins, TranslateModule],
	templateUrl: './finance-movement-tab.component.html',
	styleUrl: './finance-movement-tab.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceMovementTabComponent extends FinancialMovementComponent implements OnInit {
	private readonly _injector: Injector = inject(Injector);
	private readonly _layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	isSortiesVisible: Signal<boolean> = computed(() => {
		return this._layoutFilterServiceService.filters?.financialReports ? this._layoutFilterServiceService.filters?.financialReports().includes('sorties') : false
	});

	isEntriesVisible: Signal<boolean> = computed(() => {
		return this._layoutFilterServiceService.filters?.financialReports ? this._layoutFilterServiceService.filters?.financialReports().includes('entries') : false
	})

	isDepensesVisible: Signal<boolean> = computed(() => {
		return this._layoutFilterServiceService.filters?.financialReports ? this._layoutFilterServiceService.filters?.financialReports().includes('depenses') : false
	})

	@ViewChild('downloadRef', { read: TemplateRef, static: true }) downloadRef: TemplateRef<any>;

	tableColumns: CommonTableColumnConfig<api.SafeTransaction>[];

	tableEntriesConfig: CommonTableConfig<api.SafeTransaction>;

	tableSortiesConfig: CommonTableConfig<api.SafeTransaction>;

	tableDepensesConfig: CommonTableConfig<api.SafeTransaction>;

	dataSig = toSignal<{ data: any; i18n: any }>(this.viewData$, { injector: this._injector });

	override ngOnInit(): void {
		super.ngOnInit();
		this.generateColumns();
		effect(
			() => {
				const range = this._layoutFilterServiceService?.range();
				if (range) {
					this.dateRange.setValue({ from: range.from.toJSDate(), to: range.to.toJSDate() });
					this.setPeriod(Range.Period);
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		this._layoutFilterServiceService.registerFilterOptions('financialReports', signal([
			{
				labelKey: 'financial-movements.depenses',
				value: 'depenses'
			},
			{
				labelKey: 'financial-movements.entries',
				value: 'entries'
			},
			{
				labelKey: 'financial-movements.sorties',
				value: 'sorties'
			},
		] as CommonSelectOption[]));
		this._layoutFilterServiceService.updateFilter('financialReports', ['depenses', 'entries', 'sorties']);
	}

	onDownloadClick(row: api.SafeTransaction): void {
		this.download(row);
	}

	exportData(type: string[]) {
		console.warn('Export is not implemented for this report');
	}

	private generateColumns(): void {
		this.tableColumns = [
			{
				key: FINANCE_MOVEMENT_COLUMN.Movement,
				headerLabelKey: 'financial-movements.headers.movement',
				columnType: ColumnType.Text,
				alignment: 'left',
				valueGetter: (cell: unknown, row: api.SafeTransaction) => row.operation.name,
			},
			{
				key: FINANCE_MOVEMENT_COLUMN.Name,
				headerLabelKey: 'financial-movements.headers.name',
				columnType: ColumnType.Text,
				alignment: 'left',
				valueGetter: (cell: unknown, row: api.SafeTransaction) => row.employee.login.split(' ')[0],
			},
			{
				key: FINANCE_MOVEMENT_COLUMN.FirstName,
				headerLabelKey: 'financial-movements.headers.firstname',
				alignment: 'left',
				columnType: ColumnType.Text,
				totalColumnType: ColumnType.Translation,
				totalGetter: () => 'financial-movements.total',
				valueGetter: (cell: unknown, row: api.SafeTransaction) => row.employee.login.split(' ').slice(1).join(' '),
			},
			{
				key: FINANCE_MOVEMENT_COLUMN.Amount,
				headerLabelKey: 'financial-movements.headers.amount',
				columnType: ColumnType.Price,
				alignment: 'right',
				totalGetter: (data: any[]) => data ? data?.reduce((a, i) => a + i.amounts.without_taxes, 0) : 0,
				valueGetter: (cell: unknown, row: api.SafeTransaction) => row.amounts.without_taxes,
			},
			{
				key: FINANCE_MOVEMENT_COLUMN.Date,
				headerLabelKey: 'financial-movements.headers.datetime',
				info: { dateFormat: 'dd/MM/YYYY - hh:mm' },
				alignment: 'right',
				columnType: ColumnType.Date,
			},
			{
				key: FINANCE_MOVEMENT_COLUMN.Download,
				headerLabelKey: 'financial-movements.headers.attachement',
				alignment: 'left',
				columnType: ColumnType.Custom,
				templateRef: this.downloadRef,
			},
		];

		this.tableEntriesConfig = {
			titleKey: 'financial-movements.entries',
			columns: this.tableColumns,
		};

		this.tableSortiesConfig = {
			titleKey: 'financial-movements.sorties',
			columns: this.tableColumns,
		};

		this.tableDepensesConfig = {
			titleKey: 'financial-movements.depenses',
			columns: this.tableColumns,
		};
	}
}
