import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, Injector } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import * as api from '@dki/api-client';
import { DkiBeClickhouseAnalyticsSynthesis2ReportOrders } from '@dki/api-client';
import { SynthesisReportModule, VatSynthesisReportComponent } from '@libs/dash/features/v1';
import { ExportOption, ExportOptionType, LayoutFilterService } from '@libs/dash/features/v2';
import { ColumnType, CommonLayoutTableComponent, CommonTableConfig } from '@libs/shared/modules/common-components';
import { TranslateService } from '@libs/shared/modules/i18n';

export interface SynthesisReportTotalMappedData extends DkiBeClickhouseAnalyticsSynthesis2ReportOrders {
	channel: string;
}

export interface SynthesisReportVatMappedData extends DkiBeClickhouseAnalyticsSynthesis2ReportOrders {
	channel: string;
	detail: ({ channel: string } & DkiBeClickhouseAnalyticsSynthesis2ReportOrders)[];
}

export const SYNTHESIS_REPORT_COLUMN = {
	Channel: 'channel',
	Net: 'net_value',
	Gross: 'gross_value',
	Ratio: 'share',
	Vat: 'vat_value',
};

@Component({
	selector: 'synthesis-reports-tab-table',
	standalone: true,
	imports: [CommonModule, CommonLayoutTableComponent, SynthesisReportModule],
	templateUrl: './synthesis-reports-tab-table.component.html',
	styleUrl: './synthesis-reports-tab-table.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SynthesisReportsTabTableComponent extends VatSynthesisReportComponent {
	private readonly _injector: Injector = inject(Injector);
	private readonly _dr: DestroyRef = inject(DestroyRef);
	private readonly _translateService: TranslateService = inject(TranslateService);
	private readonly _layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	tableTotalConfig: CommonTableConfig<SynthesisReportTotalMappedData> = {
		titleKey: 'synthesis-report.channel_synth_report-new',
		columns: [
			{
				key: SYNTHESIS_REPORT_COLUMN.Channel,
				headerLabelKey: 'synthesis-report.channel-new',
				alignment: 'left',
				columnType: ColumnType.Translation,
				valueGetter: (cell: string) => `synthesis-report.channels.${cell}`,
				totalGetter: () => 'synthesis-report.total',
			},
			{
				key: SYNTHESIS_REPORT_COLUMN.Net,
				headerLabelKey: 'synthesis-report.net',
				alignment: 'right',
				columnType: ColumnType.Price,
				totalGetter: () => this.dataSig()?.total?.net_value ?? '',
			},
			{
				key: SYNTHESIS_REPORT_COLUMN.Gross,
				headerLabelKey: 'synthesis-report.gross',
				alignment: 'right',
				columnType: ColumnType.Price,
				totalGetter: () => this.dataSig()?.total?.gross_value ?? '',
			},
			{
				key: SYNTHESIS_REPORT_COLUMN.Ratio,
				headerLabelKey: 'synthesis-report.ratio',
				alignment: 'right',
				columnType: ColumnType.Percent,
				totalGetter: () => this.dataSig()?.total?.share ?? '',
			},
		],
	};

	tableVatConfig: CommonTableConfig<SynthesisReportVatMappedData> = {
		titleKey: 'synthesis-report.vat_synth_report-new',
		detailsKey: 'detail',
		columns: [
			{
				key: SYNTHESIS_REPORT_COLUMN.Channel,
				headerLabelKey: 'synthesis-report.vat_type_new',
				alignment: 'left',
				columnType: ColumnType.Translation,
				detailColumnType: ColumnType.Text,
				valueGetter: (cell: string) => `synthesis-report.channels.${cell}`,
				detailGetter: (cell: string) => `${this._translateService.instant('synthesis-report.vat')} ${cell} %`,
				totalGetter: () => 'synthesis-report.total',
			},
			{
				key: SYNTHESIS_REPORT_COLUMN.Net,
				headerLabelKey: 'synthesis-report.net',
				alignment: 'right',
				columnType: ColumnType.Price,
				totalGetter: () => this.dataSig()?.total?.net_value ?? '',
			},
			{
				key: SYNTHESIS_REPORT_COLUMN.Vat,
				headerLabelKey: 'synthesis-report.vat',
				alignment: 'right',
				columnType: ColumnType.Price,
				totalGetter: () => this.dataSig()?.total?.vat_value ?? '',
			},
			{
				key: SYNTHESIS_REPORT_COLUMN.Gross,
				headerLabelKey: 'synthesis-report.gross',
				alignment: 'right',
				columnType: ColumnType.Price,
				classGetter: () => '!bg-neutral-80',
				totalGetter: () => this.dataSig()?.total?.gross_value ?? '',
			},
		],
	};

	dataSig = toSignal<api.Synthesis2Report>(this.vatSynthesisReport$, { injector: this._injector });

	dataTotalMappedSig = computed<SynthesisReportTotalMappedData[]>(() => {
		return Object.keys(this.dataSig()?.by_channel ?? {}).map((key) => {
			return {
				channel: key,
				...this.dataSig().by_channel[key].total,
			};
		});
	});

	dataTaxMappedSig = computed<SynthesisReportVatMappedData[]>(() => {
		return Object.keys(this.dataSig()?.by_channel ?? {}).map((key) => {
			return {
				channel: key,
				...this.dataSig().by_channel[key].total,
				detail: Object.keys(this.dataSig()?.by_channel[key].by_vat_rate).map((vatKey) => {
					return {
						channel: vatKey,
						...this.dataSig().by_channel[key].by_vat_rate[vatKey],
					};
				}),
			};
		});
	});

	override ngOnInit(): void {
		super.ngOnInit();
		const availableExportOptions: ExportOption[] = [{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF }];
		this._layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);

		this._layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this._dr))
			.subscribe((selectedOptions: string[]) => this._exportData(selectedOptions));
	}

	private _exportData(selectedOptions: string[]) {
		if (selectedOptions.length === 0) {
			return;
		}
		for (const option of selectedOptions) {
			switch (option) {
				case ExportOptionType.PDF:
					this.pdfExport('vat-table-dki', 0.18);
					break;
				case ExportOptionType.CSV:
					this.exportbyChannelReport(option);
					break;
			}
		}
	}
}
