<mat-form-field appearance="outline" class="common-layout-input" [subscriptSizing]="'dynamic'">
	<mat-label>{{ label | translate }}</mat-label>
	@if (icon) {
		<span matPrefix>
			<mat-icon matPrefix>{{ icon }}</mat-icon>
		</span>
	}
	@if (!multiSelect) {
		<mat-select #matSelect (selectionChange)="select($event.value)" [value]="selectedValue">
			@if (search) {
				<div class="mat-select-search px-4 pt-4">
					<div class="w-full h-full flex items-center border-0 border-b border-accent-600 border-solid">
						<mat-icon class="mx-[13px] text-baseLayer-tertiary">search</mat-icon>
						<input class="pl-0" [placeholder]="'common-layout.search' | translate" type="text" [(ngModel)]="searchQuery" />
					</div>
				</div>
			}
			<div class="max-h-[250px] overflow-auto px-4" [ngClass]="{ 'pt-[16px]': !search }">
				@for (option of options; track option.value) {
					@if (!isHidden(option.label ?? (option.labelKey | translate))) {
						<mat-option [value]="option.value">
							{{ option.label ? option.label : (option.labelKey | translate) }}
						</mat-option>
					}
				}
			</div>
			<div class="px-4 py-4 flex justify-end">
				<button mat-button color="primary" class="common-layout-button accent-primary" (click)="matSelect.close()">
					{{ 'common-layout.cancel' | translate }}
				</button>
				<button mat-button color="primary" class="common-layout-button accent-primary" (click)="select(matSelect.value); matSelect.close()">
					{{ 'common-layout.apply' | translate }}
				</button>
			</div>
		</mat-select>
	}
	@if (multiSelect) {
		<mat-select (selectionChange)="select($event.value)" [value]="selectedValue" multiple #matSelectMultiple id="multi-rest-selector">
			@if (search) {
				<div class="mat-select-search px-4 pt-4">
					<div class="w-full h-full flex items-center border-0 border-b border-accent-600 border-solid">
						<mat-icon class="mx-[13px] text-baseLayer-tertiary">search</mat-icon>
						<input class="pl-0" [placeholder]="'common-layout.search' | translate" type="text" [(ngModel)]="searchQuery" />
					</div>
				</div>
			}
			<div class="max-h-[250px] overflow-auto px-4 z-0" [ngClass]="{ 'pt-4': !search }">
				@if (allCheckbox) {
					<mat-checkbox
						class="common-layout-checkbox w-100 border-0 border-b border-solid border-surface !sticky top-0 z-10"
						(click)="selectAll()"
						[indeterminate]="selectedValue?.length > 0 && selectedValue?.length < options.length"
						[checked]="options.length === selectedValue?.length"
					>
						{{ 'common-layout.all' | translate }}
					</mat-checkbox>
				}

				@for (option of options; track option.value) {
					<mat-option color="tertiary" [value]="option.value" [class.!hidden]="isHidden(option.label ?? (option.labelKey | translate))">
						{{ option.label ? option.label : (option.labelKey | translate) }}
					</mat-option>
				}
			</div>
			<div class="px-4 py-4 flex justify-end">
				<button mat-button color="primary" class="common-layout-button accent-primary" (click)="matSelectMultiple.close()">
					{{ 'common-layout.cancel' | translate }}
				</button>
				<button
					mat-button
					color="primary"
					class="common-layout-button accent-primary"
					(click)="select(matSelectMultiple.value); matSelectMultiple.close()"
				>
					{{ 'common-layout.apply' | translate }}
				</button>
			</div>
		</mat-select>
	}
</mat-form-field>
