<mat-tab-group class="mt-12 mb-8" color="primary"
               [mat-stretch-tabs]="true"
               [fitInkBarToContent]="true"
               [animationDuration]="0"
               (selectedIndexChange)="setDisplayedComponent($event)">
	<mat-tab *ngFor="let tab of tabs" [label]="getLabel(tab)">
	</mat-tab>
</mat-tab-group>

<ng-content></ng-content>

@if (displayedComponent) {
	<ng-container *ngComponentOutlet="displayedComponent"></ng-container>
}