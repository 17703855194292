@if (groupTypeSig() === groupTypes.CumulAll) {
	<bkf-cumul-wrapper [groupType]="groupTypeSig()"
	                   [sourceType]="sourceTypeSig()"
	                   [isBKC]="true">

	</bkf-cumul-wrapper>
} @else {
	<bkf-resto-wrapper [groupType]="groupTypeSig()"
	                   [sourceType]="sourceTypeSig()"
	                   [isBKC]="true">

	</bkf-resto-wrapper>
}
