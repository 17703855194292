<div class="w-full flex h-12 mb-4">
	<div class="flex-none flex justify-center items-center text-base font-medium font-['Sarabun'] leading-normal tracking-tight">
		{{ 'sessions-history.reports.incVAT' | translate }}
	</div>
	<div class="flex-1 flex justify-center items-center gap-20">
		<div class="flex nowrap justify-center items-center">
			<p
				class="m-0 text-gray-700 text-base font-medium font-['Sarabun'] leading-normal tracking-tight">
				{{ 'sessions-history.reports.annulations' | translate }}
			</p>
			<div
				class="border border-solid border-accent-600 rounded flex items-center h-10 mx-3 px-4 text-accent-700 font-normal font-['Sarabun'] text-base leading-normal tracking-wide">
				{{ recapLineSigTotal()?.cancellation | currency: 'EUR' }}
			</div>
		</div>

		<div class="flex nowrap justify-center items-center">
			<p
				class="m-0 text-gray-700 text-base font-medium font-['Sarabun'] leading-normal tracking-tight">
				{{ 'sessions-history.reports.refunds' | translate }}
			</p>
			<div
				class="border border-solid border-accent-600 rounded flex items-center h-10 mx-3 px-4 text-accent-700 font-normal font-['Sarabun'] text-base leading-normal tracking-wide">
				{{ recapLineSigTotal()?.refund | currency: 'EUR' }}
			</div>
		</div>

		<div class="flex nowrap justify-center items-center">
			<p
				class="m-0 text-gray-700 text-base font-medium font-['Sarabun'] leading-normal tracking-tight">
				{{ 'sessions-history.reports.reductions' | translate }}
			</p>
			<div
				class="border border-solid border-accent-600 rounded flex items-center h-10 mx-3 px-4 text-accent-700 font-normal font-['Sarabun'] text-base leading-normal tracking-wide">
				{{ recapLineSigTotal()?.discount | currency: 'EUR' }}
			</div>
		</div>

		<div class="flex nowrap justify-center items-center">
			<p
				class="m-0 text-gray-700 text-base font-medium font-['Sarabun'] leading-normal tracking-tight">
				{{ 'sessions-history.reports.debit' | translate }}
			</p>
			<div
				class="border border-solid border-accent-600 rounded flex items-center h-10 mx-3 px-4 text-accent-700 font-normal font-['Sarabun'] text-base leading-normal tracking-wide">
				{{ recapLineSigTotal()?.withdrawn | currency: 'EUR' }}
			</div>
		</div>

	</div>
</div>

<merim-common-layout-table [config]="tableConfig"
                           [paddingTop]="false"
                           [dataSource]="dataSig() | PerSessionFilterData: selectedVendors()">

</merim-common-layout-table>