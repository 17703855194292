import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@libs/shared/modules/i18n';

@Component({
	selector: 'dk-sessions-history',
	templateUrl: './sessions-history.component.html',
	styleUrls: ['./sessions-history.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionsHistoryComponent {
	public localisedTexts$ = this._translateService.selectTranslation('sessions-history');

	constructor(private _translateService: TranslateService) {}
}
