import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as api from '@dki/api-client';

import { Range } from '@libs/dash/core/entity';
import { generateXlsx, ReportOptions } from '@libs/dash/shared';
import { TranslateService } from '@libs/shared/modules/i18n';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import { isEqual, xorWith } from 'lodash-es';
import { DateTime } from 'luxon';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { EMPLOYEE_MEAL_FACADE } from '../facade/employee-meals-facade.injection.token';
import { EmployeeMealsServiceProvider } from '../facade/employee-meals-facade.provider.interface';

@Component({
	selector: 'dk-employee-meals',
	templateUrl: './employee-meals.component.html',
	styleUrls: ['./employee-meals.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeMealsComponent implements OnInit, OnDestroy {
	dateRange = new FormGroup({
		from: new FormControl(this.employeeMealsService.range.from.toJSDate()),
		to: new FormControl(this.employeeMealsService.range.to.toJSDate()),
	});

	loadedEmployeeMeals: api.EmployeeMealReport = { employee_meals: [] };
	i18n: Record<string, string> = {};
	employeeMeals$ = this.employeeMealsService.employeeMeals$;
	isLoading = this.employeeMealsService.isLoading$;
	myDefaultRestaurant$ = this.employeeMealsService.myDefaultRestaurant$;
	total = 0;
	totalCount = 0;
	restaurants = {};
	selectedRestaurants: Array<string>;
	selectedRestaurantsObjects: Array<api.Restaurant>;

	employeeReportsSig: WritableSignal<api.EmployeeMealReport> = signal(null);

	@Input() visible = false;

	destroy$: Subject<boolean> = new Subject<boolean>();

	viewData$ = combineLatest([this.employeeMeals$, this._translateService.selectTranslation('employee-meals')]).pipe(
		filter(([employeeMeals, i18n]) => !!employeeMeals),
		takeUntil(this.destroy$),
		map(([employeeMeals, i18n]) => {
			this.loadedEmployeeMeals = employeeMeals;
			this.employeeReportsSig.update(() => this.loadedEmployeeMeals);
			this.i18n = i18n;
			const init = 0;
			this.total = Object.values(this.loadedEmployeeMeals.employee_meals).reduce((t, { total_value }) => t + total_value, init);
			this.totalCount = Object.values(this.loadedEmployeeMeals.employee_meals).reduce((t, { count }) => t + count, init);
			return { employeeMeals, i18n };
		})
	);

	displayedColumnsEntries: string[] = ['restaurant', 'name', 'firstname', 'quantity', 'totalttc'];

	constructor(
		private _translateService: TranslateService,
		@Inject(EMPLOYEE_MEAL_FACADE) private readonly employeeMealsService: EmployeeMealsServiceProvider
	) {}

	ngOnInit(): void {
		this.employeeMealsService.selectedRestaurants$
			.pipe(
				filter(() => this.visible),
				withLatestFrom(this.employeeMealsService.isLoadingSavedRestaurant$),
				filter(([selectedRestaurants, loading]) => {
					return !loading && !!selectedRestaurants && !!selectedRestaurants[0];
				}),
				distinctUntilChanged(this.customComparator),
				takeUntil(this.destroy$)
			)
			.subscribe(([selectedRestaurants]) => {
				this.selectedRestaurants = selectedRestaurants.map((restaurant) => restaurant.name);
				selectedRestaurants.forEach((e) => {
					this.restaurants[e.id] = e.name;
				});
				this.setPeriod();
			});
		this.employeeMealsService.selectedRestaurants$
			.pipe(
				filter((restaurants) => restaurants.length > 0 && !!restaurants[0]),
				takeUntil(this.destroy$)
			)
			.subscribe((restaurants) => {
				this.selectedRestaurants = restaurants.map((restaurant) => restaurant.name);
				this.selectedRestaurantsObjects = restaurants;
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	setPeriod(period?: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case Range.Today:
				from = today;
				to = today;
				break;
			case Range.Week:
				from = today.startOf(Range.Week);
				to = today.endOf(Range.Week);
				break;
			case Range.Month:
				from = today.startOf(Range.Month);
				to = today.endOf(Range.Month);
				break;
			case Range.Period:
				to = !this.dateRange.controls.to.value ? from : to;
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this.employeeMealsService.getEmployeeMealsReport(from, to);
	}

	customComparator(prev, curr) {
		return (
			xorWith(
				prev.map((rest) => rest.id),
				curr.map((rest) => rest.id),
				isEqual
			).length === 0
		);
	}

	highlightedRow(row) {
		return !!row && row.label && row.label.toLowerCase().includes('total');
	}

	downloadCSV() {
		const options = {
			filename: this.i18n.title,
			decimalSeparator: '.',
			fieldSeparator: ';',
			showLabels: true,
			showTitle: true,
			title: this.i18n.title,
			useKeysAsHeaders: true,
		};
		const csvExporter = new ExportToCsv(options);
		const csv = this.loadedEmployeeMeals.employee_meals.map((employeeMeal) => {
			return {
				[this.i18n.restaurant]: this.restaurants[employeeMeal.restaurant_id],
				[this.i18n.name]: `${employeeMeal['employee'].first_name} ${employeeMeal['employee'].last_name}`,
				[this.i18n.quantity]: employeeMeal.count,
				[this.i18n.totalttc]: employeeMeal.total_value,
			};
		});
		const total = {
			[this.i18n.restaurant]: this.i18n.total,
			[this.i18n.name]: '',
			[this.i18n.quantity]: this.totalCount,
			[this.i18n.totalttc]: Number(this.total).toFixed(2),
		};
		csv.push(total);
		if (csv.length > 0) {
			csvExporter.generateCsv(csv);
		}
	}

	downloadPDF() {
		this.pdfExport('employee-meal-report', 0.19);
	}

	pdfExport(id, scale = 0.28) {
		const dateSelected = `${DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('yyyy-MM-dd')} - ${DateTime.fromJSDate(
			this.dateRange.controls.to.value
		).toFormat('yyyy-MM-dd')}`;
		const title = String(`${this.i18n.title}_${dateSelected}`);
		const source = document.getElementById(id);
		const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true });
		doc.setFontSize(16);
		doc.setFont(undefined, 'bold');
		doc.text(this.selectedRestaurants.join(','), 20, 15);
		doc.setFontSize(12);
		doc.setFont(undefined, 'light');
		doc.text(`${dateSelected}`, 20, 20);
		doc.html(source, {
			html2canvas: {
				scale: scale,
				letterRendering: false,
				ignoreElements: (e) => e.classList.contains('export-buttons'),
			},
			margin: [30, 5, 5, 5],
			windowWidth: 1000,
			width: 900,
			fontFaces: [
				{
					family: 'Roboto',
					src: [
						{
							url: '/assets/fonts/roboto.ttf',
							format: 'truetype',
						},
					],
				},
			],
			callback: (doc) => {
				doc.save(title);
			},
		});
	}

	downloadXlsx(): void {
		const employeeMealsReportOptions: ReportOptions = {
			setupHeaders: (sheet) => {
				const headers = ['Restaurant', 'Nom', 'Quantité', 'Total TTC'];
				const headerRow = sheet.addRow(headers);
				return headerRow;
			},

			prepareDataRows: (sheet) => {
				this.loadedEmployeeMeals.employee_meals.forEach((meal) => {
					const row = [
						this.restaurants[meal.restaurant_id] || 'Inconnu',
						meal.employee.last_name,
						meal.count,
						meal.total_value.toFixed(2) + ' €',
					];
					sheet.addRow(row);
				});

				const totalRow = ['Total', '', this.totalCount, this.total.toFixed(2) + ' €'];
				sheet.addRow(totalRow);
			},

			generateFileName: () => {
				const from = DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('dd/LL/yyyy');
				const to = DateTime.fromJSDate(this.dateRange.controls.to.value).toFormat('dd/LL/yyyy');
				return `RapportRepasEmployes-${from}-${to}.xlsx`;
			},
		};

		const from = DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('dd/LL/yyyy');
		const to = DateTime.fromJSDate(this.dateRange.controls.to.value).toFormat('dd/LL/yyyy');
		const detailText = `Restaurants : ${this.selectedRestaurants.join(', ')}\nDu ${from} au ${to}`;

		generateXlsx('Rapport des Repas Employés', detailText, employeeMealsReportOptions);
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}
}
