import { CommonModule } from '@angular/common';
import { NgModule, PLATFORM_ID } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

import { PageContainerModule } from '@libs/dash/shared';
import { NgPipesModule } from 'ngx-pipes';
// TODO: move to `TranslocoRootModule`
import { LocalizedDateAdapter } from '../../core/utils/localized-date.adapter';
import { EmployeeMealsModule } from '../employee-meals/employee-meals.module';
import { SynthesisReportModule } from '../synthesis-report/synthesis-report.module';
import { REPORTS_FACADE } from './facade/reports-facade.injection.token';
import { ReportsFacade } from './facade/reports-facade.service';
import { DiscountsReportComponent } from './partial-components/discounts-report/discounts-report.component';
import { FilterReportComponent } from './partial-components/filter-report/filter-report.component';
import { HourlySalesTileComponent } from './partial-components/hourly-sales-tile/hourly-sales-tile.component';
import { OperatingReportTileComponent } from './partial-components/operating-report-tile/operating-report-tile.component';
import { ProductsReportTileComponent } from './partial-components/products-report-tile/products-report-tile.component';
import { WeeklyReportTileComponent } from './partial-components/weekly-report-tile/weekly-report-tile.component';
import { SortHourlySales } from './pipes/sort-working-hours.pipe';
import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './view-component/reports.component';

const ANGULAR_MATERIAL_ELEMENTS = [MatProgressSpinnerModule, MatButtonModule, MatIconModule, MatDatepickerModule, MatExpansionModule];

@NgModule({
	imports: [
		CommonModule,
		NgPipesModule,
		...ANGULAR_MATERIAL_ELEMENTS,
		ReportsRoutingModule,
		PageContainerModule,
		MatInputModule,
		ReactiveFormsModule,
		MatTabsModule,
		MatSelectModule,
		SynthesisReportModule,
		EmployeeMealsModule,
		MatTableModule,
		MatMenuModule,
	],
	declarations: [
		ReportsComponent,
		HourlySalesTileComponent,
		OperatingReportTileComponent,
		WeeklyReportTileComponent,
		ProductsReportTileComponent,
		FilterReportComponent,
		DiscountsReportComponent,
		SortHourlySales,
	],
	providers: [
		{
			provide: REPORTS_FACADE,
			useClass: ReportsFacade,
		},
		{
			provide: DateAdapter,
			useClass: LocalizedDateAdapter,
			deps: [MAT_DATE_LOCALE, PLATFORM_ID],
		},
	],
})
export class ReportsModule {}
