<button mat-button class="common-layout-button" [matMenuTriggerFor]="menu">
	<mat-icon>file_download</mat-icon>
	<span [translate]="'common-layout.export'"></span>
</button>
<mat-menu
	#menu="matMenu"
	class="!rounded-3xl !shadow-lg !overflow-hidden !bg-surface-white !flex !flex-col !items-center !justify-center !w-[280px] p-4 custom-menu"
	(close)="onClose()"
>
	<div class="bg-surface-white flex flex-col w-full !h-max" (click)="$event.stopPropagation()">
		<div class="flex flex-col justify-center">
			<div class="flex items-center flex-row-reverse justify-between w-full" *ngFor="let option of exportOptions">
				<mat-checkbox
					[(ngModel)]="option.selected"
					(change)="updateSelection($event)"
					class="!inline-flex !relative common-layout-checkbox"
				>
				</mat-checkbox>
				<span class="ml-2" [translate]="option.label"></span>
			</div>
		</div>
		<div class="flex justify-end gap-1">
			<button
				mat-button
				(click)="onCancel()"
				class="!text-accent-primary !hover:bg-surface-darkGrey"
				[translate]="'common-layout.cancel'"
			></button>
			<button
				mat-button
				(click)="onExport()"
				class="!text-accent-primary !hover:bg-surface-darkGrey"
				[translate]="'common-layout.apply'"
			></button>
		</div>
	</div>
</mat-menu>
