@if (isBKC) {
	<bkc-cumul-table [viewdata]="contentViewData()"
	                 [tableData]="dataTableMappedSig()"
	                 [sourceType]="sourceType"
	                 [selectedRestaurants]="selectedRestaurants"
	                 [dateRange]="dateRange"
	                 [goals]="goals"
	                 [dayParts]="dayParts">

	</bkc-cumul-table>
} @else {
	<bkf-table-cumul [viewdata]="contentViewData()"
	                 [tableData]="dataTableMappedSig()"
	                 [sourceType]="sourceType"
	                 [selectedRestaurants]="selectedRestaurants"
	                 [dateRange]="dateRange"
	                 [goals]="goals"
	                 [dayParts]="dayParts">

	</bkf-table-cumul>
}