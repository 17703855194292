import { ChangeDetectionStrategy, Component, inject, Signal } from '@angular/core';
import { HIDDEN_BREADCRUMB, SKIPPED_BREADCRUMB } from '@libs/gap/models';
import { LOCAL_STORAGE_KEYS } from "@libs/shared/models";
import { BreadcrumbItem } from '@libs/shared/modules/breadcrumb';
import { CommonLayoutService, HEADER_ACTIONS } from '@libs/shared/modules/common-layout';
import { LanguageCode, LANGUAGES_LONG, LanguageService, TranslateService } from '@libs/shared/modules/i18n';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'merim-common-layout-header',
	templateUrl: './common-layout-header.component.html',
	styleUrl: './common-layout-header.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonLayoutHeaderComponent<T> {
	private readonly _commonLayoutService: CommonLayoutService<T> = inject(CommonLayoutService<T>);

	userName: Signal<string> = this._commonLayoutService.userName;
	userEmail: Signal<string> = this._commonLayoutService.userEmail;
	readonly languageService: LanguageService = inject(LanguageService);
	private readonly _translateService: TranslateService = inject(TranslateService);
	breadcrumbs$ = new BehaviorSubject<BreadcrumbItem[]>([]);

	languageChange(lang: LanguageCode): void {
		this.languageService.currentLanguage = lang;
		localStorage.setItem(LOCAL_STORAGE_KEYS.LANGUAGE, lang);
	}

	getFirstName(): string {
		return `${this._translateService.instant('COMMON_GOOD_DAY')} ${this.userName().split(' ')?.[0] || ''} !`;
	}

	logout(): void {
		this._commonLayoutService.actionsSubject.next(HEADER_ACTIONS.LOGOUT);
	}

	setBreadcrumbs(breadcrumbs: BreadcrumbItem[]) {
		this.breadcrumbs$.next(breadcrumbs);
	}

	openSettings(): void {
		this._commonLayoutService.actionsSubject.next(HEADER_ACTIONS.SETTINGS);
	}

	isSettingsAllowed(): boolean {
		return this._commonLayoutService.availableActions.includes(HEADER_ACTIONS.SETTINGS);
	}

	protected readonly LANGUAGES_LONG = LANGUAGES_LONG;
	protected readonly skippedBreadcrumb = SKIPPED_BREADCRUMB;
	protected readonly hiddenBreadcrumb = HIDDEN_BREADCRUMB;
}
