import { Component, Inject } from '@angular/core';
import { TranslateService } from '@libs/shared/modules/i18n';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { TODAYS_OVERVIEW_FACADE } from '../../facade/todays-overview-facade.injection.token';
import { TodaysOverviewServiceProvider } from '../../facade/todays-overview-facade.provider.interface';

@Component({
	selector: 'dk-channel-performance-tile',
	templateUrl: './channel-performance-tile.component.html',
	styleUrls: ['./channel-performance-tile.component.scss'],
})
export class ChannelPerformanceTileComponent {
	public viewData$ = combineLatest([
		this._todaysOverviewFacade.channelPerformanceTableData$,
		this._translateService.selectTranslation('channelPerformanceTile'),
	]).pipe(
		map(([channelPerformanceTableData, i18n]) => {
			return { channelPerformanceTableData, i18n };
		})
	);

	constructor(
		private _translateService: TranslateService,
		@Inject(TODAYS_OVERVIEW_FACADE) private _todaysOverviewFacade: TodaysOverviewServiceProvider
	) {}
}
