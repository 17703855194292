import { Component, Inject } from '@angular/core';
import { TranslateService } from '@libs/shared/modules/i18n';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { ANALYZE_HISTORY_FACADE } from '../../facade/analyze-history-facade.injection.token';
import { AnalyzeHistoryServiceProvider } from '../../facade/analyze-history-facade.provider.interface';

@Component({
	selector: 'dk-passed-waiting-time-tile',
	templateUrl: './waiting-time-tile.component.html',
	styleUrls: ['./waiting-time-tile.component.scss'],
})
export class PassedWaitingTimeTileComponent {
	public viewData$ = combineLatest([
		this._analyzeHistoryFacade.customerWaitingTime$,
		this._translateService.selectTranslation('customersWaitingTimeTile'),
	]).pipe(
		map(([customerWaitingTime, i18n]) => {
			return { customerWaitingTime, i18n };
		})
	);

	constructor(
		private _translateService: TranslateService,
		@Inject(ANALYZE_HISTORY_FACADE) private _analyzeHistoryFacade: AnalyzeHistoryServiceProvider
	) {}
}
