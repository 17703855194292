import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IPeriodData, Period, TimeStep, ValueType } from '@libs/dash/core/entity';
import { DataTableCumulComponent, SecondsToMins } from '@libs/dash/features/v1';
import { SOS_TABLE_COLUMNS } from '@libs/dash/features/v2';
import { ColumnType, CommonLayoutTableComponent, CommonTableColumnGroup, CommonTableConfig } from '@libs/shared/modules/common-components';
import { TranslateService } from '@libs/shared/modules/i18n';

@Component({
	selector: 'sos-data-table-cumul',
	standalone: true,
	imports: [CommonModule, SecondsToMins, CommonLayoutTableComponent],
	templateUrl: './sos-data-table-cumul.component.html',
	styleUrl: './sos-data-table-cumul.component.scss',
	providers: [SecondsToMins],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SosDataTableCumulComponent extends DataTableCumulComponent implements OnInit {
	private readonly _translateService: TranslateService = inject(TranslateService);
	private readonly _secondsToMins: SecondsToMins = inject(SecondsToMins);

	tableConfig: CommonTableConfig<IPeriodData>;

	tableGroups: CommonTableColumnGroup[] = [
		{
			titleKey: 'sosKioskTile.dataTable.operationalTimes',
			columns: [SOS_TABLE_COLUMNS.PreparationTime, SOS_TABLE_COLUMNS.ServeTime, SOS_TABLE_COLUMNS.TotalTime],
		},
	];

	@ViewChild('coloredTime', { read: TemplateRef, static: true }) coloredTime: TemplateRef<any>;

	override ngOnInit(): void {
		this.initConfig();
	}

	override getClassForValue(value: number, type: ValueType): string {
		if (!value || isNaN(value)) {
			return '';
		}
		return this.isThresholdExceeded(value, type) ? 'red' : 'green';
	}

	private initConfig(): void {
		this.tableConfig = {
			titleKey: 'sosKioskTile.dataTable.cumul',
			columns: [
				{
					key: SOS_TABLE_COLUMNS.Period,
					headerLabelKey: 'sosKioskTile.dataTable.slots',
					alignment: 'left',
					columnType: ColumnType.Translation,
					width: '19.5%',
					valueGetter: (cell: Period) => `sosKioskTile.daypartLabels.${cell}`,
				},
				{
					key: SOS_TABLE_COLUMNS.Schedule,
					headerLabelKey: 'sosKioskTile.dataTable.schedule',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					valueGetter: (cell: Period, row: IPeriodData) => {
						const formatTime = (time: number) => time.toString().padStart(2, '0');
						const template = this._translateService.instant('sosKioskTile.timeRange');
						return template.replace('{start}', formatTime(row.start)).replace('{end}', formatTime(row.end));
					},
					totalGetter: () => this._translateService.instant('sosKioskTile.dataTable.totalAverage'),
				},
				{
					key: SOS_TABLE_COLUMNS.TotalRevenue,
					headerLabelKey: 'sosKioskTile.dataTable.revenue',
					alignment: 'right',
					columnType: ColumnType.Price,
					width: '11.5%',
					totalGetter: (data: IPeriodData[]) => this.getTotalForField('total_revenue'),
				},
				{
					key: SOS_TABLE_COLUMNS.MeanRevenue,
					headerLabelKey: 'sosKioskTile.dataTable.averageTicket',
					alignment: 'right',
					columnType: ColumnType.Price,
					width: '11.5%',
					totalGetter: (data: IPeriodData[]) => this.calculateAverageForField('total_revenue'),
				},
				{
					key: SOS_TABLE_COLUMNS.Count,
					headerLabelKey: 'sosKioskTile.dataTable.orderCount',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					totalGetter: (data: IPeriodData[]) => this.getTotalForField('count'),
				},
				{
					key: SOS_TABLE_COLUMNS.PreparationTime,
					headerLabelKey: 'sosKioskTile.dataTable.preparation',
					alignment: 'right',
					columnType: ColumnType.Custom,
					width: '11.5%',
					info: { valueType: ValueType.Preparation },
					valueGetter: (cell: number) => cell,
					totalGetter: (data: IPeriodData[]) => this._secondsToMins.transform(this.calculateAverageForField(TimeStep.PreparationTime)),
					templateRef: this.coloredTime,
				},
				{
					key: SOS_TABLE_COLUMNS.ServeTime,
					headerLabelKey: 'sosKioskTile.dataTable.departure',
					alignment: 'right',
					columnType: ColumnType.Custom,
					width: '11.5%',
					info: { valueType: ValueType.Depart },
					valueGetter: (cell: number) => cell,
					totalGetter: (data: IPeriodData[]) => this._secondsToMins.transform(this.calculateAverageForField(TimeStep.ServeTime)),
					templateRef: this.coloredTime,
				},
				{
					key: SOS_TABLE_COLUMNS.TotalTime,
					headerLabelKey: 'sosKioskTile.dataTable.totalService',
					alignment: 'right',
					columnType: ColumnType.Custom,
					width: '11.5%',
					info: { valueType: ValueType.Total },
					valueGetter: (cell: number, row: IPeriodData) => row[TimeStep.PreparationTime] + row[TimeStep.ServeTime],
					templateRef: this.coloredTime,
					totalGetter: (data: IPeriodData[]) =>
						this._secondsToMins.transform(
							this.calculateAverageForField(TimeStep.ServeTime) + this.calculateAverageForField(TimeStep.PreparationTime)
						),
				},
			],
		};
	}

	override positionSecondLabel(): void {
		return;
	}
}
