import { CommonModule } from '@angular/common';
import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	EventEmitter,
	inject,
	Input,
	Output,
	QueryList
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Restaurant } from '@dki/api-client';
import { Clearable, CommonLayoutSelectComponent } from '@libs/shared/modules/common-components';
import { TranslateModule } from '@libs/shared/modules/i18n';
import { ExportOptionsComponent } from '../export-options/export-options.component';
import { ExportOption } from '../layout-filter-service/layout-filter.service';
import { RestaurantSelectorNewComponent } from '../restaurant-selector-new/restaurant-selector-new.component';

@Component({
	selector: 'layout-filters',
	standalone: true,
	imports: [CommonModule, RestaurantSelectorNewComponent, MatButtonModule, MatIconModule, TranslateModule, ExportOptionsComponent],
	templateUrl: './layout-filters.component.html',
	styleUrls: ['./layout-filters.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutFiltersComponent implements AfterViewChecked {
	private _cd: ChangeDetectorRef = inject(ChangeDetectorRef);

	@Input() multiSelectRestaurant = false;
	@Input() availableExportOptions: ExportOption[] = [];

	@Output() restaurantSelected = new EventEmitter<Restaurant | Restaurant[]>();
	@Output() exportSelected = new EventEmitter<string[]>();

	@ContentChildren(CommonLayoutSelectComponent) selects: QueryList<Clearable>;

	clearAll(): void {
		this.selects.forEach((e) => e.clear());
	}

	ngAfterViewChecked() {
		this._cd.detectChanges();
	}
}
