import { Pipe, PipeTransform } from '@angular/core';
import { CommonTableColumnConfig } from '@libs/shared/modules/common-components';

@Pipe({
	name: 'CommonLayoutTableValueGetter',
	standalone: true,
})
export class CommonLayoutTableValueGetterPipe<T> implements PipeTransform {
	transform(column: CommonTableColumnConfig<T>, row: T, rowIndex: number, data: T[]): any {
		return column?.valueGetter ? column.valueGetter(row[column.key] ?? undefined, row, rowIndex, data) : row[column.key] ?? 'N/A';
	}
}

@Pipe({
	name: 'CommonLayoutTableDetailGetter',
	standalone: true,
})
export class CommonLayoutTableDetailGetterPipe<T> implements PipeTransform {
	transform(column: CommonTableColumnConfig<T>, row: T, rowIndex: number, data: T[]): any {
		return column?.detailGetter ? column.detailGetter(row[column.key] ?? undefined, row, rowIndex, data) : row[column.key] ?? 'N/A';
	}
}
