import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, signal, Signal } from '@angular/core';
import { Restaurant } from '@dki/api-client';
import { DatePeriod, DayOfWeek, IChannelOption, SalesChannel } from '@libs/dash/core/entity';
import { RestaurantSelectionModule, SourceType, SpeedOfServiceModule } from '@libs/dash/features/v1';
import {
	CommonButtonToggleGroupComponent,
	CommonLayoutSelectComponent,
	CommonLayoutTab,
	CommonLayoutTabsComponent,
	CommonRangePickerComponent,
	CommonScreenTitleComponent,
	CommonSelectOption,
} from '@libs/shared/modules/common-components';
import { TranslateService } from '@libs/shared/modules/i18n';
import { DatePickerNewComponent } from '../date-picker-new/date-picker-new.component';
import { DashLayoutFilters, LayoutFilterService, LayoutRange } from '../layout-filter-service/layout-filter.service';
import { LayoutFiltersComponent } from '../layout-filters/layout-filters.component';
import { RestaurantSelectorNewComponent } from '../restaurant-selector-new/restaurant-selector-new.component';
import { BkfCumulWrapperComponent } from './partial-components/cumul-wrapper/bkf-cumul-wrapper.component';
import { BkcComponent } from './partial-screens/bkc/bkc.component';
import { BkfComponent } from './partial-screens/bkf/bkf.component';
import { SosContentComponent } from './partial-screens/sos-content/sos-content.component';

export enum DttExportGroupType {
	CumulRes = 'cumul_res',
	CumulAll = 'cumul_all',
}
@Component({
	selector: 'time-of-service',
	standalone: true,
	imports: [
		CommonModule,
		SpeedOfServiceModule,
		CommonButtonToggleGroupComponent,
		CommonScreenTitleComponent,
		CommonRangePickerComponent,
		CommonLayoutTabsComponent,
		RestaurantSelectionModule,
		RestaurantSelectorNewComponent,
		LayoutFiltersComponent,
		DatePickerNewComponent,
		CommonLayoutSelectComponent,
		SosContentComponent,
	],
	templateUrl: './time-of-service.component.html',
	styleUrl: './time-of-service.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslateService],
})
export class TimeOfServiceComponent implements OnInit {
	private readonly _translateService: TranslateService = inject(TranslateService);
	private readonly _layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	selectedTabId: string;
	selectedOptions: string[] = [];

	get range(): Signal<LayoutRange> {
		return this._layoutFilterServiceService.range;
	}

	get rangePeriod(): Signal<DatePeriod> {
		return this._layoutFilterServiceService.rangePeriod;
	}

	get filters(): DashLayoutFilters {
		return this._layoutFilterServiceService.filters;
	}

	tabs: CommonLayoutTab[] = [
		{
			label: 'time-of-service-obj.onsite',
			id: 'onsite',
			content: SosContentComponent,
		},
		{
			label: 'time-of-service-obj.drive',
			id: 'drive',
			content: BkfComponent,
		},
		{
			label: 'time-of-service-obj.bkc',
			id: 'bkc',
			content: BkcComponent,
		},
	];

	channels: IChannelOption[] = [
		{ value: null, label: this._translateService.instant('sosKioskTile.salesChannels.all') },
		...Object.values(SalesChannel).map((channel) => ({
			value: channel,
			label: this._translateService.instant(`sosKioskTile.salesChannels.${channel.toLowerCase()}`),
		})),
	];

	days: CommonSelectOption[] = Object.values(DayOfWeek).map((day) => ({
		labelKey: `sosKioskTile.days.${day}`,
		value: day,
	}));

	groupTypes: CommonSelectOption[] = [
		{
			labelKey: `dtt-export.group_type.cumul_all`,
			value: DttExportGroupType.CumulAll,
		},
		{
			labelKey: `dtt-export.group_type.cumul_res`,
			value: DttExportGroupType.CumulRes,
		},
	];

	sourceTypes: CommonSelectOption[] = [
		{
			labelKey: `dtt-export.source_types.both`,
			value: 'both',
		},
		{
			labelKey: `dtt-export.source_types.cod`,
			value: 'cod',
		},
		{
			labelKey: `dtt-export.source_types.tablet`,
			value: 'tablet',
		},
	];

	ngOnInit(): void {
		this.initFilters();
	}

	setRestaurants(restaurant: Restaurant | Restaurant[]): void {
		this._layoutFilterServiceService.updateFilter('restaurant', restaurant);
	}

	onTabsChanged(event: string): void {
		this.selectedTabId = event;
	}

	channelSelected(channel: string | string[] | null): void {
		this._layoutFilterServiceService.updateFilter('channel', channel);
	}

	daySelected(day: string | string[] | null): void {
		this._layoutFilterServiceService.updateFilter('day', day);
	}

	groupSelected(group: string | string[]): void {
		this._layoutFilterServiceService.updateFilter('groupType', group as DttExportGroupType);
	}

	sourceSelected(source: string | string[]): void {
		this._layoutFilterServiceService.updateFilter('source', source as SourceType);
	}

	onDateSelection(event: LayoutRange): void {
		this._layoutFilterServiceService.setRange(event);
	}

	onDatePeriodSelection(period: DatePeriod): void {
		this._layoutFilterServiceService.setRangePeriod(period);
	}

	exportData(selectedOptions: string[]) {
		this._layoutFilterServiceService.emitExport(selectedOptions);
	}

	private initFilters(): void {
		this._layoutFilterServiceService.registerFilter('restaurant', signal<Restaurant | Restaurant[]>([]));
		this._layoutFilterServiceService.registerFilter('channel', signal<string | string[] | null>(null));
		this._layoutFilterServiceService.registerFilter('source', signal<SourceType>(SourceType.boths));
		this._layoutFilterServiceService.registerFilter('day', signal<string | string[] | null>(null));
		this._layoutFilterServiceService.registerFilter('groupType', signal<DttExportGroupType>(DttExportGroupType.CumulAll));
	}
}
