import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@libs/shared/modules/i18n';

@Component({
	selector: 'merim-common-screen-title',
	standalone: true,
	imports: [CommonModule, TranslateModule],
	templateUrl: './common-screen-title.component.html',
	styleUrl: './common-screen-title.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonScreenTitleComponent {
	@Input({ required: true }) title = '';
}
