import { Component } from '@angular/core';
import { TranslateService } from '@libs/shared/modules/i18n';

@Component({
	selector: 'dk-password-renewal-sent',
	templateUrl: './password-renewal-sent.component.html',
	styleUrls: ['./password-renewal-sent.component.scss'],
})
export class PasswordRenewalSentComponent {
	localisedTexts$ = this._translateService.selectTranslation('password-renewal-sent');

	constructor(private _translateService: TranslateService) {}
}
