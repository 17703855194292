<mat-form-field appearance="outline" [subscriptSizing]="'dynamic'" class="common-layout-input">
	<mat-date-range-input [formGroup]="range" [rangePicker]="picker">
		<input matStartDate
		       formControlName="from"
		       #from
		       [placeholder]="'common-layout.date-picker.placeholder-start-date' | translate">
		<input matEndDate
		       formControlName="to"
		       #to
		       [placeholder]="'common-layout.date-picker.placeholder-end-date' | translate">
	</mat-date-range-input>
	<mat-label>{{ 'common-layout.date-picker.label' | translate }}</mat-label>
	<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
	<mat-date-range-picker #picker>
		<mat-date-range-picker-actions>
			<button mat-button color="primary" class="common-layout-button accent-primary" matDateRangePickerCancel>
				{{ 'common-layout.cancel' | translate }}
			</button>
			<button mat-button color="primary" class="common-layout-button accent-primary" matDateRangePickerApply
			        (click)="clickApply(from.value, to.value)">
				{{ 'common-layout.apply' | translate }}
			</button>
		</mat-date-range-picker-actions>
	</mat-date-range-picker>

	@if (range?.controls?.start?.hasError('matStartDateInvalid')) {
		<mat-error>{{ 'common-layout.date-picker.invalid-start-date' | translate }}</mat-error>
	}
	@if (range?.controls?.end?.hasError('matEndDateInvalid')) {
		<mat-error>{{ 'common-layout.date-picker.invalid-end-date' | translate }}</mat-error>
	}
</mat-form-field>
