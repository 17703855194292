import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTable, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@libs/shared/modules/i18n';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonLayoutTableDetailGetterPipe, CommonLayoutTableValueGetterPipe } from './common-layout-table-value-getter.pipe';

export enum ColumnType {
	Text = 'text',
	Translation = 'translation',
	Price = 'price',
	Percent = 'percent',
	Date = 'date',
	Custom = 'custom',
}

export type CommonTableColumnType = string;
export type ValueOf<T> = T[keyof T];

export interface CommonTableColumnGroup {
	titleKey: string;
	columns: CommonTableColumnType[];
}

export interface CommonTableColumnConfig<T> {
	key: CommonTableColumnType;
	headerLabelKey: string;
	columnType: ColumnType;
	detailColumnType?: ColumnType;
	totalColumnType?: ColumnType;
	width?: string;
	valueGetter?: (cell?: ValueOf<T>, row?: T, rowIndex?: number, data?: T[]) => any;
	detailGetter?: (cell?: ValueOf<T>, row?: T, rowIndex?: number, data?: T[]) => any;
	totalGetter?: (data?: T[]) => any;
	info?: any;
	templateRef?: TemplateRef<any>;
	classGetter?: (cell?: ValueOf<T>, row?: T, rowIndex?: number, data?: T[]) => string;
	alignment?: string;
}

export interface CommonTableConfig<T> {
	titleKey: string;
	columns: CommonTableColumnConfig<T>[];
	detailsKey?: string;
	scrollable?: boolean;
}

@Component({
	selector: 'merim-common-layout-table',
	standalone: true,
	imports: [
		CommonModule,
		MatTableModule,
		MatIconModule,
		TranslateModule,
		CommonLayoutTableValueGetterPipe,
		CommonLayoutTableDetailGetterPipe,
	],
	templateUrl: './common-layout-table.component.html',
	styleUrls: ['./common-layout-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
	providers: [CommonLayoutTableValueGetterPipe, CommonLayoutTableDetailGetterPipe, DatePipe],
})
export class CommonLayoutTableComponent<T> {
	private readonly _elementRef: ElementRef = inject(ElementRef);

	ColumnType = ColumnType;
	displayedColumns: CommonTableColumnConfig<T>[] = [];
	expandedElement: T | null = null;

	private _config: CommonTableConfig<T>;
	private _tableDisplayColumns: string[] = [];

	get config(): CommonTableConfig<T> {
		return this._config;
	}

	get tableDisplayedColumns(): string[] {
		return this._tableDisplayColumns;
	}

	@Input() id: string;
	@Input() columnGroups: CommonTableColumnGroup[] = [];
	@Input() paddingTop: boolean = true;
	@Input({ required: true }) dataSource: T[] = [];

	@Input({ required: true }) set config(config: CommonTableConfig<T>) {
		this._handleConfigChange(config);
	}

	@ViewChild(MatTable) table: MatTable<T>;
	@ViewChild('matTable') matTable: ElementRef;

	private _resizeObserver: ResizeObserver;
	private _resizeSubscription: Subscription;

	ngOnDestroy() {
		if (this._resizeObserver) {
			this._resizeObserver.disconnect();
		}
		if (this._resizeSubscription) {
			this._resizeSubscription.unsubscribe();
		}
	}

	contentChanged(): void {
		this._initializeResizeHandling();
		this._adjustColumnGroups();
	}

	isHeaderOnLastColumn(columnKey: CommonTableColumnType): boolean {
		return this.columnGroups.map((group) => group.columns).some((columns) => columns.some((c) => c === columnKey));
	}

	private _handleConfigChange(config: CommonTableConfig<T>): void {
		this._config = config;

		if (this._config) {
			this._tableDisplayColumns = this._config.columns.map((column) => column.key);
			this.displayedColumns = this.config.columns;
		}
	}

	private _initializeResizeHandling(): void {
		const table = this._elementRef.nativeElement.querySelector('.mat-mdc-table');
		if (table) {
			this._resizeObserver = new ResizeObserver(() => this._adjustColumnGroups());
			this._resizeObserver.observe(table);
		}

		this._resizeSubscription = fromEvent(window, 'resize')
			.pipe(debounceTime(250))
			.subscribe(() => this._adjustColumnGroups());
	}

	toggleRow(element: T, event: MouseEvent): void {
		if (!this.config?.detailsKey) {
			return;
		}

		if (this._isClickOnIcon(event)) {
			event.stopPropagation();
		}
		this.expandedElement = this.expandedElement === element ? null : element;
		this.table.renderRows();
	}

	private _isClickOnIcon(event: MouseEvent): boolean {
		return (event.target as HTMLElement).tagName.toLowerCase() === 'mat-icon';
	}

	private _adjustColumnGroups(): void {
		const headerRow = this._elementRef?.nativeElement?.querySelector('.mat-mdc-header-row');
		if (!headerRow) {
			return;
		}

		const cells = headerRow.querySelectorAll('.mat-mdc-header-cell');
		const header = this._elementRef?.nativeElement?.querySelector('.column-group-tab-primary');
		const tabs = this._elementRef?.nativeElement?.querySelectorAll('.column-group-tab-secondary');
		const table = this._elementRef?.nativeElement?.querySelector('.mat-mdc-table');
		const tableRect = table?.getBoundingClientRect();

		if (!tableRect || !cells || !header || this.config.columns.length === 0) {
			return;
		}

		this._adjustHeader(cells, header, tableRect);
		this.columnGroups.forEach((group, index) => {
			this._adjustColumnGroup(group, index, cells, tabs, tableRect);
		});
	}

	private _adjustColumnGroup(
		group: CommonTableColumnGroup,
		index: number,
		cells: NodeListOf<Element>,
		tabs: NodeListOf<Element>,
		tableRect: DOMRect
	): void {
		const tab = tabs[index] as HTMLElement;
		const firstColumnIndex = this.tableDisplayedColumns.indexOf(group.columns[0]);
		const lastColumnIndex = this.tableDisplayedColumns.indexOf(group.columns[group.columns.length - 1]);

		if (firstColumnIndex !== -1 && lastColumnIndex !== -1 && tableRect) {
			this._setElementStyles(
				tab,
				firstColumnIndex,
				lastColumnIndex,
				cells,
				tableRect,
				(left) => left - 1,
				(width) => width + 1
			);
		}
	}

	private _adjustHeader(cells: NodeListOf<Element>, header: Element, tableRect: DOMRect): void {
		const headerElement = header as HTMLElement;
		this._setElementStyles(headerElement, 0, 0, cells, tableRect);
	}

	private _setElementStyles(
		element: HTMLElement,
		firstColumnIndex: number,
		lastColumnIndex: number,
		cells: NodeListOf<Element>,
		tableRect: DOMRect,
		leftGetter: (value: number) => number = (value: number) => value,
		widthGetter: (value: number) => number = (value: number) => value
	): void {
		const firstCell = cells[firstColumnIndex] as HTMLElement;
		const lastCell = cells[lastColumnIndex] as HTMLElement;
		if (firstCell && lastCell) {
			const firstCellRect = firstCell.getBoundingClientRect();
			const lastCellRect = lastCell.getBoundingClientRect();

			const left = firstCellRect.left - tableRect.left;
			const width = lastCellRect.right - firstCellRect.left;

			element.style.position = 'absolute';
			element.style.left = `${leftGetter(left)}px`;
			element.style.width = `${widthGetter(width)}px`;
		}
	}
}
