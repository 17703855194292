import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Signal } from '@angular/core';
import { DttExportModule, SourceType } from "@libs/dash/features/v1";
import { DttExportGroupType, LayoutFilterService } from '@libs/dash/features/v2';
import { BkfRestoWrapperComponent } from "../../partial-components/resto-wrapper/bkf-resto-wrapper.component";
import { BkfCumulWrapperComponent } from "../../partial-components/cumul-wrapper/bkf-cumul-wrapper.component";

@Component({
	selector: 'bkc',
	standalone: true,
	imports: [CommonModule, DttExportModule, BkfCumulWrapperComponent, BkfRestoWrapperComponent],
	templateUrl: './bkc.component.html',
	styleUrl: './bkc.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BkcComponent {
	private readonly _layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);
	groupTypeSig: Signal<DttExportGroupType> = this._layoutFilterServiceService.filters.groupType;
	sourceTypeSig: Signal<SourceType> = this._layoutFilterServiceService.filters.source;
	groupTypes = DttExportGroupType;
}
