import { Component, Inject, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as api from '@dki/api-client';

import { Range } from '@libs/dash/core/entity';
import { TranslateService } from '@libs/shared/modules/i18n';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import { cloneDeep } from 'lodash-es';
import { DateTime } from 'luxon';
import { combineLatest, Subject, tap } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { REPORTS_FACADE } from '../../facade/reports-facade.injection.token';
import { ReportsServiceProvider } from '../../facade/reports-facade.provider.interface';

@Component({
	selector: 'dk-discounts',
	templateUrl: './discounts-report.component.html',
	styleUrls: ['./discounts-report.component.scss'],
})
export class DiscountsReportComponent implements OnInit, OnDestroy {
	isLoading = this.reportsService.isLoadingDiscountsReport$;
	i18n$ = this.translateService.selectTranslation('reports').pipe(map((reports) => reports.discount));
	i18n: any = {};
	discountsReport: api.DiscountsReport;
	discountsReportSig: WritableSignal<api.DiscountsReport> = signal(null);
	displayedColumnsEntries = ['libelle', 'percent', 'value', 'quantity', 'discountvat', 'discount'];
	destroy$: Subject<boolean> = new Subject<boolean>();

	dateRange = new FormGroup({
		from: new FormControl(this.reportsService.ranges.discountsReport.from.toJSDate()),
		to: new FormControl(this.reportsService.ranges.discountsReport.to.toJSDate()),
	});

	selectedRestaurants: Array<string>;

	constructor(
		private readonly translateService: TranslateService,
		@Inject(REPORTS_FACADE) private readonly reportsService: ReportsServiceProvider
	) {}

	ngOnInit(): void {
		combineLatest([this.i18n$, this.reportsService.discountReport$])
			.pipe(
				tap(([i18n, discountReport]) => this.discountsReportSig.update(() => discountReport)),
				filter(([i18n, discountReport]) => !!discountReport && discountReport.by_discount_type?.length > 0),
				map(([i18n, discountReport]) => {
					const _discountReport = cloneDeep(discountReport);
					const employeeDiscounts: api.DiscountReportItem[] = [];
					const discounts: api.DiscountReportItem[] = [];
					discountReport.by_discount_type.forEach((discount) => {
						if (discount.id === 0) employeeDiscounts.push(discount);
						else discounts.push(discount);
					});
					_discountReport.by_discount_type = discounts;
					employeeDiscounts.forEach((discount) => {
						_discountReport.total.count -= discount.count;
						_discountReport.total.total_value -= discount.total_value;
						_discountReport.total.total_value_excluding_vat -= discount.total_value_excluding_vat;
					});
					return [i18n, _discountReport];
				}),
				takeUntil(this.destroy$)
			)
			.subscribe(([i18n, discountReport]) => {
				this.i18n = i18n;
				this.discountsReport = discountReport;
				this.discountsReportSig.update(() => discountReport);
			});
		this.reportsService.selectedRestaurants$
			.pipe(
				filter((restaurants) => restaurants.length > 0 && !!restaurants[0]),
				takeUntil(this.destroy$)
			)
			.subscribe((restaurants) => {
				this.selectedRestaurants = restaurants.map((restaurant) => restaurant.name);
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	setPeriod(period?: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case Range.Today:
				from = today;
				to = today;
				break;
			case Range.Week:
				from = today.startOf(Range.Week);
				to = today.endOf(Range.Week);
				break;
			case Range.Month:
				from = today.startOf(Range.Month);
				to = today.endOf(Range.Month);
				break;
			case Range.Period:
				to = !this.dateRange.controls.to.value ? from : to;
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this.reportsService.getDiscountsReport(from, to);
	}

	downloadCSV() {
		const options = {
			filename: this.i18n['title'],
			decimalSeparator: '.',
			fieldSeparator: ';',
			showLabels: true,
			showTitle: true,
			title: this.i18n['title'],
			useKeysAsHeaders: true,
		};
		const csv = [];
		this.discountsReport.by_discount_type.forEach((element) => {
			const entry = {
				[this.i18n.libelle]: element.name,
				[this.i18n.percent]: element.percent ? this.i18n.yes : this.i18n.no,
				[this.i18n.value]: element.value,
				[this.i18n.quantity]: element.count,
				[this.i18n.discountAmountVat]: Number(element.total_value).toFixed(2),
				[this.i18n.discountAmountGross]: Number(element.total_value_excluding_vat).toFixed(2),
			};
			csv.push(entry);
		});
		const total = {
			[this.i18n.libelle]: this.i18n.total,
			[this.i18n.percent]: '',
			[this.i18n.value]: '',
			[this.i18n.quantity]: this.discountsReport.total.count,
			[this.i18n.discountAmountVa]: Number(this.discountsReport.total.total_value).toFixed(2),
			[this.i18n.discountAmountGross]: Number(this.discountsReport.total.total_value_excluding_vat).toFixed(2),
		};
		csv.push(total);
		const csvExporter = new ExportToCsv(options);
		csvExporter.generateCsv(csv);
	}

	downloadPDF() {
		this.pdfExport('discount-report', 0.21);
	}

	pdfExport(id, scale = 0.28) {
		const dateSelected = `${DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('yyyy-MM-dd')} - ${DateTime.fromJSDate(
			this.dateRange.controls.to.value
		).toFormat('yyyy-MM-dd')}`;
		const title = String(`${this.i18n.title}_${dateSelected}`);
		const source = document.getElementById(id);
		const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true });
		doc.setFontSize(16);
		doc.setFont(undefined, 'bold');
		doc.text(this.selectedRestaurants.join(','), 20, 15);
		doc.setFontSize(12);
		doc.setFont(undefined, 'light');
		doc.text(`${dateSelected}`, 20, 20);
		doc.html(source, {
			html2canvas: {
				scale: scale,
				letterRendering: false,
				ignoreElements: (e) => e.classList.contains('export-buttons'),
			},
			margin: [30, 5, 5, 5],
			windowWidth: 1300,
			width: 1000,
			fontFaces: [
				{
					family: 'Roboto',
					src: [
						{
							url: '/assets/fonts/roboto.ttf',
							format: 'truetype',
						},
					],
				},
			],
			callback: (doc) => {
				doc.save(title);
			},
		});
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}
}
