@if (viewData$ | async; as viewData) {
	<div class="header">
		<h2>{{ viewData.i18n.speedOfService.pageTitle }}</h2>
		<app-date-picker (dateSelection)="onDateSelection($event)" [initDate]="initDate"></app-date-picker>
	</div>
	<div class="indicators">
		<p class="objective-label">{{ viewData.i18n.speedOfService.objectiveLabel }}</p>
		@for (objective of serviceObjectives; track objective.type) {
			<indicator-card
				[label]="viewData.i18n.speedOfService.indicators[objective.type]"
				[objective]="objective.objective"
				[percent]="calculatePercent(objective.type)"
			>
			</indicator-card>
		}
	</div>
	<div class="restaurant-options">
		<app-restaurant-picker
			[restaurants]="viewData.myRestaurants"
			(restaurantSelection)="onRestaurantSelection($event)"
			[initialRestaurant]="selectedRestaurants$ | async"
		></app-restaurant-picker>
		<app-sales-channel-selector (channelSelected)="onChannelSelection($event)"></app-sales-channel-selector>
		<app-export-button (click)="export()"></app-export-button>
	</div>
	<app-days-selector (daysChanged)="onDaysChanged($event)"></app-days-selector>
	<div id="data-table">
		<data-table-cumul [data]="viewData.sosData"></data-table-cumul>
		<data-table [data]="viewData.sosDataByRestaurants" [restaurants]="viewData.myRestaurants"></data-table>
	</div>
} @else {
	<p [translate]="'sosKioskTile.speedOfService.loading'"></p>
}
