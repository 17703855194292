import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { getLocale } from '@libs/dash/core';

import { Range, sortLabels } from '@libs/dash/core/entity';
import { TranslateService } from '@libs/shared/modules/i18n';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { HISTORY_LOGS_FACADE } from '../../facade/history-logs-facade.injection.token';
import { HistoryLogsServiceProvider } from '../../facade/history-logs-facade.provider.interface';

@UntilDestroy()
@Component({
	selector: 'dk-deposits-summary',
	templateUrl: './deposits-synth.component.html',
	styleUrls: ['./deposits-synth.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositSynthComponent implements OnInit {
	dateRange = new FormGroup({
		from: new FormControl(this._historylogService.range.from.toJSDate()),
		to: new FormControl(this._historylogService.range.to.toJSDate()),
	});

	isLoading$ = this._historylogService.isLoading$;
	isFetchingDeposits$ = this._historylogService.isFetchingSynthReports$;

	sortActive = false;
	reverse = false;
	column = '';
	data: any;
	i18n: any;
	labels: any;

	employees = [];
	selectedEmployees = 'manager';

	filterChanged = new BehaviorSubject<boolean>(false);
	synthReport$ = this._historylogService.synthReport$;
	localisedTexts$ = this._translateService.selectTranslation('deposit-synth');

	viewData$ = combineLatest([this.localisedTexts$, this.synthReport$, this.filterChanged]).pipe(
		filter(([i18n, data]) => !!data),
		untilDestroyed(this),
		map(([i18n, data]) => {
			this.i18n = i18n;
			const labels = data.labels.sort(sortLabels);
			this.labels = labels;
			if (this.selectedEmployees === 'manager') {
				this.data = data.by_manager_data_set;
			} else {
				this.data = data.by_employee_data_set;
			}
			this.data.sort((x, y) => this.sort(y, x));
			return { i18n, data: data };
		})
	);

	constructor(
		private _translateService: TranslateService,
		@Inject(HISTORY_LOGS_FACADE) private readonly _historylogService: HistoryLogsServiceProvider,
		private currencyPipe: CurrencyPipe
	) {}

	ngOnInit(): void {
		this._historylogService.myDefaultRestaurant$.subscribe(() => {
			this.setPeriod();
		});
	}

	onFilterChange(value) {
		this.selectedEmployees = value;
		this.filterChanged.next(true);
	}

	sort(a, b) {
		if (this.sortActive) {
			let x = a;
			let y = b;
			const keyPath = this.column;
			const keys = keyPath.split('.');
			try {
				for (const key of keys) {
					x = x[key];
					y = y[key];
				}
			} catch (err) {
				return 0;
			}
			return (x < y ? -1 : x > y ? 1 : 0) * (this.reverse ? -1 : 1);
		} else {
			return 0;
		}
	}

	setPeriod(period?: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case Range.Today:
				from = today;
				to = today;
				break;
			case Range.Week:
				from = today.startOf(Range.Week);
				to = today.endOf(Range.Week);
				break;
			case Range.Month:
				from = today.startOf(Range.Month);
				to = today.endOf(Range.Month);
				break;
			case Range.Period:
				to = !this.dateRange.controls.to.value ? from : to;
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this._historylogService.getSynthesisReports(from, to);
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}

	download(ext: string) {
		if (ext === 'pdf') {
			const source = document.getElementById('deposit-summary');
			const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true, format: 'letter' });
			doc.html(source, {
				html2canvas: {
					scale: 0.15,
					letterRendering: true,
					ignoreElements: (e) => e.nodeName.includes('MAT'),
					logging: true,
				},
				margin: [30, 5, 5, 5],
				windowWidth: 1000,
				width: 1000,
				fontFaces: [
					{
						family: 'Roboto',
						src: [
							{
								url: '/assets/fonts/roboto.ttf',
								format: 'truetype',
							},
						],
					},
				],
				callback: (doc) => {
					doc.setFontSize(16);
					doc.setFont(undefined, 'bold');
					const from = DateTime.fromJSDate(this.dateRange.controls.from.value).setLocale(getLocale()).toFormat('yyyy LLL dd'),
						to = DateTime.fromJSDate(this.dateRange.controls.to.value).setLocale(getLocale()).toFormat('yyyy LLL dd');
					const date = from === to ? from : `${from} - ${to}`;
					doc.setFontSize(12);
					doc.text(`${date}`, 20, 20);
					doc.save(this.i18n.title);
				},
			});
		} else {
			const csv = [];
			const pipeOption = ['EUR', 'symbol', '1.2-2'];
			const currency = (v) => this.currencyPipe.transform(v, ...pipeOption);
			if (this.data) {
				this.data.forEach((e) => {
					const entry = {
						[this.i18n[this.selectedEmployees]]: e.staff,
						[this.i18n.total]: currency(e.total),
					};
					this.labels.forEach((label) => {
						if (e.by_label[label]) {
							entry[`${this.i18n.payment_methods[label] || label}-${this.i18n.declared_amount}`] =
								currency(e.by_label[label].amount.real) || 0;
							entry[`${this.i18n.payment_methods[label] || label}-${this.i18n.gap}`] = currency(e.by_label[label].amount.gap) || 0;
							entry[`${this.i18n.payment_methods[label] || label} -%`] = ((e.by_label[label].amount.real / e.total) * 100).toFixed(2) + '%';
						} else {
							entry[`${this.i18n.payment_methods[label] || label}-${this.i18n.declared_amount}`] = currency(0);
							entry[`${this.i18n.payment_methods[label] || label}-${this.i18n.gap}`] = currency(0);
							entry[`${this.i18n.payment_methods[label] || label} -%`] = '0%';
						}
					});
					csv.push(entry);
				});
				const total = {
					[this.i18n[this.selectedEmployees]]: this.i18n.total,
					[this.i18n.total]: currency(this.totalRevenue()),
				};
				this.labels.forEach((label) => {
					total[`${this.i18n.payment_methods[label] || label}-${this.i18n.declared_amount}`] =
						currency(this.total(label, 'real', this.data)) || 0;
					total[`${this.i18n.payment_methods[label] || label}-${this.i18n.gap}`] = currency(this.total(label, 'gap', this.data)) || 0;
					total[`${this.i18n.payment_methods[label] || label} -%`] =
						((this.total(label, 'real', this.data) * 100) / this.totalRevenue()).toFixed(2) + '%';
				});
				csv.push(total);
			}
			const options = {
				filename: this.i18n.title,
				decimalSeparator: '.',
				fieldSeparator: ';',
				showLabels: true,
				showTitle: true,
				title: `${this.i18n.title}  ${DateTime.fromJSDate(this.dateRange.value.from).toISODate()}_${DateTime.fromJSDate(this.dateRange.value.to).toISODate()}`,
				useKeysAsHeaders: true,
			};
			const csvExporter = new ExportToCsv(options);
			if (csv.length > 0) {
				csvExporter.generateCsv(csv);
			}
		}
	}

	setSorting(method, property, reverse = true) {
		const colPath = `by_label.${method}.amount.${property}`;
		if (this.column === colPath) {
			this.sortActive = false;
			this.column = '';
		} else {
			this.column = colPath;
			this.sortActive = true;
		}
		this.reverse = reverse;
		this.filterChanged.next(true);
	}

	total(method, property, dataset) {
		let sum = 0;
		dataset.forEach((e) => {
			sum += (e.by_label[method] && e.by_label[method]['amount'][property]) || 0;
		});
		return sum;
	}

	totalRevenue() {
		if (this.data) {
			return this.data.reduce((sum, obj) => sum + obj.total, 0);
		}
		return 0;
	}
}
