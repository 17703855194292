import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, HostBinding, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MenuModel } from '@libs/shared/models';
import { CommonLayoutService, HEADER_ACTIONS } from '@libs/shared/modules/common-layout';
import { tap } from 'rxjs';
import { MENU_TOGGLE_ANIMATION, MENU_TOGGLE_TRIGGER } from '../../animations';
import { getMenuToggleStateFromBoolean } from '../../utils';

@Component({
	selector: 'merim-common-layout',
	templateUrl: './common-layout.component.html',
	styleUrl: './common-layout.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [MENU_TOGGLE_ANIMATION],
})
export class CommonLayoutComponent<T> implements OnInit {
	private readonly _commonLayoutService: CommonLayoutService<T> = inject(CommonLayoutService<T>);
	private readonly _destroyRef: DestroyRef = inject(DestroyRef);

	@HostBinding(`@${MENU_TOGGLE_TRIGGER}`) get expandMenuTrigger() {
		return getMenuToggleStateFromBoolean(this._commonLayoutService.menuExpanded());
	}

	@Input({ required: true })
	set version(version: string) {
		this._commonLayoutService.setVersion(version);
	}

	@Input({ required: true })
	set title(title: string) {
		this._commonLayoutService.setTitle(title);
	}

	@Input({ required: true })
	set menuItems(menuItems: MenuModel<T>[]) {
		this._commonLayoutService.setMenuItems(menuItems);
	}

	@Input({ required: true })
	set userName(name: string) {
		this._commonLayoutService.setUserName(name);
	}

	@Input({ required: true })
	set userEmail(email: string) {
		this._commonLayoutService.setUserEmail(email);
	}

	@Output() logout = new EventEmitter();
	@Output() settings = new EventEmitter();

	ngOnInit() {
		this.handleActionsClicked();
		this._commonLayoutService.availableActions = [HEADER_ACTIONS.LOGOUT, this.settings.observed && HEADER_ACTIONS.SETTINGS];
	}

	private handleActionsClicked(): void {
		this._commonLayoutService.actionsSubject
			.pipe(
				takeUntilDestroyed(this._destroyRef),
				tap((action: HEADER_ACTIONS) => {
					switch (action) {
						case HEADER_ACTIONS.LOGOUT:
							this.logout.emit();
							break;
						case HEADER_ACTIONS.SETTINGS:
							this.settings.emit();
							break;
					}
				})
			)
			.subscribe();
	}
}
