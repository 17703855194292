@if (vatSynthesisReport$ | async; as data) {
	@if (data.by_channel && isNotEmptyDataset(data)) {
		<div class="flex-box">
			<div>
				<table mat-table [dataSource]="data.by_channel | keyvalue: sortByChannel()" class="mat-elevation-z8 w-100" id="bychannel-table-dki">
					<ng-container matColumnDef="channels">
						<th mat-header-cell *matHeaderCellDef>{{ i18n.channel }}</th>
						<td mat-cell *matCellDef="let element">{{ i18n.channels[element.key] }}</td>
						<td mat-footer-cell *matFooterCellDef>{{ i18n.total }}</td>
					</ng-container>
					<ng-container matColumnDef="net">
						<th mat-header-cell *matHeaderCellDef>{{ i18n.net }}</th>
						<td mat-cell *matCellDef="let element">{{ element.value.total.net_value | currency: 'EUR' }}</td>
						<td mat-footer-cell *matFooterCellDef>{{ data.total['net_value'] | currency: 'EUR' }}</td>
					</ng-container>
					<ng-container matColumnDef="gross">
						<th mat-header-cell *matHeaderCellDef>{{ i18n.gross }}</th>
						<td mat-cell *matCellDef="let element">{{ element.value.total.gross_value | currency: 'EUR' }}</td>
						<td mat-footer-cell *matFooterCellDef>{{ data.total['gross_value'] | currency: 'EUR' }}</td>
					</ng-container>
					<ng-container matColumnDef="ratio">
						<th mat-header-cell *matHeaderCellDef>{{ i18n.ratio }}</th>
						<td mat-cell *matCellDef="let element">{{ element.value.total.share | percent: '1.2-2' }}</td>
						<td mat-footer-cell *matFooterCellDef></td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="displayedColumnsEntries"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumnsEntries"></tr>
					<tr mat-footer-row *matFooterRowDef="displayedColumnsEntries" class="bg-light-blue font-weight-bold highlight"></tr>
				</table>
				<div class="dk-flex">
					<button mat-stroked-button [matMenuTriggerFor]="synthReportbychannel" class="export-label">{{ i18n.export }}</button>
					<mat-menu #synthReportbychannel="matMenu" yPosition="above" xPosition="before">
						<button mat-menu-item (click)="exportbyChannelReport('CSV')">{{ i18n.exportCsv }}</button>
						<button mat-menu-item (click)="exportbyChannelReport('PDF')">{{ i18n.exportPDF }}</button>
					</mat-menu>
				</div>
			</div>
			<div>
				<table mat-table [dataSource]="datasetByVat(data)" class="mat-elevation-z8 w-100 mt-5" id="vat-table-dki">
					<ng-container matColumnDef="vat_type">
						<th mat-header-cell *matHeaderCellDef>{{ i18n.vat_type }}</th>
						<td mat-cell *matCellDef="let element">
							{{
								element.isTotal ? i18n.total + ' ' + i18n.channels[element.label] : i18n.channels[element.label] + ' ' + element.vat + '%'
							}}
						</td>
						<td mat-footer-cell *matFooterCellDef>{{ i18n.total }}</td>
					</ng-container>
					<ng-container matColumnDef="net">
						<th mat-header-cell *matHeaderCellDef>{{ i18n.net }}</th>
						<td mat-cell *matCellDef="let element">{{ element.net | currency: 'EUR' }}</td>
						<td mat-footer-cell *matFooterCellDef>{{ data.total['net_value'] | currency: 'EUR' }}</td>
					</ng-container>
					<ng-container matColumnDef="gross">
						<th mat-header-cell *matHeaderCellDef>{{ i18n.gross }}</th>
						<td mat-cell *matCellDef="let element">{{ element.gross | currency: 'EUR' }}</td>
						<td mat-footer-cell *matFooterCellDef>{{ data.total['gross_value'] | currency: 'EUR' }}</td>
					</ng-container>
					<ng-container matColumnDef="vat">
						<th mat-header-cell *matHeaderCellDef>{{ i18n.vat }}</th>
						<td mat-cell *matCellDef="let element">{{ element.vat_value | currency: 'EUR' }}</td>
						<td mat-footer-cell *matFooterCellDef>{{ data.total['vat_value'] | currency: 'EUR' }}</td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="displayedVatColumnsEntries"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedVatColumnsEntries" [ngClass]="{ highlight: row.isTotal }"></tr>
					<tr mat-footer-row *matFooterRowDef="displayedVatColumnsEntries" class="bg-light-blue font-weight-bold"></tr>
				</table>
				<div class="dk-flex">
					<button mat-stroked-button [matMenuTriggerFor]="synthReportbyvat" class="export-label">{{ i18n.export }}</button>
					<mat-menu #synthReportbyvat="matMenu" yPosition="above" xPosition="before">
						<button mat-menu-item (click)="exportVatTypeReport('CSV')">{{ i18n.exportCsv }}</button>
						<button mat-menu-item (click)="exportVatTypeReport('PDF')">{{ i18n.exportPDF }}</button>
					</mat-menu>
				</div>
			</div>
		</div>
	}
}
