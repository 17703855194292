import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslateModule } from '@libs/shared/modules/i18n';

export type CustomButtonToggleGroupItem = {
	id: string;
	labelKey: string;
};

@Component({
	selector: 'merim-common-button-toggle-group',
	standalone: true,
	imports: [CommonModule, MatButtonToggleModule, TranslateModule],
	templateUrl: './common-button-toggle-group.component.html',
	styleUrl: './common-button-toggle-group.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonButtonToggleGroupComponent<T> {

	@Input({ required: true }) menuItems: CustomButtonToggleGroupItem[];
	@Input() selected: T;

	@Output() selectedChange = new EventEmitter<string>();

	select(event: MatButtonToggleChange): void {
		if (event) {
			this.selectedChange.emit(event.value);
		}
	}
}
