<merim-common-layout-table [config]="tableConfig"
                           [columnGroups]="tableGroups"
                           [dataSource]="data">

</merim-common-layout-table>

<ng-template #coloredTime let-context="data" let-info="info">
	<p class="m-0 h-[23px] leading-[23px] py-0 px-2 rounded-[4px] bg-blue-100 text-blue-900"
	   [class]="getClassForValue(context, info.valueType)">
    {{ context | toMins }}
	</p>
</ng-template>