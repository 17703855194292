<merim-common-screen-title [title]="'sosKioskTile.speedOfService.pageTitle'">
	<date-picker-new
		(dateSelection)="onDateSelection($event)"
		[selectedPeriodSig]="rangePeriod()"
		(selectedPeriodChange)="onDatePeriodSelection($event)"
		[initDate]="range()"
	></date-picker-new>
</merim-common-screen-title>

<merim-common-layout-tabs [tabs]="tabs" (contentChanged)="onTabsChanged($event)">
	<layout-filters [multiSelectRestaurant]="true" (exportSelected)="exportData($event)">
		@if (selectedTabId === 'onsite') {
			<common-layout-select
				[icon]="'device_hub'"
				[label]="'sosKioskTile.salesChannels.label'"
				[search]="true"
				[selectedValue]="filters.channel()"
				[allCheckbox]="false"
				[multiSelect]="false"
				(selectionChange)="channelSelected($event)"
				[options]="channels"
			>
			</common-layout-select>
		}

		<common-layout-select
			[icon]="'view_week'"
			[label]="'sosKioskTile.days.label'"
			[search]="true"
			[multiSelect]="true"
			[selectedValue]="filters.day()"
			(selectionChange)="daySelected($event)"
			[options]="days"
		>
		</common-layout-select>

		@if (selectedTabId !== 'onsite') {
			<common-layout-select
				[icon]="'group_work'"
				[label]="'dtt-export.type_of_group'"
				[search]="false"
				[multiSelect]="false"
				[selectedValue]="filters.groupType()"
				(selectionChange)="groupSelected($event)"
				[options]="groupTypes"
			>
			</common-layout-select>

			<common-layout-select
				[icon]="'group_work'"
				[label]="'dtt-export.source_type'"
				[search]="false"
				[multiSelect]="false"
				[selectedValue]="filters.source()"
				(selectionChange)="sourceSelected($event)"
				[options]="sourceTypes"
			>
			</common-layout-select>
		}
	</layout-filters>
</merim-common-layout-tabs>
