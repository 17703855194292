import { Pipe, PipeTransform } from '@angular/core';
import { CommonTableConfig } from "@libs/shared/modules/common-components";

@Pipe({
	name: 'hourlyReportTableConfigFilter',
	standalone: true,
})
export class HourlyReportTableConfigFilterPipe<T> implements PipeTransform {
	transform(value: CommonTableConfig<T>, selectedChannels: string[]): CommonTableConfig<T> {
		if (selectedChannels && selectedChannels.length > 0 && value) {
			const columns = value.columns.filter((column) => !column.key.includes('__') || selectedChannels.includes(column.key.split('__')[0]));
			return {
				...value,
				columns,
			};
		}
		return value;
	}
}
