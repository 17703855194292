import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, Injector, OnInit } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import * as api from '@dki/api-client';
import { Range } from '@libs/dash/core/entity';
import { ReportsModule, WeeklyReportTileComponent } from '@libs/dash/features/v1';
import { ExportOption, ExportOptionType, LayoutFilterService } from '@libs/dash/features/v2';
import { ColumnType, CommonLayoutTableComponent, CommonTableConfig } from '@libs/shared/modules/common-components';
import { TranslateService } from '@libs/shared/modules/i18n';
import { map } from 'rxjs/operators';

const WEEKLY_REPORTS_COLUMN = {
	Sales: 'sales',
	Total: 'total',
	Monday: 'monday',
	Tuesday: 'tuesday',
	Wednesday: 'wednesday',
	Thursday: 'thursday',
	Friday: 'friday',
	Saturday: 'saturday',
	Sunday: 'sunday',
};

interface PerVatInternalData {
	sales: string;
	total: number;
	monday: number;
	tuesday: number;
	wednesday: number;
	thursday: number;
	friday: number;
	saturday: number;
	sunday: number;
}

@Component({
	selector: 'weekly-reports-tab',
	standalone: true,
	imports: [CommonModule, ReportsModule, CommonLayoutTableComponent],
	templateUrl: './weekly-reports-tab.component.html',
	styleUrl: './weekly-reports-tab.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeeklyReportsTabComponent extends WeeklyReportTileComponent implements OnInit {
	private readonly _injector: Injector = inject(Injector);
	private readonly _translate: TranslateService = inject(TranslateService);
	private readonly _dr: DestroyRef = inject(DestroyRef);

	private readonly _layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	dataInternalSig = toSignal<{ weeklyReport: api.WeeklyReport; i18n: any }>(this.viewData$.pipe(map((data: any) => data)), {
		injector: this._injector,
	});
	dataSig = toSignal<api.WeeklyReport>(this.viewData$.pipe(map((data: any) => data.weeklyReport)), { injector: this._injector });

	tableDataSig = computed<PerVatInternalData[]>(() => {
		if (this.dataSig()) {
			return [
				{
					sales: 'weeklyReportTile.salesNet',
					...this.dataSig().net_value.by_weekday,
					total: this.dataSig().net_value.total,
				},
				...Object.keys(this.dataSig().vat)
					.sort((a, b) => {
						const keyA = parseFloat(a);
						const keyB = parseFloat(b);
						return keyA < keyB ? -1 : keyA === keyB ? 0 : 1;
					})
					.map((key) => {
						return {
							sales: `${this._translate.instant('weeklyReportTile.value_by_vat.vat')} ${key}%`,
							...this.dataSig().vat[key].by_weekday,
							total: this.dataSig().vat[key].total,
						};
					}),
			];
		}
		return [];
	});

	tableDataPaymentSig = computed<PerVatInternalData[]>(() => {
		if (this.dataSig()) {
			return [
				...Object.keys(this.dataSig().value_by_payment_method).map((key) => {
					return {
						sales: key,
						...this.dataSig().value_by_payment_method[key].by_weekday,
						total: this.dataSig().value_by_payment_method[key].total,
					};
				}),
			];
		}
		return [];
	});

	tableConfigSig = computed<CommonTableConfig<PerVatInternalData>>(() => {
		if (this.dataSig()) {
			return {
				titleKey: 'weeklyReportTile.perVat',
				columns: [
					{
						key: WEEKLY_REPORTS_COLUMN.Sales,
						columnType: ColumnType.Translation,
						alignment: 'left',
						headerLabelKey: `weeklyReportTile.sales`,
						totalGetter: () => `weeklyReportTile.total`,
					},
					...Object.keys(this.dataSig().free_item_value.by_weekday).map((key) => {
						return {
							key,
							columnType: ColumnType.Price,
							alignment: 'right',
							headerLabelKey: `weeklyReportTile.weekdays.${key}`,
							totalGetter: (data: any[]) => data.reduce((acc: number, i: PerVatInternalData) => acc + i[key], 0),
						};
					}),
					{
						key: WEEKLY_REPORTS_COLUMN.Total,
						columnType: ColumnType.Price,
						headerLabelKey: `weeklyReportTile.total`,
						classGetter: () => '!bg-neutral-80',
						alignment: 'right',
						totalGetter: (data: any[]) => data.reduce((acc: number, i: PerVatInternalData) => acc + i.total, 0),
					},
				],
			};
		}
		return null;
	});

	tablePaymentsConfigSig = computed<CommonTableConfig<any>>(() => {
		if (this.dataSig()) {
			return {
				titleKey: 'weeklyReportTile.value_by_payment_method_title',
				columns: [
					{
						key: WEEKLY_REPORTS_COLUMN.Sales,
						columnType: ColumnType.Text,
						totalColumnType: ColumnType.Translation,
						alignment: 'left',
						headerLabelKey: `weeklyReportTile.payment_method`,
						valueGetter: (cell: string) => this.dataInternalSig()?.i18n?.value_by_payment_method[cell] || cell,
						totalGetter: () => `weeklyReportTile.total`,
					},
					...Object.keys(this.dataSig().free_item_value.by_weekday).map((key) => {
						return {
							key,
							columnType: ColumnType.Price,
							alignment: 'left',
							headerLabelKey: `weeklyReportTile.weekdays.${key}`,
							totalGetter: (data: any[]) => data.reduce((acc: number, i: PerVatInternalData) => acc + i[key], 0),
						};
					}),
					{
						key: 'total',
						columnType: ColumnType.Price,
						alignment: 'right',
						headerLabelKey: `weeklyReportTile.total`,
						classGetter: () => '!bg-neutral-80',
						totalGetter: (data: any[]) => data.reduce((acc: number, i: PerVatInternalData) => acc + i.total, 0),
					},
				],
			};
		}
		return null;
	});

	override ngOnInit(): void {
		super.ngOnInit();

		effect(
			() => {
				const range = this._layoutFilterServiceService?.range();
				if (range) {
					this.date.setValue(range.from.toJSDate());
					this.setWeek(Range.Date);
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		const availableExportOptions: ExportOption[] = [
			{ label: ExportOptionType.CSV, selected: false, type: ExportOptionType.CSV },
			{ label: ExportOptionType.XLSX, selected: false, type: ExportOptionType.XLSX },
		];
		this._layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);

		this._layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this._dr))
			.subscribe((selectedOptions: string[]) => this._exportData(selectedOptions));
	}

	private _exportData(selectedOptions: string[]) {
		if (selectedOptions.length === 0) {
			return;
		}
		for (const option of selectedOptions) {
			switch (option) {
				case ExportOptionType.CSV:
					this.downloadCsv();
					break;
				case ExportOptionType.XLSX:
					this.downloadXlsx();
					break;
				case ExportOptionType.PDF:
					this.downloadPDF();
					break;
			}
		}
	}
}
