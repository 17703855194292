import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import * as api from '@dki/api-client';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import { filter } from 'rxjs/operators';

import { SYNTHESIS_REPORT_FACADE } from '../../facade/synthesis-report-facade.injection.token';
import { SynthesisReportServiceProvider } from '../../facade/synthesis-report-facade.provider.interface';
@UntilDestroy()
@Component({
	selector: 'dk-vat-synthesis',
	templateUrl: './vat-synthesis-report.component.html',
	styleUrls: ['./vat-synthesis-report.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VatSynthesisReportComponent implements OnInit {
	@Input() i18n: any;

	vatSynthesisReport$ = this.synthesisReportService.vatSynthesisReport$;
	isLoading = this.synthesisReportService.isLoading$;

	displayedColumnsEntries: string[] = ['channels', 'net', 'gross', 'ratio'];
	displayedVatColumnsEntries: string[] = ['vat_type', 'net', 'vat', 'gross'];

	byVatReport: Record<string, any>[];
	byChannelReport: api.Synthesis2Report | Record<string, unknown>;

	selectedRestaurants: Array<string>;

	constructor(@Inject(SYNTHESIS_REPORT_FACADE) private readonly synthesisReportService: SynthesisReportServiceProvider) {}

	ngOnInit(): void {
		this.synthesisReportService.selectedRestaurants$
			.pipe(
				untilDestroyed(this),
				filter((restaurants) => restaurants.length > 0 && !!restaurants[0])
			)
			.subscribe((restaurants) => {
				this.selectedRestaurants = restaurants.map((restaurant) => restaurant.name);
			});
	}

	isNotEmptyDataset(dataset) {
		return dataset.by_channel && Object.keys(dataset.by_channel).length > 0;
	}

	// datasetByChannel(vatSynthesisReport: api.Synthesis2Report) {}

	datasetByVat(vatSynthesisReport: api.Synthesis2Report | Record<string, unknown>) {
		const dataSet = [];
		const channels = Object.keys(vatSynthesisReport.by_channel);
		for (const channel of channels) {
			for (const [key, value] of Object.entries(vatSynthesisReport.by_channel[channel].by_vat_rate)) {
				const entry = {
					label: channel,
					vat: key,
					gross: value['gross_value'],
					net: value['net_value'],
					vat_value: value['vat_value'],
				};
				dataSet.push(entry);
			}
			const entry = {
				label: channel,
				vat: vatSynthesisReport.by_channel[channel].total.vat_value,
				gross: vatSynthesisReport.by_channel[channel].total.gross_value,
				net: vatSynthesisReport.by_channel[channel].total.net_value,
				vat_value: vatSynthesisReport.by_channel[channel].total.vat_value,
				isTotal: true,
			};
			dataSet.push(entry);
		}

		this.byChannelReport = vatSynthesisReport;
		this.byVatReport = dataSet.sort((a, b) => this.i18n.channels[a.label].localeCompare(this.i18n.channels[b.label]));
		return this.byVatReport;
	}

	sortByChannel() {
		return (a: { key: string }, b: { key: string }): number => {
			if (!this.i18n || !this.i18n.channels) {
				return 0;
			}
			return this.i18n.channels[a.key].localeCompare(this.i18n.channels[b.key]);
		};
	}

	exportVatTypeReport(type) {
		if (type === 'CSV') {
			const csv = this.byVatReport.map((el) => {
				const entry = {
					[this.i18n.vat_type]: el.isTotal
						? `${this.i18n.total} ${this.i18n.channels[el.label]}`
						: `${this.i18n.channels[el.label]} ${el.vat}%`,
					[this.i18n.gross]: parseFloat(el.gross).toFixed(2),
					[this.i18n.vat]: parseFloat(el.vat_value).toFixed(2),
					[this.i18n.net]: parseFloat(el.net).toFixed(2),
				};
				return entry;
			});
			csv.push({
				[this.i18n.vat_type]: this.i18n.total,
				[this.i18n.gross]: parseFloat(this.byChannelReport.total['gross_value']).toFixed(2),
				[this.i18n.vat]: parseFloat(this.byChannelReport.total['vat_value']).toFixed(2),
				[this.i18n.net]: parseFloat(this.byChannelReport.total['net_value']).toFixed(2),
			});
			const options = {
				filename: this.i18n.channel_synth_report,
				decimalSeparator: '.',
				fieldSeparator: ';',
				showLabels: true,
				showTitle: true,
				title: this.i18n.channel_synth_report,
				useKeysAsHeaders: true,
			};
			const csvExporter = new ExportToCsv(options);
			csvExporter.generateCsv(csv);
		} else {
			this.pdfExport('vat-table-dki', 0.18);
		}
	}

	exportbyChannelReport(type) {
		if (type === 'CSV') {
			const csv = [];
			Object.entries(this.byChannelReport.by_channel).forEach((channel) => {
				csv.push({
					[this.i18n.channel]: this.i18n.channels[channel[0]],
					[this.i18n.net]: parseFloat(channel[1].total.net_value).toFixed(2),
					[this.i18n.gross]: parseFloat(channel[1].total.gross_value).toFixed(2),
					[this.i18n.ratio]: `${(channel[1].total.share * 100).toFixed(2)}%`,
				});
			});
			csv.push({
				[this.i18n.channel]: this.i18n.total,
				[this.i18n.net]: parseFloat(this.byChannelReport.total['net_value']).toFixed(2),
				[this.i18n.gross]: parseFloat(this.byChannelReport.total['gross_value']).toFixed(2),
				[this.i18n.ratio]: `${(this.byChannelReport.total['share'] * 100).toFixed(2)}%`,
			});
			const options = {
				filename: this.i18n.vat_synth_report,
				decimalSeparator: '.',
				fieldSeparator: ';',
				showLabels: true,
				showTitle: true,
				title: this.i18n.vat_synth_report,
				useKeysAsHeaders: true,
			};
			const csvExporter = new ExportToCsv(options);
			csvExporter.generateCsv(csv);
		} else {
			this.pdfExport('bychannel-table-dki');
		}
	}

	pdfExport(id, scale = 0.28) {
		const dateSelected = `${this.synthesisReportService.range.from.toFormat('yyyy-MM-dd')} - ${this.synthesisReportService.range.to.toFormat('yyyy-MM-dd')}`;
		const title = String(`${this.i18n.title}_${dateSelected}`);
		const source = document.getElementById(id);
		const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true });
		doc.setFontSize(16);
		doc.setFont(undefined, 'bold');
		doc.text(this.selectedRestaurants.join(','), 20, 15);
		doc.setFontSize(12);
		doc.setFont(undefined, 'light');
		doc.text(`${dateSelected}`, 20, 20);
		doc.html(source, {
			html2canvas: {
				scale: scale,
				letterRendering: false,
				ignoreElements: (e) => e.classList.contains('export-buttons'),
			},
			margin: [30, 5, 5, 5],
			windowWidth: 1000,
			width: 900,
			fontFaces: [
				{
					family: 'Roboto',
					src: [
						{
							url: '/assets/fonts/roboto.ttf',
							format: 'truetype',
						},
					],
				},
			],
			callback: (doc) => {
				doc.save(title);
			},
		});
	}
}
