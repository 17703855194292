<merim-common-screen-title [title]="'reports.title'">
	<date-picker-new
		(dateSelection)="onDateSelection($event)"
		(selectedPeriodChange)="onDatePeriodSelection($event)"
		[selectedPeriodSig]="rangePeriod()"
		[initDate]="range()"
	></date-picker-new>
</merim-common-screen-title>

<merim-common-layout-tabs [tabs]="tabs" (contentChanged)="onTabsChanged($event)">
	<layout-filters [multiSelectRestaurant]="true" (exportSelected)="export($event)" (restaurantSelected)="setRestaurants($event)">
		@if (selectedTabId === 'products' || selectedTabId === 'operating' || selectedTabId === 'hourly') {
			<common-layout-select
				[icon]="'device_hub'"
				[label]="'sosKioskTile.salesChannels.label'"
				[search]="true"
				[selectedValue]="filters?.channels()"
				[allCheckbox]="false"
				[multiSelect]="true"
				(selectionChange)="channelSelected($event)"
				[options]="filtersOptions?.channels ? filtersOptions?.channels() : []"
			>
			</common-layout-select>
		}
	</layout-filters>
</merim-common-layout-tabs>
