import { ComponentType } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy, ChangeDetectorRef,
	Component,
	DestroyRef,
	EventEmitter,
	inject,
	Input,
	OnInit,
	Output,
	Type
} from '@angular/core';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatTabsModule } from '@angular/material/tabs';
import { LanguageService, TranslateModule, TranslateService } from '@libs/shared/modules/i18n';
import { takeUntil } from "rxjs/operators";

export interface CommonLayoutTab {
	id: string;
	label: string;
	content: ComponentType<any>;
}

@Component({
	selector: 'merim-common-layout-tabs',
	standalone: true,
	imports: [CommonModule, MatTabsModule, TranslateModule],
	templateUrl: './common-layout-tabs.component.html',
	styleUrl: './common-layout-tabs.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonLayoutTabsComponent implements OnInit, AfterViewInit {
	displayedComponent: ComponentType<any> = null;
	private readonly _translateService: TranslateService = inject(TranslateService);
	private readonly _languageService: LanguageService = inject(LanguageService);
	private readonly _dr: DestroyRef = inject(DestroyRef);
	private _cd: ChangeDetectorRef = inject(ChangeDetectorRef);

	@Input() tabs: CommonLayoutTab[] = [];
	@Output() contentChanged = new EventEmitter<string>();

	ngOnInit() {
		this._languageService.currentLanguage$.pipe(takeUntilDestroyed(this._dr)).subscribe((lang) => {
			this._cd.detectChanges();
		})
	}

	ngAfterViewInit(): void {
		this.displayedComponent = this.tabs[0].content;
		this.contentChanged.emit(this.tabs[0].id);
	}

	setDisplayedComponent(index: number): void {
		this.displayedComponent = this.tabs[index].content;
		this.contentChanged.emit(this.tabs[index].id);
	}

	getLabel(tab: CommonLayoutTab): string {
		return this._translateService.instant(tab.label);
	}
}
