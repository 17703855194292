import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { WINDOW_EVENTS } from '@libs/dash/core/entity';
import { UiVersionDetectorService } from '@libs/shared/services';
import { TranslateService } from '@libs/shared/modules/i18n';
import { combineLatest, interval, Subject, timer } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { PERFORMANCE_FACADE } from '../facade/performance-facade.injection.token';
import type { PerformanceServiceProvider } from '../facade/performance-facade.provider.interface';

@Component({
	selector: 'dk-performance',
	templateUrl: './performance.component.html',
	styleUrls: ['./performance.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceComponent implements OnDestroy, AfterViewInit {
	localisedTexts$ = this._translateService.selectTranslation('performance').pipe(
		withLatestFrom(this._translateService.selectTranslation('channels')),
		map(([i18n, channels]) => {
			i18n['channels'] = channels;
			return i18n;
		})
	);

	private readonly _newUiDetector: UiVersionDetectorService = inject(UiVersionDetectorService);
	isNewRoute = this._newUiDetector.isNewUi();

	pmsMod: string;
	kdsMod: string;
	sourced = false;

	handler;

	delayOnRefresh = 1000 * 60 * 10;

	progressBarInit = false;
	progress = 0;
	interval = 1000; // Update interval in milliseconds
	increment: number = (this.interval / this.delayOnRefresh) * 100;
	fillProgressWidth = '0%';

	isLoading$ = this.performanceService.isFetchingRestaurants$;

	isFetching$ = this.performanceService.loading$;

	destroy$ = new Subject<boolean>();

	viewData$ = combineLatest([
		this.performanceService.currentRevenuePerTimeInterval$,
		this.performanceService.currentTotalRevenue$,
		this.performanceService.predictionPerTimeInterval$,
		this.performanceService.totalPredictedRevenue$,
		// this.performanceService.pasttRevenuePerTimeInterval$,
		this.performanceService.pastTotalRevenue$,
		this.performanceService.channelsSales$,
		this.performanceService.revenueByPrediction$,
		this.performanceService.predictedAccumulated$,
		this.localisedTexts$,
	]).pipe(
		filter(([a, b, c, d, e, f, g, h, i]) => {
			return a?.total != undefined && !!c.datetime && g != undefined && h != undefined;
		}),
		map(
			([
				currentRevenuePerTimeInterval,
				currentTotalRevenue,
				predictionPerTimeInterval,
				totalPredicted,
				// pasttRevenuePerTimeInterval,
				pastTotalRevenue,
				channelsSales,
				revenueByPrediction,
				predictedAccumulated,
				i18n,
			]) => {
				// console.log({
				// 	currentRevenuePerTimeInterval,
				// 	currentTotalRevenue,
				// 	predictionPerTimeInterval,
				// 	totalPredicted,
				// 	pasttRevenuePerTimeInterval,
				// 	pastTotalRevenue,
				// 	channelsSales,
				// 	revenueByPrediction,
				//  predictedAccumulated,
				// 	i18n,
				// });
				return {
					currentRevenuePerTimeInterval,
					currentTotalRevenue,
					predictionPerTimeInterval,
					totalPredicted,
					// pasttRevenuePerTimeInterval,
					pastTotalRevenue,
					channelsSales,
					revenueByPrediction,
					predictedAccumulated,
					i18n,
				};
			}
		)
	);

	constructor(
		private _translateService: TranslateService,
		private router: Router,
		@Inject(PERFORMANCE_FACADE) private readonly performanceService: PerformanceServiceProvider,
		private route: ActivatedRoute,
		private c: ChangeDetectorRef
	) {}

	ngOnDestroy(): void {
		this.removeIframeModListener();
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.sourced = this.router.url.includes('sourced');
		this.route.queryParams.subscribe((queryParams: Params) => {
			this.pmsMod = queryParams['pms-mode'] ? queryParams['pms-mode'] : 'N/A';
			this.kdsMod = queryParams['kds-mode'] ? queryParams['kds-mode'] : 'N/A';
		});
		if (this.sourced) {
			this.performanceService.fetchRestaurants();
			this.switchBlackMode();
			this.addIframeModListener();
		}
		combineLatest([this.performanceService.defaultRestaurant$, timer(0, this.delayOnRefresh)])
			.pipe(
				filter(([id]) => !!id),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.progressBarInit ? void 0 : this.performanceService.fetchForecast();
				this.performanceService.fetchHourlySales(true);
				this.performanceService.fetchHourlySales(false);
				this.progressBarInit ? void 0 : this.initProgressBar();
			});

		// to be tested
		if (this.sourced) {
			interval(this.delayOnRefresh)
				.pipe(takeUntil(this.destroy$))
				.subscribe(() => {
					// this.performanceService.fetchForecast();
					this.performanceService.fetchHourlySales(true);
				});
		}

		timer(getTimeUntilMidnight(), 24 * 60 * 60 * 1000).subscribe(() => {
			this.performanceService.fetchForecast();
		});

		interval(1000 * 60 * 60).subscribe(() => {
			this.performanceService.fetchForecast();
		});

		function getTimeUntilMidnight(): number {
			const now = new Date();
			const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
			return midnight.getTime() - now.getTime();
		}
	}

	initProgressBar() {
		this.progressBarInit = true;
		this.c.detectChanges();
		this.progress += this.increment;
		if (this.progress >= 100) {
			this.progress = this.progress % 100;
		}
		this.fillProgressWidth = `${this.progress}%`;

		if (this.progress < 100) {
			setTimeout(() => this.initProgressBar(), this.interval);
		}
	}

	addIframeModListener(): void {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const that = this;
		window.addEventListener('message', function handler(event) {
			that.iframeModHandler(event, that);
			that.handler = handler.bind(that);
		});
	}

	removeIframeModListener(): void {
		window.removeEventListener('message', this.handler);
	}

	iframeModHandler(event, that = this) {
		if (event?.data?.msgType === WINDOW_EVENTS.CHANGE_MOD) {
			that.kdsMod = event.data.params.kdsMode ? event.data.params.kdsMode : that.kdsMod;
			that.pmsMod = event.data.params.pmsMode ? event.data.params.pmsMode : that.pmsMod;
			that.c.detectChanges();
		}
	}

	switchBlackMode() {
		const style = document.createElement('style');
		style.innerHTML = `
		*:not(.cdk-overlay-connected-position-bounding-box):not(.cdk-overlay-container) {
			background-color: #363636 !important;
		}`;
		document.head.appendChild(style);
	}
}
