import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'productReportsDataFilter',
	standalone: true,
})
export class ProductReportsDataFilterPipe implements PipeTransform {
	transform(data: any[], selectedChannels: string[]): any[] {
		if (selectedChannels && selectedChannels.length > 0) {
			return data.map((i) => {
				const channels = Object.keys(i.channels)
					.filter((channelKey) => selectedChannels.includes(channelKey))
					.reduce(
						(a, channelKey) => ({
							...a,
							[channelKey]: i.channels[channelKey],
						}),
						{}
					);
				return {
					...i,
					channels: channels,
					total: {
						solo: {
							value: Object.values(channels).reduce((total, channel: any) => total + channel.solo.value, 0),
							count: Object.values(channels).reduce((total, channel: any) => total + channel.solo.count, 0),
						},
						inMenu: {
							value: Object.values(channels).reduce((total, channel: any) => total + channel.inMenu.value, 0),
							count: Object.values(channels).reduce((total, channel: any) => total + channel.inMenu.count, 0),
						},
					},
				};
			});
		}

		return data;
	}
}
