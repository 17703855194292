<merim-common-layout
	class="new-ui"
	[version]="currentAppVersionSig()"
	[menuItems]="menuItemsSig()"
	[userName]="userSig()?.name?.full"
	[userEmail]="userSig()?.contactDetails?.email?.administrative"
	[title]="i18nSig()?.texts['reports']"
	(logout)="onLogout()"
>
</merim-common-layout>
