import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@libs/shared/modules/i18n';

@Component({
	selector: 'merim-sos-indicator-card',
	standalone: true,
	imports: [CommonModule, TranslateModule],
	templateUrl: './sos-indicator-card.component.html',
	styleUrls: ['./sos-indicator-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SosIndicatorCardComponent {
	objectiveMs: number;
	absPercent: number;
	relativePercent: number;

	@Input() labelKey: string;
	@Input() set percent(percent: number) {
		this.relativePercent = percent;
		this.absPercent = Math.abs(percent);
	}
	@Input() set objective(objective: number) {
		this.objectiveMs = objective * 1000;
	}

	get icon(): string {
		if (this.relativePercent === 0) {
			return 'visibility_off';
		}
		return this.relativePercent < 0 ? 'arrow_downward' : 'arrow_upward';
	}

	get statusClass(): string {
		if (this.relativePercent === 0) {
			return 'neutral';
		}
		return this.relativePercent < 0 ? 'positive' : 'negative';
	}
}
