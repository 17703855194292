import { Pipe, PipeTransform } from '@angular/core';

export const BUSINESS_DAY_START_HOUR = 5;

@Pipe({ name: 'sortHourlySales' })
export class SortHourlySales implements PipeTransform {
	transform(value: [hour: number, sales: Record<string, unknown>][]) {
		const arr = [];
		const late = [];
		value.forEach((x) => {
			x[0] <= BUSINESS_DAY_START_HOUR ? late.push(x) : arr.push(x);
		});
		return [...arr, ...late];
	}
}
