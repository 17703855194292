import { Pipe, PipeTransform } from '@angular/core';
import { CommonTableColumnGroup } from "@libs/shared/modules/common-components";

@Pipe({
	name: 'hourlyReportTableGroupsFilter',
	standalone: true,
})
export class HourlyReportTableGroupsFilterPipe implements PipeTransform {
	transform(value: CommonTableColumnGroup[], selectedChannels: string[]): CommonTableColumnGroup[] {
		if (selectedChannels && selectedChannels.length > 0 && value && value.length > 0) {
			return value.filter((group) => !group.columns.some(c => c.includes('__')) || selectedChannels.includes(group.columns[0].split('__')[0]));
		}
		return value;
	}
}
