import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, Injector, Input, OnInit, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import * as api from '@dki/api-client';
import { Range } from '@libs/dash/core/entity';
import { DttExportComponent, DttExportModule, SourceType } from '@libs/dash/features/v1';
import { DttExportGroupType } from '@libs/dash/features/v2';
import { equals } from 'ramda';
import { tap } from 'rxjs';
import { LayoutFilterService, LayoutRange } from '../../../layout-filter-service/layout-filter.service';
import { BkcRestoTableComponent } from "../bkc-resto-table/bkc-resto-table.component";
import { BkfTableCumulComponent } from '../bkf-table-cumul/bkf-table-cumul.component';
import { BkfTableRestoComponent } from '../bkf-table-resto/bkf-table-resto.component';

@Component({
	selector: 'bkf-resto-wrapper',
	standalone: true,
	imports: [CommonModule, DttExportModule, BkfTableCumulComponent, BkfTableRestoComponent, BkcRestoTableComponent],
	templateUrl: './bkf-resto-wrapper.component.html',
	styleUrl: './bkf-resto-wrapper.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BkfRestoWrapperComponent extends DttExportComponent implements OnInit {
	protected readonly _injector: Injector = inject(Injector);
	private readonly _dr: DestroyRef = inject(DestroyRef);
	private readonly _layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	contentViewData: Signal<{ dttReport; i18n }> = toSignal(this.viewData$.pipe(tap((data) => console.log(data))), {
		injector: this._injector,
	});

	dataTableMappedSig: Signal<any[]> = computed(() => {
		if (this.contentViewData() && !Array.isArray(this.contentViewData().dttReport)) {
			return Object.keys(this.contentViewData().dttReport).map((key) => {
				return {
					...this.selectedRestaurants?.find((r) => r.id === key),
					details: this.contentViewData().dttReport[key],
				};
			});
		}

		return [];
	});

	@Input({ required: true }) override isBKC = false;
	@Input({ required: true }) override groupType: DttExportGroupType = DttExportGroupType.CumulAll;
	@Input({ required: true }) override sourceType: SourceType = SourceType.boths;

	override ngOnInit(): void {
		super.ngOnInit();
		this.setRestaurants();
	}

	private setRestaurants(): void {
		this.setDay(this._layoutFilterServiceService?.filters?.day() as api.WeekDay[]);
		this.setPeriodEffect(this._layoutFilterServiceService.range());

		effect(
			() => {
				this.setPeriodEffect(this._layoutFilterServiceService.range());
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		effect(
			() => {
				this.sourceType = this._layoutFilterServiceService.filters?.source();
				this.fetchDTT();
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		effect(
			() => {
				this.groupType = this._layoutFilterServiceService.filters?.groupType();
				this.fetchDTT();
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		effect(
			() => {
				this.setDay(this._layoutFilterServiceService?.filters?.day() as api.WeekDay[]);
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		this._layoutFilterServiceService.export.pipe(takeUntilDestroyed(this._dr)).subscribe(() => this.exportAllPDF(this.viewData$));
	}

	private setDay(day: api.WeekDay[]): void {
		if (day && !equals(day, Array.from(this.weekDays))) {
			this.weekDays = new Set<api.WeekDay>(day);
			this.fetchDTT();
		}
	}

	private setPeriodEffect(range: LayoutRange): void {
		if (
			(range && this.dateRange.controls.from.value.toDateString() !== range.from.toJSDate().toDateString()) ||
			this.dateRange.controls.to.value.toDateString() !== range.to.toJSDate().toDateString()
		) {
			this.dateRange.setValue({
				from: range.from.toJSDate(),
				to: range.to.toJSDate(),
			});
			this.setPeriod(Range.Period);
		}
	}
}
