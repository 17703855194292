import { Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { DatePeriod } from '@libs/dash/core/entity';
import { CommonSelectOption } from '@libs/shared/modules/common-components';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';

export type LayoutRange = { from: DateTime; to: DateTime };

export type ExportOption = { label: ExportOptionType; selected: boolean; type: ExportOptionType };

export enum ExportOptionType {
	PDF = 'PDF',
	CSV = 'CSV',
	XLSX = 'XLSX',
}

export type DashLayoutFilters = {
	[key: string]: WritableSignal<any>;
};

export type DashLayoutFiltersOptions = {
	[key: string]: WritableSignal<CommonSelectOption[] | any>;
};

@Injectable({ providedIn: 'root' })
export class LayoutFilterService {
	private _range: WritableSignal<LayoutRange> = signal<LayoutRange>(null);
	private _rangePeriod: WritableSignal<DatePeriod> = signal<DatePeriod>(DatePeriod.Week);
	private _export: Subject<string[]> = new Subject<string[]>();
	private _filters: DashLayoutFilters = {};
	private _filtersOptions: DashLayoutFiltersOptions = {};
	private _exportOptions: WritableSignal<ExportOption[]> = signal<ExportOption[]>([]);
	private _availableExportOptions: WritableSignal<ExportOption[]> = signal<ExportOption[]>([]);

	get range(): Signal<LayoutRange> {
		return this._range.asReadonly();
	}

	get rangePeriod(): Signal<DatePeriod> {
		return this._rangePeriod.asReadonly();
	}

	get export(): Subject<string[]> {
		return this._export;
	}

	get filters(): DashLayoutFilters {
		return this._filters;
	}

	get filtersOptions(): DashLayoutFiltersOptions {
		return this._filtersOptions;
	}

	get exportOptions(): Signal<ExportOption[]> {
		return this._exportOptions.asReadonly();
	}

	get availableExportOptions(): Signal<ExportOption[]> {
		return this._availableExportOptions.asReadonly();
	}

	setRange(value: LayoutRange): void {
		this._range.update(() => value);
	}

	setRangePeriod(value: DatePeriod): void {
		this._rangePeriod.update(() => value);
	}

	emitExport(selectedOptions: string[]): void {
		this._export.next(selectedOptions);
	}

	registerFilter<T>(key: string, signal: WritableSignal<T>): void {
		if (!this.hasFilter(key)) {
			this._filters[key] = signal;
		}
	}

	updateFilter<T>(key: string, value: T): void {
		if (this.hasFilter(key)) {
			this._filters[key].update(() => value);
		}
	}

	hasFilter(key: string): boolean {
		return this._filters.hasOwnProperty(key);
	}

	hasFilterOptions(key: string): boolean {
		return this._filtersOptions.hasOwnProperty(key);
	}

	registerFilterOptions(key: string, signal: WritableSignal<CommonSelectOption[] | any>): void {
		this._filtersOptions[key] = signal;
	}

	setExportOptions(options: ExportOption[]): void {
		this._exportOptions.update(() => options);
	}

	setAvailableExportOptions(options: ExportOption[]): void {
		this._availableExportOptions.update(() => options);
	}
}
