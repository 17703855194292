import {
	BKInfoOrderTabletEventKey,
	BKOrderEventType,
	BKOrderEventUtilities,
	BKOrderSourceEnum,
	BKPublishedOrderDataImpl,
	BKTableAllocationType,
	IBKCustomerNameAssignment,
	IBKOrderEventData,
	IBKPublishedOrderData,
	IBKTableAllocationAssignment
} from '@bk/jscommondatas';
import { Shar3dUtils } from '@shar3d/shar3d-utils';

/**
 * This class contains static methods used in order to convert "behaviors" to their corresponding events
 */
export class OrderEventsUtils {
	/**
	 * Create an event for tablet eent
	 */
	public static createEvent(eventType: BKOrderEventType, arg?: { [key: string]: string | number }, timestamp: number = null): IBKOrderEventData {
		return {
			timestamp: timestamp || Shar3dUtils.now(),
			eventType: eventType,
			arg: Shar3dUtils.object2URLEncoded(arg || {})
		};
	}

	/**
	 * Create an event for tablet eent with raw arg
	 */
	public static createRawEvent(eventType: BKOrderEventType, arg: string): IBKOrderEventData {
		return {
			timestamp: Shar3dUtils.now(),
			eventType: eventType,
			arg: arg
		};
	}

	/**
	 * Create an event for tablet event
	 */
	public static createInfoOrderTabletEvent(inRoom: boolean): IBKOrderEventData | null {
		return OrderEventsUtils.createEvent(BKOrderEventType.INFO_ORDER_TABLET, {
			[BKInfoOrderTabletEventKey.IN_ROOM]: inRoom ? 1 : 0
		});
	}

	/**
	 * Create an event for kioks order source info
	 */
	public static createInfoOrderKioskEvent(payInPos: boolean, timestamp: number = null): IBKOrderEventData | null {
		return OrderEventsUtils.createEvent(BKOrderEventType.INFO_ORDER_KIOSK, {
			[BKOrderEventUtilities.KIOSK_PAY_IN_POS_KEY]: payInPos ? 1 : 0
		}, timestamp);
	}


	/**
	 * Create an event for table allocation
	 */
	public static createTableAllocationEvent(allocationData: IBKTableAllocationAssignment): IBKOrderEventData {
		return OrderEventsUtils.createEvent(BKOrderEventType.TABLE_ALLOCATION, {
			...(allocationData.allocationType === BKTableAllocationType.TABLE_NUMBER ? {[BKOrderEventUtilities.TABLE_NUMBER_KEY]: allocationData.code} : {}),
			...(allocationData.allocationType === BKTableAllocationType.EASEL_NUMBER ? {[BKOrderEventUtilities.EASEL_NUMBER_KEY]: allocationData.code} : {}),
			[BKOrderEventUtilities.LOCATION_SPACE_KEY]: allocationData.locationSpace
		});
	}

	/** Create an event for customer name (first name selection screen) */
	public static createCustomerNameAssignmentEvent(customerNameAssignment: IBKCustomerNameAssignment): IBKOrderEventData {
		return OrderEventsUtils.createEvent(BKOrderEventType.TABLE_ALLOCATION, {
			[BKOrderEventUtilities.CUSTOMER_NAME_KEY]: customerNameAssignment.customerName
		});
	}

	/**
	 * Detect if an order was create by a kiosk to be paid in pos
	 */
	public static isKioskOrderCreatedToBePaidInPos(order: IBKPublishedOrderData): boolean {
		if (!order) {
			return false;
		}
		if (order.source !== BKOrderSourceEnum.ORDER_SOURCE_KIOSK) {
			return false;
		}
		return BKOrderEventUtilities.isKioskOrderCreatedToBePaidInPos(order.event || []);
	}

	/**
	 * Create an event for CRM user phone number
	 */
	public static createUserPhoneNumberEvent(userPhoneNumber: string): IBKOrderEventData {
		return OrderEventsUtils.createRawEvent(BKOrderEventType.CRM_PHONE_NUMBER, userPhoneNumber);
	}

	/** Get customer name (first name selection screen) */
	public static getCustomerNameAssignmentDataFromOrder(order: BKPublishedOrderDataImpl): IBKCustomerNameAssignment | null {
		if (!order) {
			return null;
		}
		return BKOrderEventUtilities.getCustomerNameAssignment(order.event || []);
	}

	/**
	 * Get the user phone number from the events of an order
	 */
	public static getUserPhoneNumber(order: BKPublishedOrderDataImpl): string | null {
		if (!order) return null;
		const phoneNumberEvent: IBKOrderEventData | undefined = (order.event || []).filter((oe) => {
			return oe.eventType === BKOrderEventType.CRM_PHONE_NUMBER && !!oe.arg;
		})[0];
		return phoneNumberEvent?.arg || null;
	}

	/**
	 * Detect if an order was create by a tablet in room
	 */
	public static isOrderCreatedFromTabletInRoom(order: BKPublishedOrderDataImpl): boolean {
		return BKOrderEventUtilities.isOrderCreatedFromTabletInRoom(order.event || []);
	}

	/**
	 * Get the table allocation data from the events of an order
	 */
	public static getTableAllocationData(order: BKPublishedOrderDataImpl): IBKTableAllocationAssignment | null {
		if (!order) {
			return null;
		}
		return BKOrderEventUtilities.getTableAllocationAssignment(order.event || []);
	}
}
