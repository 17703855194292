<div class="flex gap-[32px] items-center">
	<merim-common-button-toggle-group [menuItems]="labels"
	                                  [selected]="selectedPeriodSig()"
	                                  (selectedChange)="selectPeriod($event); selectPeriodInternal($event)">

	</merim-common-button-toggle-group>

	<merim-common-range-picker [range]="range" (applyClick)="applyCustomDates()">

	</merim-common-range-picker>
</div>