import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@libs/shared/modules/i18n';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'dk-safe-deposit',
	templateUrl: './safe-deposit.component.html',
	styleUrls: ['./safe-deposit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SafeDepositComponent {
	public localisedTexts$ = this._translateService.selectTranslation('safe-deposit');

	constructor(private readonly _translateService: TranslateService) {}
}
