<div class="selector" (click)="toggleSelector()">
	<mat-icon [svgIcon]="'home_outline'" id="home"></mat-icon>
	@if (!submittedRestaurant) {
		<span>Restaurants</span>
	} @else {
		<span>{{ submittedRestaurant.name }}</span>
	}
	<mat-icon id="arrow">keyboard_arrow_down</mat-icon>
</div>
@if (isOpen) {
	<div class="wrapper" [@inOutAnimation]>
		<h2>Restaurants</h2>
		<div class="search">
			<input #search placeholder="Recherche" (keyup)="handleSearch(search.value)"/>
			<mat-icon matSuffix>search</mat-icon>
		</div>
		<div class="selection">
			<div class="selected">
				@for (restaurant of selectedRestaurants; track restaurant.id) {
					<div class="selected-restaurant" (click)="handleSelection(restaurant)">
						<span>{{ restaurant.name }}</span>
						<mat-icon class="close">close</mat-icon>
					</div>
				}
			</div>
			<mat-divider></mat-divider>
			@for (restaurant of filteredRestaurants; track restaurant.id) {
				<mat-checkbox (change)="handleSelection(restaurant)" [checked]="isChecked(restaurant)">{{ restaurant.name }}</mat-checkbox>
			}
		</div>
		<button (click)="apply()">Appliquer</button>
	</div>
}
