import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, TemplateRef, ViewChild } from '@angular/core';
import * as api from '@dki/api-client';
import { BkcReportComponent, SecondsToMins } from '@libs/dash/features/v1';
import { BKF_TABLE_COLUMNS } from '@libs/dash/features/v2';
import { ColumnType, CommonLayoutTableComponent, CommonTableConfig } from '@libs/shared/modules/common-components';
import { TranslateService } from '@libs/shared/modules/i18n';

@Component({
	selector: 'bkc-resto-table',
	standalone: true,
	imports: [CommonModule, CommonLayoutTableComponent, SecondsToMins],
	templateUrl: './bkc-resto-table.component.html',
	styleUrl: './bkc-resto-table.component.scss',
	providers: [SecondsToMins],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BkcRestoTableComponent extends BkcReportComponent {
	private readonly _translateService: TranslateService = inject(TranslateService);
	private readonly _secondsToMins: SecondsToMins = inject(SecondsToMins);

	tableData = input.required<any[]>();
	tableConfig: CommonTableConfig<api.SosDttReport>;

	@ViewChild('coloredTime', { read: TemplateRef, static: true }) coloredTime: TemplateRef<any>;

	ngOnInit(): void {
		this.initConfig();
	}

	getClassForValue(value: number, type: string): string {
		if (!value || isNaN(value)) {
			return '';
		}
		return !this.objectiveAchieved(type, value) ? 'red' : 'green';
	}

	private initConfig(): void {
		this.tableConfig = {
			titleKey: 'dtt-export.group_type.cumul_res',
			detailsKey: 'details',
			columns: [
				{
					key: BKF_TABLE_COLUMNS.DayPart,
					headerLabelKey: 'dtt-export.dtt_report.header.day_part',
					alignment: 'left',
					columnType: ColumnType.Text,
					detailColumnType: ColumnType.Translation,
					valueGetter: (_, row: any) => row.name,
					detailGetter: (cell: string) => `dtt-export.dtt_report.day_part.${cell}`,
				},
				{
					key: BKF_TABLE_COLUMNS.Schedule,
					headerLabelKey: 'sosKioskTile.dataTable.schedule',
					columnType: ColumnType.Text,
					alignment: 'left',
					totalColumnType: ColumnType.Translation,
					valueGetter: (_, row: any) => '',
					detailGetter: (_, __, index: number) => {
						const formatTime = (time: number) => time.toString().padStart(2, '0');
						const template = this._translateService.instant('sosKioskTile.timeRange');
						return template.replace('{start}', formatTime(this.dayParts[index][0])).replace('{end}', formatTime(this.dayParts[index][1]));
					},
					totalGetter: () => 'sosKioskTile.dataTable.totalAverage',
				},
				{
					key: BKF_TABLE_COLUMNS.CA,
					headerLabelKey: 'dtt-export.dtt_report.header.ca',
					columnType: ColumnType.Price,
					valueGetter: (_, row: any) => this.total(row.details, 'total_revenue'),
					totalGetter: (data: any[]) => this.total(data?.reduce((a, i) => [...a, ...i.details], []) ?? [], 'total_revenue'),
				},
				{
					key: BKF_TABLE_COLUMNS.AverageTicket,
					headerLabelKey: 'dtt-export.dtt_report.header.avrg_ticket',
					columnType: ColumnType.Price,
					detailGetter: (cell, row: api.SosDttReport) => row?.total_revenue && row?.total_revenue / row?.count,
					valueGetter: (cell: unknown, row: any) => row.details.reduce((a, i) => a + (i?.total_revenue && i?.total_revenue / i?.count), 0),
					totalGetter: (data: any[]) =>
						this.total(data?.reduce((a, i) => [...a, ...i.details], []) ?? [], 'total_revenue') /
						this.total(data?.reduce((a, i) => [...a, ...i.details], []) ?? [], 'count'),
				},
				{
					key: BKF_TABLE_COLUMNS.NumberOfTickets,
					headerLabelKey: 'dtt-export.dtt_report.header.orders',
					columnType: ColumnType.Text,
					valueGetter: (cell: unknown, row: any) => this.total(row.details ?? [], 'count') || '-',
					detailGetter: (cell: unknown, row: api.SosDttReport) => row?.count || '-',
					totalGetter: (data: any[]) => this.total(data?.reduce((a, i) => [...a, ...i.details], []) ?? [], 'count') || '-',
				},
				{
					key: BKF_TABLE_COLUMNS.Cars,
					headerLabelKey: 'dtt-export.dtt_report.header.cars',
					columnType: ColumnType.Text,
					valueGetter: (cell: unknown, row: any) =>
						this.total(row.details ?? [], 'count') - this.total(row.details ?? [], 'sos_samecar') || '-',
					detailGetter: (cell: unknown, row: any) => (row?.count && row?.count - row?.sos_samecar) || '-',
					totalGetter: (data: any[]) =>
						this.total(data?.reduce((a, i) => [...a, ...i.details], []) ?? [], 'count') -
							this.total(data?.reduce((a, i) => [...a, ...i.details], []) ?? [], 'sos_samecar') || '-',
				},
				{
					key: BKF_TABLE_COLUMNS.Order,
					headerLabelKey: 'dtt-export.dtt_report.header.order',
					columnType: ColumnType.Text,
					valueGetter: (cell: unknown, row: any) =>
						this._secondsToMins.transform(
							this.totalAverage(row.details ?? [], 'mean_sos_waittime', false) +
								this.totalAverage(row.details ?? [], 'mean_sos_ordertime', false)
						),
					detailGetter: (cell: unknown, row: api.SosDttReport) =>
						this._secondsToMins.transform(row?.mean_sos_waittime + row?.mean_sos_ordertime),
					totalGetter: (data: any[]) =>
						this._secondsToMins.transform(
							this.totalAverage(data?.reduce((a, i) => [...a, ...i.details], []) ?? [], 'mean_sos_waittime', false) +
								this.totalAverage(data?.reduce((a, i) => [...a, ...i.details], []) ?? [], 'mean_sos_ordertime', false)
						),
				},
				{
					key: BKF_TABLE_COLUMNS.LineTime,
					headerLabelKey: 'dtt-export.dtt_report.header.transit',
					columnType: ColumnType.Text,
					valueGetter: (cell: number, row: any) =>
						this._secondsToMins.transform(
							this.totalAverage(row.details, 'mean_sos_paytime', false) +
								this.totalAverage(row.details, 'mean_sos_linetime', false) +
								this.totalAverage(row.details, 'mean_sos_hardservetime', false)
						),
					detailGetter: (cell: number, row: api.SosDttReport) =>
						this._secondsToMins.transform(
							(row?.mean_sos_paytime || 0) + (row?.mean_sos_hardservetime || 0) + (row?.mean_sos_linetime || 0)
						),
					totalGetter: (data: any[]) =>
						this._secondsToMins.transform(
							this.totalAverage(
								data?.reduce((a, i) => [...a, ...i.details], []),
								'mean_sos_paytime',
								false
							) +
								this.totalAverage(
									data?.reduce((a, i) => [...a, ...i.details], []),
									'mean_sos_linetime',
									false
								) +
								this.totalAverage(
									data?.reduce((a, i) => [...a, ...i.details], []),
									'mean_sos_hardservetime',
									false
								)
						),
				},
				{
					key: BKF_TABLE_COLUMNS.Delivery,
					headerLabelKey: 'dtt-export.dtt_report.header.delivery',
					columnType: ColumnType.Text,
					valueGetter: (cell: unknown, row: any) => this._secondsToMins.transform(this.totalAverage(row.details ?? [], 'mean_sos_deliverytime', false)),
					detailGetter: (cell: unknown, row: api.SosDttReport) => (row ? this._secondsToMins.transform(this.getDeliveryTime(row)) : '-'),
					totalGetter: (data: any[]) =>
						this._secondsToMins.transform(
							this.totalAverage(data?.reduce((a, i) => [...a, ...i.details], []) ?? [], 'mean_sos_deliverytime', false)
						),
				},
				{
					key: BKF_TABLE_COLUMNS.Total,
					headerLabelKey: 'dtt-export.dtt_report.header.total',
					columnType: ColumnType.Text,
					valueGetter: (cell: unknown, row: any) => this._secondsToMins.transform(row.details.reduce((a, i) => a + this.totalTime(i), 0)),
					detailGetter: (cell: unknown, row: api.SosDttReport) => this._secondsToMins.transform(this.totalTime(row)),
					totalGetter: (data: any[]) =>
						this._secondsToMins.transform(this.totalAverage(data?.reduce((a, i) => [...a, ...i.details], []) ?? [], 'total', true)),
				},
			],
		};
	}
}
