import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, input, Output } from '@angular/core';
import { DatePeriod } from '@libs/dash/core/entity';
import { DatePickerComponent } from '@libs/dash/features/v1';
import {
	CommonButtonToggleGroupComponent,
	CommonRangePickerComponent,
	CustomButtonToggleGroupItem,
} from '@libs/shared/modules/common-components';
import { TranslateService } from '@libs/shared/modules/i18n';

@Component({
	selector: 'date-picker-new',
	standalone: true,
	imports: [CommonModule, CommonRangePickerComponent, CommonButtonToggleGroupComponent],
	templateUrl: './date-picker-new.component.html',
	styleUrl: './date-picker-new.component.scss',
	providers: [TranslateService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerNewComponent extends DatePickerComponent implements AfterViewInit {
	labels: CustomButtonToggleGroupItem[] = this.datePeriods.map((p) => {
		return {
			labelKey: 'sosKioskTile.datePicker.' + p,
			id: p,
		};
	});

	selectedPeriodSig = input.required<DatePeriod>();

	@Output() selectedPeriodChange = new EventEmitter<DatePeriod>();

	ngAfterViewInit() {
		this.selectPeriod(this.selectedPeriodSig());
	}

	selectPeriodInternal(period: DatePeriod | string): void {
		this.selectedPeriodChange.emit(period as DatePeriod);
	}
}
