import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, Injector, OnInit, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import * as api from '@dki/api-client';
import { IPeriodData, IRestaurantData, SERVICE_OBJECTIVES, TimeStep, ValueType } from '@libs/dash/core/entity';
import { SpeedOfServiceComponent } from '@libs/dash/features/v1';
import { CommonLayoutTableComponent } from '@libs/shared/modules/common-components';
import { TranslateModule } from '@libs/shared/modules/i18n';
import { debounceTime, filter, take } from 'rxjs/operators';
import { ExportOption, ExportOptionType, LayoutFilterService } from '../../../layout-filter-service/layout-filter.service';
import { SosIndicatorCardComponent } from '../../../sos-indicator-card/sos-indicator-card.component';
import { SosDataTableCumulComponent } from '../../partial-components/sos-data-table-cumul/sos-data-table-cumul.component';
import { SosDataTableRestoComponent } from '../../partial-components/sos-data-table-resto/sos-data-table-resto.component';

export interface ICommonLayoutIndicator {
	labelKey: string;
	percent: number;
	objective: number;
}

export type InternalTranslation = { [key: string]: string | InternalTranslation };

export type InternalViewData = {
	myRestaurants: api.Restaurant[];
	i18n: InternalTranslation;
	sosData: IPeriodData[];
	sosDataByRestaurants: { [key: string]: IRestaurantData };
};

export const SOS_TABLE_COLUMNS = {
	Period: 'period',
	Schedule: 'schedule',
	TotalRevenue: 'total_revenue',
	MeanRevenue: 'mean_revenue',
	Count: 'count',
	PreparationTime: TimeStep.PreparationTime,
	ServeTime: TimeStep.ServeTime,
	TotalTime: TimeStep.Total,
};

@Component({
	selector: 'sos-content',
	standalone: true,
	imports: [CommonModule, CommonLayoutTableComponent, SosIndicatorCardComponent, SosDataTableCumulComponent, SosDataTableRestoComponent],
	templateUrl: './sos-content.component.html',
	styleUrl: './sos-content.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SosContentComponent extends SpeedOfServiceComponent implements OnInit {
	indicators: ICommonLayoutIndicator[] = [];
	private readonly _injector: Injector = inject(Injector);
	private readonly _dr: DestroyRef = inject(DestroyRef);
	private readonly _layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	contentViewData: Signal<InternalViewData> = toSignal(this.viewData$, { injector: this._injector });

	ngOnInit(): void {
		this.selectedRestaurants$
			.pipe(
				filter((restaurants: api.Restaurant[]) => restaurants.filter((r) => typeof r !== 'undefined').length > 0),
				debounceTime(250),
				take(1)
			)
			.subscribe((restaurants: api.Restaurant[]) => {
				this.setRestaurants(restaurants);
			});
		effect(
			() => {
				if (this.contentViewData()) {
					this.updateIndicators();
				}
			},
			{ injector: this._injector }
		);

		const availableExportOptions: ExportOption[] = [{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF }];
		this._layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);

		this._layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this._dr))
			.subscribe((selectedOptions: string[]) => this._exportData(selectedOptions));
	}

	private setRestaurants(restaurants: api.Restaurant[]): void {
		this._layoutFilterServiceService.filters.restaurant.update(() => restaurants);

		effect(
			() => {
				if (this._layoutFilterServiceService?.filters?.restaurant()) {
					this.onRestaurantSelection(this._layoutFilterServiceService.filters.restaurant() as api.Restaurant[]);
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		effect(
			() => {
				if (this._layoutFilterServiceService.range()) {
					this.onDateSelection(this._layoutFilterServiceService.range());
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		effect(
			() => {
				if (this._layoutFilterServiceService?.filters?.channel()) {
					this.onChannelSelection(this._layoutFilterServiceService.filters.channel() as string);
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		effect(
			() => {
				if (this._layoutFilterServiceService?.filters?.day()) {
					this.onDaysChanged(this._layoutFilterServiceService.filters.day() as string[]);
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);
	}

	private updateIndicators(): void {
		this.serviceObjectives = Object.entries(SERVICE_OBJECTIVES).map(([key, value]) => ({ type: key as unknown as ValueType, ...value }));
		this.indicators = this.serviceObjectives.map((objective) => ({
			labelKey: `sosKioskTile.speedOfService.indicators.${objective.type}`,
			percent: this.calculatePercent(objective.type),
			objective: objective.threshold,
		}));
	}

	private _exportData(selectedOptions: string[]) {
		if (selectedOptions.length === 0) {
			return;
		}
		for (const option of selectedOptions) {
			switch (option) {
				case ExportOptionType.PDF:
					this.export();
					break;
			}
		}
	}
}
