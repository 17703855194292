import {
	BKDiscountInOrderDataImpl,
	IBKItemInOrderBase,
	IBKItemPriceInfo,
	IBKKingdomRebootCoupon,
	IBKOrderEventData,
	IBKTableAllocationAssignment,
} from '@bk/jscommondatas';
import { DeclaredOrderDetail } from '@bk/price-management-common';
import { UPDATE_TYPE } from '@libs/shared/models';
import { createAction, props } from '@ngrx/store';
import { IOrderCompleteData, IOrderLocation, IOrderUpdatePartial, IOrderUsedPayments, IPaymentInKiosk } from '@shared/models';

export type CreateOrderDataType = Record<'data', IOrderCompleteData>;
export type UpdateOrderLocationType = Record<'location', IOrderLocation>;
export type UpdatePartialOrderType = { order: Partial<IOrderCompleteData>; updateOrderPrice?: boolean };
export type AddOrderEventsType = Record<'data', IBKOrderEventData[]>;
export type UpdateOrderEventsType = Record<'data', IBKOrderEventData>;
export type SetOrderEventsType = Record<'data', IBKOrderEventData[]>;
export type AddProductToOrderType = Record<'productInOrder', IBKItemInOrderBase>;
export type AddDiscountToOrderType = Record<'discountInOrder', BKDiscountInOrderDataImpl>;
export type ChangeProductQuantityType = {
	lineUuid: string;
	quantity: number;
	isItemRemoved?: boolean;
	id: number;
};
export type UpdateProductInOrderType = Record<'data', IOrderUpdatePartial>;
export type UpdateOrderPriceResultType = { orderContent: IBKItemInOrderBase[]; orderTotal: IBKItemPriceInfo };
export type UpdateOrderWithFiscalPriceType = { itemWithoutFiscalPrice: IBKItemInOrderBase | undefined };
export type UpdateOrderWithFiscalPriceResultType = { order: IOrderCompleteData; orderDetail: DeclaredOrderDetail };
export type UpdatePaymentMethodsType = Record<'data', IOrderUsedPayments>;
export type UpdateFidelityRewardsType = { updateType: UPDATE_TYPE; data: IBKKingdomRebootCoupon };
export type EnablePaymentInKioskType = Record<'data', IPaymentInKiosk>;
export type RemoveItemsFromOrderType = { removedItems: IBKItemInOrderBase[] };

export const CleanOrderState = createAction('[Order] Clean order');
export const CleanOrderStateSuccess = createAction('[Order] Clean order success');
export const CreateNewOrder = createAction('[Order] Create new order', props<CreateOrderDataType>());
export const UpdateOrderLocation = createAction('[Order] Update order Location', props<UpdateOrderLocationType>());
export const UpdatePartialOrder = createAction('[Order] Update partially order', props<UpdatePartialOrderType>());
export const AddOrderEvents = createAction('[Order] Log order event', props<AddOrderEventsType>());
export const UpdateOrderEvents = createAction('[Order] Update logged order event', props<UpdateOrderEventsType>());
export const UpdateTableAllocation = createAction('[Order] Update table allocation', props<IBKTableAllocationAssignment>());
export const SetOrderEvents = createAction('[Order] Update events', props<SetOrderEventsType>());
export const AddProductToOrder = createAction('[Order] Add product to order', props<AddProductToOrderType>());
export const AddDiscountToOrder = createAction('[Order] Add discount to order', props<AddDiscountToOrderType>());
export const ChangeProductQuantityFinish = createAction('[Order] Change product quantity finish', props<ChangeProductQuantityType>());
export const ChangeProductQuantity = createAction('[Order] Change product quantity', props<ChangeProductQuantityType>());
export const UpdateOrderPrice = createAction('[Order] Update order items and total price');
export const UpdateOrderPriceSuccess = createAction(
	'[Order] Update order items and total price success',
	props<UpdateOrderPriceResultType>()
);
export const UpdateOrderPriceFail = createAction('[Order] Update order items and total price fail', props<UpdateOrderPriceResultType>());
export const UpdateOrderWithFiscalPrice = createAction(
	'[Order] Update order items and total with fiscal core prices',
	props<UpdateOrderWithFiscalPriceType>()
);
export const UpdateOrderWithFiscalPriceSuccess = createAction(
	'[Order] Update order items and total with fiscal core prices success',
	props<UpdateOrderWithFiscalPriceResultType>()
);
export const UpdateOrderWithFiscalPriceFail = createAction(
	'[Order] Update order items and total with fiscal core prices fail',
	props<UpdateOrderWithFiscalPriceResultType>()
);
export const UpsertProductInOrder = createAction('[Order] Update product in order', props<UpdateProductInOrderType>());
export const RequestFailure = createAction('[Order] Handle request error');

export const UpdateUsedPaymentMethods = createAction('[Order] Update payment methods', props<UpdatePaymentMethodsType>());

export const UpdateFidelityRewards = createAction('[Order] Update fidelity rewards', props<UpdateFidelityRewardsType>());

export const EnablePaymentInKiosk = createAction('[Order] Enable payment in kiosk', props<EnablePaymentInKioskType>());

export const RemoveItemsFromOrder = createAction('[Order] Remove items from order', props<RemoveItemsFromOrderType>());
