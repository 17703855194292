import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { IChannelOption, SalesChannel } from '@libs/dash/core/entity';
import { TranslateService } from '@libs/shared/modules/i18n';

@Component({
	selector: 'app-sales-channel-selector',
	templateUrl: './sales-channel-selector.component.html',
	styleUrls: ['./sales-channel-selector.component.scss'],
})
export class SalesChannelSelectorComponent implements OnInit {
	@Output() channelSelected = new EventEmitter<SalesChannel | null>();

	selectedChannel: SalesChannel | null = null;
	channels: IChannelOption[] = [];

	constructor(private readonly translateService: TranslateService) {}

	ngOnInit(): void {
		this._initializeChannels();
	}

	private _initializeChannels(): void {
		this.channels = [
			{ value: null, label: this.translateService.instant('sosKioskTile.salesChannels.all') },
			...Object.values(SalesChannel).map((channel) => ({
				value: channel,
				label: this.translateService.instant(`sosKioskTile.salesChannels.${channel.toLowerCase()}`),
			})),
		];
	}

	onChannelChange(value: SalesChannel | null): void {
		this.selectedChannel = value;
		this.channelSelected.emit(value);
	}
}
