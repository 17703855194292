import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerApply, MatDatepickerCancel } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@libs/shared/modules/i18n';

export interface CommonSelectOption {
	labelKey?: string;
	label?: string;
	value: string;
}

export interface Clearable {
	clear(): void;
}

@Component({
	selector: 'common-layout-select',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		MatFormFieldModule,
		MatIcon,
		MatSelectModule,
		MatButton,
		MatDatepickerApply,
		MatDatepickerCancel,
		MatCheckboxModule,
		TranslateModule,
	],
	templateUrl: './common-layout-select.component.html',
	styleUrl: './common-layout-select.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonLayoutSelectComponent implements Clearable {
	private readonly _cd: ChangeDetectorRef = inject(ChangeDetectorRef);

	searchQuery = '';

	@Input() multiSelect = false;
	@Input() label: string = '';
	@Input() icon: string = '';
	@Input() search = false;
	@Input() allCheckbox = true;
	@Input() minWidth: number = 300;
	@Input() selectedValue: string | string[] | null;
	@Input() options: CommonSelectOption[];

	@Output() selectionChange = new EventEmitter<string | string[]>();

	select(selectedValue: string | string[] | null): void {
		this.selectedValue = selectedValue;
		this.selectionChange.emit(selectedValue);
	}

	selectAll(): void {
		if (this.selectedValue?.length === this.options.length) {
			this.selectedValue = [];
			this.selectionChange.emit(this.selectedValue);
			return;
		}

		this.selectedValue = this.options.map((o) => o.value);
		this.selectionChange.emit(this.selectedValue);
	}

	clear(): void {
		this.selectedValue = undefined;
		this.selectionChange.emit(this.selectedValue);
		this._cd.detectChanges();
	}

	isHidden(name: string) {
		return !name?.toLowerCase().includes(this.searchQuery.toLowerCase());
	}
}
