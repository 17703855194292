import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ActionButtons } from '@libs/dash/shared';
import { MIN_PASSWORD_SCORE } from '@libs/shared/modules/frontend-common';
import { TranslateService } from '@libs/shared/modules/i18n';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import zxcvbn from 'zxcvbn';
import { ACCOUNT_FACADE } from '../../facade/account-facade.injection.token';
import { AccountServiceProvider } from '../../facade/account-facade.provider.interface';

@Component({
	selector: 'dk-new-password',
	templateUrl: './new-password.component.html',
	styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent {
	maskInput = true;
	error = false;
	errorMsg = '';

	actionButtons$: Observable<ActionButtons> = this._translateService.selectTranslation('new-password').pipe(
		map((localisedLoginTexts) => {
			return {
				positive: {
					label: localisedLoginTexts.buttons.save,
					disabled: false,
				},
			};
		})
	);

	passwordForm = new FormGroup({
		password: new FormControl(''),
	});

	localisedTexts$ = this._translateService.selectTranslation('new-password');

	constructor(
		private _translateService: TranslateService,
		@Inject(ACCOUNT_FACADE) private _accountFacade: AccountServiceProvider
	) {}

	promptPasswordSetup(): void {
		if (zxcvbn(this.passwordForm.value.password).score >= MIN_PASSWORD_SCORE) {
			this._accountFacade.promptPasswordSetup(this.passwordForm.value.password);
			this.passwordForm.reset();
		} else {
			this.error = true;
			this.errorMsg = 'weak-pw';
		}
	}
}
