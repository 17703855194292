import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, computed, inject, input, Signal, TemplateRef, ViewChild } from '@angular/core';
import * as api from '@dki/api-client';
import { IAggregateData, IPeriodData, Period, TimeStep, ValueType } from '@libs/dash/core/entity';
import { DataTableComponent, SecondsToMins } from '@libs/dash/features/v1';
import { InternalViewData, SOS_TABLE_COLUMNS } from '@libs/dash/features/v2';
import { ColumnType, CommonLayoutTableComponent, CommonTableColumnGroup, CommonTableConfig } from '@libs/shared/modules/common-components';
import { TranslateService } from '@libs/shared/modules/i18n';

interface ByRestaurantSig extends api.Restaurant {
	periods: IPeriodData[];
	total: IAggregateData;
}

@Component({
	selector: 'sos-data-table-resto',
	standalone: true,
	imports: [CommonModule, SecondsToMins, CommonLayoutTableComponent],
	templateUrl: './sos-data-table-resto.component.html',
	styleUrl: './sos-data-table-resto.component.scss',
	providers: [SecondsToMins],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SosDataTableRestoComponent extends DataTableComponent implements AfterViewInit {
	private readonly _translateService: TranslateService = inject(TranslateService);
	private readonly _secondsToMins: SecondsToMins = inject(SecondsToMins);

	tableConfig: CommonTableConfig<any>;
	tableGroups: CommonTableColumnGroup[] = [
		{
			titleKey: 'sosKioskTile.dataTable.operationalTimes',
			columns: [SOS_TABLE_COLUMNS.PreparationTime, SOS_TABLE_COLUMNS.ServeTime, SOS_TABLE_COLUMNS.TotalTime],
		},
	];

	contentViewData = input.required<InternalViewData>();

	dataSourceSig: Signal<ByRestaurantSig[]> = computed(() => {
		if (this.contentViewData() && this.contentViewData().sosDataByRestaurants) {
			this.data = this.contentViewData().sosDataByRestaurants;
			return Object.keys(this.contentViewData().sosDataByRestaurants).map((key) => {
				return {
					...this.contentViewData().myRestaurants.find((restaurant) => restaurant.id === key),
					...this.contentViewData().sosDataByRestaurants[key],
					periods: Object.values(this.contentViewData().sosDataByRestaurants[key].periods),
				};
			});
		}

		this.data = null;
		return [];
	});

	@ViewChild('coloredTime', { read: TemplateRef }) coloredTime: TemplateRef<any>;

	override ngAfterViewInit() {
		this.tableConfig = {
			titleKey: 'sosKioskTile.dataTable.byRestaurants',
			detailsKey: 'periods',
			columns: [
				{
					key: SOS_TABLE_COLUMNS.Period,
					headerLabelKey: 'sosKioskTile.dataTable.slots',
					alignment: 'left',
					columnType: ColumnType.Text,
					detailColumnType: ColumnType.Translation,
					width: '19.5%',
					valueGetter: (cell: any, row: ByRestaurantSig) => row.name,
					detailGetter: (cell: Period) => `sosKioskTile.daypartLabels.${cell}`,
				},
				{
					key: SOS_TABLE_COLUMNS.Schedule,
					headerLabelKey: 'sosKioskTile.dataTable.schedule',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					valueGetter: () => '',
					detailGetter: (cell, row: IPeriodData) => this.formatTimeRange({ start: row.start, end: row.end }),
					totalGetter: () => this._translateService.instant('sosKioskTile.dataTable.totalAverage'),
				},
				{
					key: SOS_TABLE_COLUMNS.TotalRevenue,
					headerLabelKey: 'sosKioskTile.dataTable.revenue',
					alignment: 'right',
					columnType: ColumnType.Price,
					width: '11.5%',
					valueGetter: (cell, row: ByRestaurantSig) => row.total.total_revenue,
					totalGetter: (data: ByRestaurantSig[]) => this.calculateTotalForField('total_revenue'),
				},
				{
					key: SOS_TABLE_COLUMNS.MeanRevenue,
					headerLabelKey: 'sosKioskTile.dataTable.averageTicket',
					alignment: 'right',
					columnType: ColumnType.Price,
					width: '11.5%',
					valueGetter: (cell, row: ByRestaurantSig) => (row.total.count > 0 ? row.total.total_revenue / row.total.count : 0),
					totalGetter: (data: ByRestaurantSig[]) =>
						this.calculateTotalForField('count') > 0
							? this.calculateTotalForField('total_revenue') / this.calculateTotalForField('count')
							: 0,
				},
				{
					key: SOS_TABLE_COLUMNS.Count,
					headerLabelKey: 'sosKioskTile.dataTable.orderCount',
					alignment: 'right',
					columnType: ColumnType.Text,
					width: '11.5%',
					valueGetter: (cell, row: ByRestaurantSig) => row.total.count,
					totalGetter: (data: ByRestaurantSig[]) => this.calculateTotalForField('count'),
				},
				{
					key: SOS_TABLE_COLUMNS.PreparationTime,
					headerLabelKey: 'sosKioskTile.dataTable.preparation',
					alignment: 'right',
					columnType: ColumnType.Custom,
					width: '11.5%',
					templateRef: this.coloredTime,
					info: ValueType.Preparation,
					valueGetter: (cell, row: ByRestaurantSig) => row.total[TimeStep.PreparationTime],
					detailGetter: (cell, row: IPeriodData) => row[TimeStep.PreparationTime],
					totalGetter: (data: ByRestaurantSig[]) =>
						this._secondsToMins.transform(this.calculateAverageTimeForField(TimeStep.PreparationTime)),
				},
				{
					key: SOS_TABLE_COLUMNS.ServeTime,
					headerLabelKey: 'sosKioskTile.dataTable.departure',
					alignment: 'right',
					columnType: ColumnType.Custom,
					width: '11.5%',
					templateRef: this.coloredTime,
					info: ValueType.Depart,
					valueGetter: (cell, row: ByRestaurantSig) => row.total[TimeStep.ServeTime],
					detailGetter: (cell, row: IPeriodData) => row[TimeStep.ServeTime],
					totalGetter: (data: ByRestaurantSig[]) => this._secondsToMins.transform(this.calculateAverageTimeForField(TimeStep.ServeTime)),
				},
				{
					key: SOS_TABLE_COLUMNS.TotalTime,
					headerLabelKey: 'sosKioskTile.dataTable.totalService',
					alignment: 'right',
					columnType: ColumnType.Custom,
					width: '11.5%',
					templateRef: this.coloredTime,
					info: ValueType.Total,
					valueGetter: (cell, row: ByRestaurantSig) => row.total[TimeStep.PreparationTime] + row.total[TimeStep.ServeTime],
					detailGetter: (cell, row: IPeriodData) => row[TimeStep.PreparationTime] + row[TimeStep.ServeTime],
					totalGetter: (data: ByRestaurantSig[]) =>
						this._secondsToMins.transform(
							this.calculateAverageTimeForField(TimeStep.ServeTime) + this.calculateAverageTimeForField(TimeStep.PreparationTime)
						),
				},
			],
		};
	}

	override positionSecondLabel(): void {
		return;
	}

	override getClassForValue(value: number, type: ValueType): string {
		if (!value || isNaN(value)) {
			return '';
		}
		return this.isThresholdExceeded(value, type) ? 'red' : 'green';
	}
}
