import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@libs/shared/modules/i18n';
import { UiVersionDetectorService } from '@libs/shared/services';
import { BreadcrumbComponent } from './breadcrumb.component';

const materialModules = [MatIconModule];

const components = [BreadcrumbComponent];
const pipes = [];
const directives = [];

@NgModule({
	imports: [CommonModule, RouterModule, TranslateModule, ...materialModules],
	declarations: [...components, ...pipes, ...directives],
	exports: [...components, ...pipes],
	providers: [UiVersionDetectorService],
})
export class BreadcrumbsModule {}
