import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UiVersionDetectorService } from '@libs/shared/services';
import { TranslateService } from '@libs/shared/modules/i18n';
import { DateTime } from 'luxon';

@Component({
	selector: 'dk-global-overview',
	templateUrl: './global-overview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalOverviewComponent {
	public localisedTexts$ = this._translateService.selectTranslation('global-overview');

	private readonly _newUiDetector: UiVersionDetectorService = inject(UiVersionDetectorService);
	isNewRoute = this._newUiDetector.isNewUi();

	constructor(private _translateService: TranslateService) {}

	dateSet = false;
	date: DateTime;

	toggleAnalyzeHistory(date, shown = true) {
		const fromHistory = date._isAMomentObject;
		if (!fromHistory) {
			this.dateSet = false;
		} else {
			this.dateSet = true;
		}
		if (shown) this.date = DateTime.fromJSDate(date._d);
	}
}
